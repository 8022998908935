import React, { Component } from "react";
import "./../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import $ from "jquery";

import { sistemeDanfoss } from "../../misc/sistemeDanfoss";
class CosuriDeFumCeramic extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", "604" + "px");
    $("#last").css("height", $("#previous").outerHeight() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", "604" + "px");

    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Coș de fum ceramic" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Sisteme de coş de fum ceramic – UNI PLUS, RONDO & QUADRO</h2>
          </div>
          <hr />
          <img
            id="previous"
            src="/assets/images/cosuri_de_fum/ceramic1.png"
            alt=".."
            className="cosuri-de-fum-imagine"
          />
          <div className="row">
            <div className="col">
              {" "}
              <div className="text-description">
                <h3>Avantaje multiple:</h3>
                <li>
                  siguranţă în exploatare şi funcţionare – prin eliminarea riscului incendiilor provocate de coşuri de
                  fum şi a intoxicaţiilor cu monoxid de carbon
                </li>
                <li>
                  tiraj optim – cu funcţionarea focarului la randament maxim şi un consum de combustibil mai redus
                </li>
                <li>
                  rezistenţă la condensul acid – eliminându-se necesitatea reparaţiilor ulterioare prin prevenirea
                  deteriorării structurii coşului
                </li>
                <h3>Garanție</h3>
                <li>
                  În condiţiile punerii în operă a sistemului complet, producătorul acordă o garanţie de 30 de ani
                  pentru rezistenţă la şocuri termice, rezistenţă la coroziune chimică şi insensibilitate la umiditate a
                  structurii coşului de fum.
                </li>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <img
                id="last"
                src="/assets/images/cosuri_de_fum/ceramic2.png"
                alt=".."
                className="cosuri-de-fum-imagine"
              />
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default CosuriDeFumCeramic;
