import React, { Component } from "react";
import "./../../Products.css";
import "./Lindab.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { Link } from "react-router-dom";
import { lindabAll } from "../../../misc/lindab";
class LindabRoca extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Țiglă metalică rocă vulcanică" className="no-padding" />
        <br />
        <h2>Lindab</h2>
        <hr />
        <div className="text-description">
          {" "}
          <strong>Lindab</strong> este un concern international care dezvolta, produce si promoveaza produse si solutii
          moderne pentru constructii civile& industriale si sisteme de ventilatii pentru un climat interior imbunatatit.{" "}
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="lindab/toscana" className="card-link">
              <Card
                link={lindabAll.TOSCANA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="TOSCANA"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="lindab/madera" className="card-link">
              {" "}
              <Card
                link={lindabAll.MADERA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="MADERA"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="lindab/rustica" className="card-link">
              {" "}
              <Card
                link={lindabAll.RUSTICA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="RUSTICA"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="lindab/serena" className="card-link">
              {" "}
              <Card
                link={lindabAll.SERENA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="SERENA"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabRoca;
