import React, { Component } from "react";
import "./Creaton.css";
import Tag from "../../../misc/Tag";

const images = {
  ROSU: {
    link: <img src="/assets/images/tigla_ceramica_creaton/biber/biber_rosu.jpg" alt="..." />,
    text: "ROȘU NATUR",
    className: "text-rosu-natur"
  },
  ROSU_ARDERE: {
    link: <img src="/assets/images/tigla_ceramica_creaton/biber/biber_rosu_ardere.jpg" alt="..." />,
    text: "ROȘU NATUR CU EFECT DE ARDERE",
    className: "text-rosu-natur-ardere"
  },
  ROSU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/biber/biber_rosu_angoba.jpg" alt="..." />,
    text: "ROȘU ANGOBA",
    className: "text-rosu-angoba"
  },
  CUPRU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/biber/biber_rosu_cupru_angoba.jpg" alt="..." />,
    text: "ROȘU CUPRU ANGOBA",
    className: "text-cupru-angoba"
  },
  ROSU_VIN_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/biber/biber_rosu_vin_angoba.jpg" alt="..." />,
    text: "ROȘU VIN ANGOBA",
    className: "text-rosu-vin-angoba"
  },

  MARO_INCHIS_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/biber/biber_maro_inchis_angoba.jpg" alt="..." />,
    text: "MARO ÎNCHIS ANGOBA",
    className: "text-maro-inchis-angoba"
  }
};

class CreatonBiber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU.link,
      text: images.ROSU.text,
      className: images.ROSU.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="KLASSIK DECUPAJ ROTUND" className="no-padding" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla solzi cu suprafață profilată.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                {" "}
                <div>
                  Cea mai largă gamă de aplicații permite soluții individuale de acoperire, ideală pentru
                  renovare și construcții noi
                </div>
                <div>Formă elegantă, discretă și atemporală pentru suprafețele de acoperiș de frumusețe clasică</div>
                <div>Soluționează în mod optic peisajele de acoperișuri</div>
                <div>Spectru larg de culori și suprafețe</div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU)} className="dot rosu-natur"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_ARDERE)} className="dot rosu-natur-ardere"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_ANGOBA)} className="dot rosu-angoba"></span>
                  <span onClick={e => this.handleOnClick(images.CUPRU_ANGOBA)} className="dot cupru-angoba"></span>
                  <span
                    onClick={e => this.handleOnClick(images.ROSU_VIN_ANGOBA)}
                    className="dot rosu-vin-angoba"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.MARO_INCHIS_ANGOBA)}
                    className="dot maro-inchis-angoba"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatonBiber;
