import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
class FerestreTerasaSticlaCurbata extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Sticlă curbată" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Fereastra VELUX cu protecție din sticlă curbată</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  Fereastra VELUX cu protecție din sticlă curbată este o soluție extrem de practică și elegantă.
                  Suprafața sa curbă unică lasă lumina naturală să pătrundă în interior, permițând apei să se scurgă cu
                  ușurință de pe suprafața ei. Suprafața curbată iese în evidență și din exterior, ca o completare
                  modernă a suprafeței acoperișului. Este ideală pentru proprietarii de case pasionați de design. Alege
                  între variantă cu cercevea fixă sau cea echipată cu motor electric.
                </div>
                <hr />
                <li>Sticlă curbă dintr-un capăt în altul, astfel încât ploaia se scurge cu ușurință în afară</li>
                <li>Cadru izolator din PVC cu panou de geam termoizolant alcătuit din 2 foi de sticlă</li>
                <li>Pentru un climat ideal, se poate accesoriza cu un rulou exterior parasolar discret</li>
              </div>
            </div>
            <div className="col min-width">
              <div className="row">
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.CVP}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="CVP model cu ventilaţie"
                    list={[]}
                  />
                </div>
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.CFP}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="CFP model fix"
                    list={[]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreTerasaSticlaCurbata;
