import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
import "../Ferestre.css";
class FerestreMansardaStandard extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Standard" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Ferestre de mansardă VELUX Standard</h1>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <h2>Operare de sus</h2>
              <hr />
              <div className="text-description">
                Modelul original de fereastră de mansardă cu operare de sus GZL
                are integrat un sistem de ventilare. Bara de operare superioară
                conferă ferestrei mai multe avantaje: modelul se potrivește
                pentru cele mai multe înălțimi de montaj și se manevrează ușor,
                chiar dacă mobilierul este amplasat sub fereastră.
              </div>

              <h2>Operare de jos</h2>
              <hr />
              <div className="text-description">
                Operată cu ajutorul unui mâner elegant din zinc, situat în
                partea de jos, această fereastră de mansardă din lemn este
                soluția recomandată în situațiile în care este necesar montajul
                ferestrei la înălțime. Fereastra este echipată cu un sistem de
                ventilare în două trepte care oferă posibilitatea de reglare a
                ventilației, chiar dacă fereastra este închisă.
              </div>

              <h2>Operare de sus, fără întreținere</h2>
              <hr />
              <div className="text-description">
                GLU este varianta din lemn învelit în poliuretan, care nu
                necesită întreținere. Modelul are un aspect impecabil și este
                recomandat în special în încăperile cu umiditate ridicată.
                Datorită sistemului izolator performant ThermoTechnology™ cu
                care sunt dotate modelele, te vei simți protejat de frig.
              </div>
            </div>{" "}
            <div className="col min-width">
              <div className="row">
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.GZL_SUS}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GZL 1051 cu operare de sus"
                    list={[]}
                  />
                </div>
                <div className="col ">
                  <Card
                    link={ferestreVeluxAll.GZL_JOS}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GZL 1051 B cu operare de jos"
                    list={[]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.GLU_SUS}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GLU 0051 cu operare de sus"
                    list={[]}
                  />
                </div>
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.GLU_JOS}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GLU 0051B cu operare de jos"
                    list={[]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreMansardaStandard;
