import React, { Component } from "react";
import Tag from "../../../misc/Tag";

class LindabRustica extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Lindab Rustica" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Rustica</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col col-figo">
              <div className="text-description text-creaton">
                <br />
                <div>
                  Tigla metalica cu acoperire de granule minerale Roca Rustica vine sa completeze gama de profile cu
                  imaginea caracteristica a tiglelor solzi.
                </div>
                <div>Roca Rustica este alegerea potrivita pentru case cu aspect traditional.​​</div>
              </div>
              <img
                src="/assets/images/tigla_metalica_roca/lindab/rustica_culori.png"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tigla_metalica_roca/lindab/lindab_rustica.jpg"
                alt="lindab Rustica"
                className="lindab-roca-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabRustica;
