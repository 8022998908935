import React, { Component } from "react";
import "../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
import { Link } from "react-router-dom";
import { rulouriDecorativeControl } from "../../../../misc/rulouriDecorative.js";
class RulouriControlCaldura extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Rulouri exterioare" className="no-padding" />
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width-rulouri-decorative">
            <Link
              to="/produse/ferestre-velux/rulouri-decorative/control-caldura/roleta-exterioara"
              className="card-link"
            >
              <Card
                link={rulouriDecorativeControl.ROLETA_SML_ELECTRICA_2}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Roletă exterioară"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>

          <div className="col min-width-rulouri-decorative">
            <Link
              to="/produse/ferestre-velux/rulouri-decorative/control-caldura/rulou-parasolar"
              className="card-link"
            >
              <Card
                link={rulouriDecorativeControl.RULOU_MHL_MANUAL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Rulou parasolar"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default RulouriControlCaldura;
