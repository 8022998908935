import React, { Component } from "react";
import Tag from "../misc/Tag";
import Card from "../misc/Card";
import { cardThumbs } from "../Homepage/Main";
import $ from "jquery";
import { Link } from "react-router-dom";
const images = {
  CONSULTANTA: <img src="/assets/images/servicii/consultanta.jpg" className="card-img-top" alt="..." />,
  DECOPERTARE: <img src="/assets/images/servicii/decopertare.jpeg" className="card-img-top" alt="..." />,
  DEVIZ: <img src="/assets/images/servicii/deviz.jpg" className="card-img-top" alt="..." />,
  GARANTIE: <img src="/assets/images/servicii/garantie.jpg" className="card-img-top" alt="..." />,
  IMPLEMENTARE: <img src="/assets/images/servicii/implementare.png" className="card-img-top" alt="..." />,
  MASURARE: <img src="/assets/images/servicii/masurare.jpg" className="card-img-top" alt="..." />,
  RENOVARE: <img src="/assets/images/servicii/renovare.jpg" className="card-img-top" alt="..." />,
  INVELITOARE: <img src="/assets/images/servicii/invelitoare.jpg" className="card-img-top" alt="..." />
};
class Services extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#last1").css("maxWidth", $("#previous1").outerWidth() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#last1").css("maxWidth", $("#previous1").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Servicii" />
        <br />
        <div className="text-description">
          Clima din România este destul de dură, cu ierni înghețate, veri călduroase și vânturi puternice. De aceea este
          foarte important să alegeți materiale de calitate, care să reziste în timp și profesioniști care să le pună în
          operă. Dacă aveți nevoie de o consiliere sau dacă doriți să reparați sau să înlocuiți acoperișul, un
          reprezentant al firmei Roof Construct International se va deplasa la dumneavoastră, va analiza problemă și vă
          va oferi cea mai bună soluție tehnico-comercială.
        </div>
        <div className="text-description">
          Distribuim și montăm o gamă destul de vastă de învelitori asigurându-ne că există întotdeauna o opțiune
          potrivită pentru fiecare buget. Obiectivul nostru principal este acela de a ajunge o companie de top în acest
          domeniu, care să ofere clienților săi servicii de cea mai înaltă calitate, siguranță și încredere.
        </div>
        <div className="row justify-content-center">
          <div className="col-3 min-width">
            <Card
              link={images.CONSULTANTA}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Consultanță"
              list={[]}
              className=""
              imgClass="height217"
            />
            <br />
            <div className="text-description">
              <div>
                Clientului i se oferă după caz, servicii de consiliere/consultanță de specialitate, cu privire la
                alegerea produselor potrivite, montaj, mentenanță.
              </div>
              Ne puteți contacta pentru consultanță accesând{" "}
              <Link to="/contact" className="card-link">
                pagina de contact
              </Link>
            </div>
          </div>
          <div className="col-3 min-width">
            <Card
              link={images.MASURARE}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Măsurare"
              list={[]}
              className=""
            />{" "}
            <br />
            <div className="text-description">
              Serviciul presupune deplasarea unei echipe la fața locului și efectuarea măsurătorii proiectului. Atât în
              cazul construcțiilor noi, cât și al celor vechi, o măsurătoare realizată la fața locului constituie
              certitudinea faptului că necesarul de materiale este întocmit fără abateri și respectă configurația
              existentă. Astfel, elementele care vor forma acoperișul vor fi optimizate și calculate corect.
            </div>
          </div>
          <div className="col-3 min-width" id="previous1">
            <Card
              link={images.DEVIZ}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Deviz"
              list={[]}
              className=""
            />
            <br />
            <div className="text-description">
              Devizul este realizat fie pe baza unui plan al acoperișului, fie pe baza măsurătorii la fața locului.
              Echipa noastră garantează o măsurătoare precisă și un cost corect, în urma unei oferte optimizate și
              personalizate pentru proiect.
            </div>
          </div>
          <div className="col-3 min-width" id="last1">
            <Card
              link={images.IMPLEMENTARE}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Implementare proiect"
              list={[]}
              className=""
            />
            <br />
            <div className="text-description">
              După acceptarea ofertei înaintată clientului, se va demara activitatea privitoare la livrarea produselor
              (acestea sunt livrate fără cheltuieli suplimentare din partea clientului, fiind ambalate compact. Vor
              necesită spațiu mic de depozitare, neconstituind un inconvenient pentru buna desfășurare a lucrului în
              șantier), montaj (aceasta operațiune se execută cu personal de specialitate) și, eventual, ca și serviciu
              post-montaj, mentenanță.
            </div>
          </div>

          <div className="col-3 min-width">
            <Card
              link={images.DECOPERTARE}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Decopertarea acoperișului vechi"
              list={[]}
              className=""
              imgClass="height217"
            />{" "}
            <br />
            <div className="text-description">
              Procedura de înlocuire a învelitorii presupune decopertarea acoperișului vechi și montarea celui nou. Este
              foarte important ca echipa care efectuează această lucrare să fie una specializată deoarece lucrul la
              înălțime implică riscuri multiple. Prin urmare, trebuie să fie acordată o atenție specială dotării cu
              echipamente de protecție, cum ar fi corzile fixe, căștile și mănușile.
            </div>
          </div>
          <div className="col-3 min-width">
            <Card
              link={images.RENOVARE}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Renovare acoperiș"
              list={[]}
              className=""
            />{" "}
            <br />
            <div className="text-description">
              În general, oamenii nu acordă o mare importantă întreținerii acoperișului decât atunci când apar primele
              semne că acesta s-a degradat. Există cazuri în care interiorul locuinței nu este afectat de acoperișul
              degradat, însă aspectul acestuia vă nemulțumește. În aceste situații există soluții la îndemâna oricui.
            </div>
          </div>
          <div className="col-3 min-width" id="previous">
            <Card
              link={images.INVELITOARE}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Învelitoare"
              list={[]}
              className=""
            />{" "}
            <br />
            <div className="text-description">
              Indiferent că doriți să optați pentru țiglă metalică, țiglă ceramică sau tablă plană minirulou, trebuie să
              știți mai întâi ce presupune fiecare material în parte. De ce? Pentru că întotdeauna există avantaje și
              dezavantaje, atât în ceea ce privește costurile investiției, cât și eficiența, calitatea și întreținerea
              acestor învelitori.
            </div>
          </div>
          <div className="col-3 min-width" id="last">
            <Card
              link={images.GARANTIE}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Garanție"
              list={[]}
              className=""
            />{" "}
            <br />
            <div className="text-description">
              Echipa noastră oferă clienților garanție pentru acoperiș, pentru produse (acordată de producător) și
              pentru manoperă, asigurând astfel intervenție sigură și rapidă în cazul unor eventuale probleme.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
