import React, { Component } from "react";
import "./Products.css";
import Tag from "../misc/Tag";
import Card from "../misc/Card";
import { cardThumbs } from "../Homepage/Main";
import $ from "jquery";
class TrapeDeFum extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Trape de fum și luminatoare" className="no-padding" />
        <br />
        <div align="center" className="text-title">
          <h2>BMI Awak</h2>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <h3>Trape de fum</h3>
            <hr />{" "}
            <div className="text-description">
              Trapele de fum sunt destinate evacuării fumului și a gazelor fierbinți degajate în timpul incendiilor. O
              funcție suplimentară poate fi creșterea gradului de luminozitate în încăperi precum și ventilarea
              acestora.
            </div>
            <Card
              link={cardThumbs.trapaDeFum}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Trape de fum"
              list={[]}
              className=""
              imgClass="height217"
            />
          </div>
          <div className="col" id="previous">
            <h3>Luminatoare</h3>
            <hr />{" "}
            <div className="text-description">
              Luminatoarele asigură creșterea gradului de luminozitate naturală în interiorul clădirilor, și ceea ce
              este extrem de important, duc la un consum de energie electrică mult mai mic pe timpul zilei.
            </div>
            <Card
              link={cardThumbs.luminatoare}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Luminatoare"
              list={[]}
              className=""
            />
          </div>
          <div className="col" id="last">
            <h3>Trape de acces acoperiș</h3>
            <hr />{" "}
            <div className="text-description">
              Trapele de acces acoperiș sunt ideale pentru iluminatul natural și accesul în siguranță pe acoperișul
              clădirilor, cum ar fi blocurile de locuințe, clădirile de birouri, hale industriale sau centre comerciale.
            </div>
            <Card
              link={cardThumbs.trapaDeAcces}
              expandedArray={[false, false, false, false, false]}
              cardTitle="Trape de acces acoperiș"
              list={[]}
              className=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TrapeDeFum;
