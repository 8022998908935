import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Figo.css";

class FigoRobust extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="FIgo Robust" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Figo Robust</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col col-figo">
              <div className="text-description text-creaton">
                <div>
                  Figo Robust este o tablă special dezvoltată pentru acoperișuri și fațade metalice. Figo Robust, prin
                  calitatea înaltă a materialului, facilitează îndoirea și prelucrarea pe majoritatea formelor de
                  acoperiș.
                </div>

                <div className="properties-figo">
                  {" "}
                  <li>Aspect semilucios.</li>
                  <li>Grad mediu al reflexiilor</li>
                  <li>Suprafață netedă</li>
                  <li>Folie de protecție la cerere</li>
                </div>
                <hr />
              </div>
              <img
                src="/assets/images/tabla_plana_minirulou/figo/figo_robust_culori.png"
                alt="culori"
                className="figo-culori"
              />
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tabla_plana_minirulou/figo/card_figo_robust.png"
                alt="figo robust"
                className="figo-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FigoRobust;
