import React, { Component } from "react";
import Tag from "../../../misc/Tag";

class BMIAerodek extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="BMI AeroDek" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>BMI AeroDek</h1>
            <hr />
            <div className="widgets">
              <img src="/assets/images/tigla_metalica_roca/bmi_aerodek/garantie40.png" alt="culori" />
              <img src="/assets/images/tigla_metalica_roca/bmi_aerodek/garantie30.png" alt="culori" />
              <img src="/assets/images/tigla_metalica_roca/bmi_aerodek/AlZn150.png" alt="culori" />
              <img src="/assets/images/tigla_metalica_roca/bmi_aerodek/250ZnMg.png" alt="culori" />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-figo">
              <h2>Tradition Plus 0.43 / Tradition Plus 0.6</h2>
              <div>Îmbină tradiția cu tehnologia modernă</div>
              <hr />
              <img
                src="/assets/images/tigla_metalica_roca/bmi_aerodek/tradition_plus_culori.jpg"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>

            <div className="col min-width">
              <h2>Tradition</h2>
              <div>Simplitate și rafinament</div>
              <hr />
              <img
                src="/assets/images/tigla_metalica_roca/bmi_aerodek/tradition_culori.jpg"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>
          </div>
          <div className="row">
            <div className="col min-width">
              <h2>Robust Plus / Robust</h2>
              <div>Echilibru Și tradiție</div>
              <hr />
              <img
                src="/assets/images/tigla_metalica_roca/bmi_aerodek/robust_culori.jpg"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>
            <div className="col min-width">
              <h2>Quadro Plus P / Quadro Plus S</h2>
              <div>Un nou trend arhitectural</div>
              <hr />
              <img
                src="/assets/images/tigla_metalica_roca/bmi_aerodek/quadro_plus_culori.jpg"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>
          </div>
          <div className="row">
            <div className="col min-width">
              <h2>Firenze</h2>
              <div>Farmecul acoperișurilor în stil mediteranean</div>
              <hr />
              <img
                src="/assets/images/tigla_metalica_roca/bmi_aerodek/firenze_culori.jpg"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>
            <div className="col min-width"> </div>
          </div>
          <img src="/assets/images/tigla_metalica_roca/bmi_aerodek/1.png" alt="culori" className="lindab-roca-culori" />
        </div>
      </div>
    );
  }
}

export default BMIAerodek;
