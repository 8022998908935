import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { tuneluriSolare } from "../../../misc/tuneluriSolare";
import "./TuneluriSolare.css";
class AcoperisTerasa extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Acoperiș terasă" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Tuneluri solare pentru acoperiș terasă</h2>
          </div>
          <hr />
          <div className="text-description">
            <div>
              Tunelul solar VELUX pentru acoperiș terasă a fost creat pentru a aduce lumina naturală în holuri, pe casa
              scării, în băi și dressinguri aflate sub acoperiș tip terasă, unde este imposibilă montarea ferestrelor
              VELUX.
            </div>
            <div>Tunelurile solare cu tub flexibil și rigid oferă flexibilitate la montaj.</div>
            <div>
              Tunelul cu tub flexibil se poate monta atunci când unghiul dintre plafon și panta acoperișului este prea
              mic pentru un tunel cu tub rigid. Tunelul cu tub rigid furnizează o rază de lumină directă dacă este
              montat pe plafon exact sub fereastra din exterior.
            </div>
            <div>
              Tunelul solar VELUX se montează pe o pantă a acoperișului cuprinsă între 0° - 15° (cod VELUX: TCF/TCR).
              Pentru un acoperiș înclinat, vei avea nevoie de un tunel solar pentru acoperiș înclinat.
            </div>
          </div>
        </div>
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width">
            <div className="text-title tuneluri-title">
              <h2>Tunel solar flexibil pentru acoperiş terasă - TCF</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                <Card
                  link={tuneluriSolare.TCF_FLEXIBIL}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="TCF flexibil"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <h4 className="tuneluri-caracteristici">Caracteristici: </h4>
                <li>Recomandat pentru o lungime a tunelului cuprinsă între 0.2 la 0.9 m între acoperiş şi tavan</li>
                <li>Tunelul flexibil cu o lungime de 1 m, super reflectorizant, are un diametru de 35 cm</li>
                <li>Difuzorul ușor de asamblat este livrat împreună cu un kit de izolare termică</li>
                <li>Fereastra exterioară este din PVC și este protejată de o cupolă acrilică</li>
              </div>
            </div>
          </div>
          <div className="col min-width ">
            <div className="text-title tuneluri-title">
              <h2>Tunel solar rigid pentru acoperiş terasă – TCR</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                {" "}
                <Card
                  link={tuneluriSolare.TCR_RIGID}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="TCR Rigid"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <h4 className="tuneluri-caracteristici">Caracteristici: </h4>
                <li>Recomandat pentru o lungime a tunelului cuprinsă între 0.8 - 6 m între acoperiş şi tavan</li>
                <li>
                  Tunelul rigid cu o lungime de 1,7 m se recomandă a se utiliza - în combinații cu extensii ZTR - pentru
                  distanțe mari, astfel încât să asigure nivelul adecvat de iluminare naturală. Acesta este livrat
                  împreună cu 2 coturi de 45 grade
                </li>
                <li>Tunelul poate fi extins până la maxim 6 m</li>
                <li>Tunelul rigid are diametrul de 35 cm</li>
                <li>
                  Tunelul are pe interior un strat reflectorizant care asigură o reflexie a luminii de până la 98%
                </li>
                <li>Difuzorul ușor de asamblat este livrat împreună cu un kit de izolare termică</li>
                <li>Fereastra din exterior este din PVC și este protejată de o cupolă acrilică.</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AcoperisTerasa;
