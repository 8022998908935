import React, { Component } from "react";
import Tag from "../../../misc/Tag";

class LindabToscana extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Lindab Toscana" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Toscana</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col col-figo">
              <div className="text-description text-creaton">
                <br />
                <div>
                  Tigla metalica cu acoperire de granule minerale Roca Toscana reprezinta alegerea ideala pentru case cu
                  arhitectura mediteraneana.
                </div>
                <div>
                  Forma profilului reproduce modelul olanelor caracteristice acoperisurilor Italiei, iar nuantele "dual
                  tone" dau o nota eleganta si romantica acoperisurilor. Aspectul traditional si combinatiile pastelate
                  de culori etaleaza o armonie perfecta cu natura, starnind admiratia tuturor privitorilor.
                </div>
              </div>
              <img
                src="/assets/images/tigla_metalica_roca/lindab/toscana_culori.png"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tigla_metalica_roca/lindab/lindab_toscana.jpg"
                alt="lindab toscana"
                className="lindab-roca-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabToscana;
