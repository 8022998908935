import React, { Component } from "react";
import "./Creaton.css";
import Tag from "../../../misc/Tag";

const images = {
  ROSU_NATUR: {
    link: (
      <img src="/assets/images/tigla_ceramica_creaton/balance/balance_rosu_natur.jpg" className="card-img-top" alt="..." />
    ),
    text: "ROȘU NATUR",
    className: "text-rosu-natur"
  },
  CUPRU_ANGOBA: {
    link: (
      <img src="/assets/images/tigla_ceramica_creaton/balance/balance_cupru_angoba.jpg" className="card-img-top" alt="..." />
    ),
    text: "CUPRU ANGOBA",
    className: "text-cupru-angoba"
  },
  MARO_INCHIS_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/balance/balance_maro_inchis_angoba.jpg" alt="..." />,
    text: "MARO ÎNCHIS ANGOBA",
    className: "text-maro-inchis-angoba"
  },
  NEGRU_ANGOBA: {
    link: (
      <img src="/assets/images/tigla_ceramica_creaton/balance/balance_negru_angoba.jpg" className="card-img-top" alt="..." />
    ),
    text: "NEGRU ANGOBA",
    className: "text-negru-angoba"
  },
  NEGRU_MAT_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/balance/balance_negru_mat_angoba.jpg" alt="..." />,
    text: "NEGRU MAT ANGOBA",
    className: "text-negru-mat-angoba"
  },
  ROSU_ANGOBA: {
    link: (
      <img src="/assets/images/tigla_ceramica_creaton/balance/balance_rosu_angoba.jpg" className="card-img-top" alt="..." />
    ),
    text: "ROȘU ANGOBA",
    className: "text-rosu-angoba"
  },
  ROSU_VIN_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/balance/balance_rosu_vin_angoba.jpg" alt="..." />,
    text: "ROȘU VIN ANGOBA",
    className: "text-rosu-vin-angoba"
  },
  ADREZIE_ANGOBA: {
    link: (
      <img
        src="/assets/images/tigla_ceramica_creaton/balance/balance_adrezie_angoba.jpg"
        className="card-img-top"
        alt="..."
      />
    ),
    text: "ARDEZIE ANGOBA",
    className: "text-ardezie-angoba"
  }
};
class CreatonBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATUR.link,
      text: images.ROSU_NATUR.text,
      className: images.ROSU_NATUR.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
 
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Balance" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Echilibrată din punct de vedere al formei și al rentabilității.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                <div>Rentabilitate mare datorită formatului XXL</div>
                <div>Fălțuire pronunțată cu joc mare al lungimii de acoperire</div>
                <div>Avantaje de montare grație a trei ciocuri stabile de agățare</div>
                <div>
                  Nervurile de susținere asigură rezistență statică îmbunătățită și evacuarea oricărei forme de apă, de
                  condens
                </div>
                <div>
                  „Tehnica de labirint” cu cinci nervuri laterale special formate și deosebit de pronunțate împiedică
                  pătrunderea apei pluviale chiar și în caz de rafale puternice de vânt
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATUR)} className="dot rosu-natur"></span>
                  <span
                    name="rosu-angoba"
                    onClick={e => this.handleOnClick(images.ROSU_ANGOBA)}
                    className="dot rosu-angoba"
                  ></span>
                  <span
                    name="cupru-angoba"
                    onClick={e => this.handleOnClick(images.CUPRU_ANGOBA)}
                    className="dot cupru-angoba"
                  ></span>
                  <span
                    name="rosu-vin-angoba"
                    onClick={e => this.handleOnClick(images.ROSU_VIN_ANGOBA)}
                    className="dot rosu-vin-angoba"
                  ></span>
                  <span
                    name="maro-inchis-angoba"
                    onClick={e => this.handleOnClick(images.MARO_INCHIS_ANGOBA)}
                    className="dot maro-inchis-angoba"
                  ></span>
                  <span
                    name="adrezie-angoba"
                    onClick={e => this.handleOnClick(images.ADREZIE_ANGOBA)}
                    className="dot adrezie-angoba"
                  ></span>
                  <span
                    name="negru-angoba"
                    onClick={e => this.handleOnClick(images.NEGRU_ANGOBA)}
                    className="dot negru-angoba"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.NEGRU_MAT_ANGOBA)}
                    className="dot negru-mat-angoba"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatonBalance;
