import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";

import { tuneluriSolare } from "../../../misc/tuneluriSolare";
import "./TuneluriSolare.css";
class AcoperisInclinat extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Acoperiș înclinat" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Tuneluri solare pentru acoperiș înclinat</h2>
          </div>
          <hr />
          <div className="text-description">
            <div>
              Tunelul solar VELUX pentru acoperiș înclinat a fost creat cu scopul de a aduce lumina naturală în holuri,
              pe casa scării, în băi și dressinguri aflate sub acoperiș în pantă, unde montarea ferestrelor de mansardă
              VELUX este imposibilă.
            </div>
            <div>
              Tunelurile solare VELUX flexibile și rigide se pot monta în diferite situații. Tunelurile flexibile se
              montează, de regulă, când distanța dintre acoperiș și tavan este prea mică, astfel încât să se poată monta
              un tunel rigid. Tunelurile rigide furnizează o rază de lumină directă în cazul în care aplica din tavan a
              tunelului este plasată exact sub fereastra din exterior.
            </div>
            <div>
              Tunelul solar VELUX (TWF/TWR) se poate monta pe orice model de învelitoare. Tunelul solar pentru acoperiș
              înclinat se montează pe o pantă cuprinsă între 15°- 60°
            </div>
          </div>
        </div>
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width">
            <div className="text-title tuneluri-title">
              <h2>Tunel solar flexibil pentru acoperiș înclinat - TWF</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                <Card
                  link={tuneluriSolare.TWF_FLEXIBIL}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="TWF flexibil"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <h4 className="tuneluri-caracteristici">Caracteristici: </h4>
                <li>Recomandat pentru o lungime a tunelului cuprinsă între 0.4 - 2 m între acoperiş şi tavan</li>
                <li>Tunelul flexibil cu o lungime de 2 m, super-reflectorizant, are un diametru de 35 cm</li>
                <li>Difuzorul ușor de asamblat este livrat împreună cu un kit de izolare termică</li>
                <li>
                  Profilul exterior al ferestrei are un geam întărit de 4 mm și o cercevea detașabilă. Geamul are
                  aplicat pe exterior un tratament pentru a se păstra cât mai curat.
                </li>
              </div>
            </div>
          </div>
          <div className="col min-width ">
            <div className="text-title tuneluri-title">
              <h2>Tunel solar rigid pentru acoperiş înclinat – TWR</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                {" "}
                <Card
                  link={tuneluriSolare.TWR_RIGID}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="TWR Rigid"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <h4 className="tuneluri-caracteristici">Caracteristici: </h4>
                <li>Recomandat pentru o lungime a tunelului cuprinsă între 0.9 - 6 m între acoperiş şi tavan</li>
                <li>
                  Tunelul rigid, de 1.7 m lungime, se recomandă a se utiliza - în combinație cu extensii ZTR - pentru
                  distanțe mari, astfel încât să asigure nivelul adecvat de iluminare naturală
                </li>
                <li>Tunelul este livrat împreună cu 2 coturi de 45 grade</li>
                <li>Tunelul poate fi extins până la maxim 6 m</li>
                <li>Tunelul rigid are diametrul de 35 cm</li>
                <li>
                  Tunelul are pe interior un strat reflectorizant care asigură o reflexie a luminii de până la 98%
                </li>
                <li>Difuzorul ușor de asamblat este livrat împreună cu un kit de izolare termică</li>
                <li>
                  Profilul exterior al ferestrei are un geam întărit de 4 mm și o cercevea detașabilă. Geamul este
                  tratat la exterior pentru a se păstra cât mai curat.
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AcoperisInclinat;
