import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Bramac.css";
const images = {
  MAGICO: {
    link: <img src="/assets/images/tigla_bramac/adria/adria_magico.jpg" className="card-img-top" alt="..." />,
    text: "MAGICO",
    className: "text-magico"
  },
  UMBRA: {
    link: <img src="/assets/images/tigla_bramac/adria/adria_umbra.jpg" className="card-img-top" alt="..." />,
    text: "UMBRA",
    className: "text-umbra"
  },
  MONTANO: {
    link: <img src="/assets/images/tigla_bramac/adria/adria_montano.jpg" className="card-img-top" alt="..." />,
    text: "MONTANO",
    className: "text-montano"
  }
};
class BramacAdria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.MAGICO.link,
      text: images.MAGICO.text,
      className: images.MAGICO.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    console.log(images.e);
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Adria" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Aer mediteranean pentru acoperisuri exclusiviste.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  "Aici se trăiește din plin și exclusivist!" parcă asta transmite o casă acoperită cu țigla Bramac
                  Adria trecătorilor. Datorită profilului rotund și pronuntat, și a jocului viu de culori, acoperișul
                  casei dumneavoastră pare că prinde viață, iar formelor individuale ale acoperișului nu îi sunt impuse
                  limite!
                </div>
                <div>
                  Țigla Adria se potrivește perfect atât pentru renovarea construcțiilor prestigioase, cât și pentru
                  locuințe cu arhitectura în stil mediteranean!
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.MAGICO)} className="dot magico"></span>
                  <span onClick={e => this.handleOnClick(images.UMBRA)} className="dot umbra"></span>
                  <span onClick={e => this.handleOnClick(images.MONTANO)} className="dot montano"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BramacAdria;
