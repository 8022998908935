import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "./Portfolio.css";
import Tag from "../misc/Tag";
import Card from "../misc/Card";
import { Link } from "react-router-dom";
const images = [
  {
    original: "/assets/images/portofoliu/lucrari_noi/138.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/138.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/137.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/137.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/136.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/136.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/135.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/135.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/134.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/134.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/133.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/133.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/132.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/132.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/131.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/131.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/130.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/130.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/129.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/129.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/128.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/128.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/127.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/127.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/126.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/126.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/109.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/109.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/110.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/110.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/111.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/111.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/112.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/112.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/113.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/113.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/114.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/114.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/115.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/115.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/116.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/116.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/117.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/117.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/118.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/118.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/119.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/119.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/120.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/120.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/121.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/121.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/122.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/122.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/123.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/123.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/124.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/124.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/125.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/125.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/102.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/102.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/103.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/103.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/104.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/104.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/105.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/105.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/106.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/106.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/107.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/107.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/108.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/108.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/73.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/73.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/74.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/74.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/75.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/75.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/76.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/76.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/77.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/77.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/78.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/78.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/79.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/79.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/80.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/80.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/81.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/81.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/82.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/82.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/83.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/83.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/84.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/84.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/85.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/85.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/86.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/86.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/87.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/87.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/88.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/88.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/89.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/89.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/90.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/90.jpeg",
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/92.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/92.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/93.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/93.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/94.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/94.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/95.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/95.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/96.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/96.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/97.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/97.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/98.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/98.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/99.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/99.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/100.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/100.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/101.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/101.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/59_1.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/59_1.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/60.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/60.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/61.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/62.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/63.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/47.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/64.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/64.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/65.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/65.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/66.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/66.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/67.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/67.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/68.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/68.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/69.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/69.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/70.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/70.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/71.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/71.jpeg",
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/50.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/50.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/54.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/54.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/55.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/55.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/56.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/56.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/59.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/59.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/48.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/48.jpeg",
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/51.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/51.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/52.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/52.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/53.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/53.jpeg",
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/57.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/57.jpeg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/58.jpeg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/58.jpeg",
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/1.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/1.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/3.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/3.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/9.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/9.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/10.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/10.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/29.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/29.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/4.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/4.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/5.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/5.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/6.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/6.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/7.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/7.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/8.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/8.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/11.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/11.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/12.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/12.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/13.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/13.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/14.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/14.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/15.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/15.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/16.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/16.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/17.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/17.jpg",
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/18.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/18.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/19.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/19.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/20.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/20.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/21.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/21.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/22.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/22.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/23.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/23.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/24.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/24.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/25.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/25.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/26.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/26.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/27.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/27.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/28.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/28.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/30.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/30.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/31.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/31.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/32.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/32.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/33.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/33.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/34.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/34.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/35.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/35.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/36.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/36.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/37.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/37.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/38.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/38.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/39.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/39.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/40.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/40.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/41.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/41.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/42.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/42.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/43.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/43.jpg",
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/44.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/44.jpg",
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/46.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/46.jpg",
  },
];

class LucrariNoi extends Component {
  render() {
    return (
      <div className="products-container">
        <Tag name="Proiecte noi" />
        <br />
        <ImageGallery items={images} />
      </div>
    );
  }
}

export default LucrariNoi;
