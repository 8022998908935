import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";

class LindabNordic extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Nordic 400" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h1>Nordic 400</h1>
            <hr />
          </div>
          <div className="row">
            <div className="col braas-col">
              <div className="text-description text-creaton">
                <div>
                  Tigla metalica Lindab Nordic 400 este un produs premium pentru acoperisuri cu aspect desavarsit.{" "}
                </div>
                <div>
                  Profilul Nordic 400 este disponibil intr-o gama larga de culori cu acoperire PE(poliester 25 µm),
                  MATT(poliester mat 30 µm) sau HBPE(high-build poliester 50 µm){" "}
                </div>
              </div>
              <hr />
              <h3>Culori PE</h3>
              <img
                src="/assets/images/tigla_metalica_lindab/nordic400_culori_pe.png"
                alt="nordic400"
                className="lindab-culori"
              />{" "}
              <hr />
              <h3>Culori MATT</h3>
              <img
                src="/assets/images/tigla_metalica_lindab/nordic400_culori_matt.png"
                alt="nordic400"
                className="lindab-culori"
              />{" "}
              <hr />
              <h3>Culori HBPE</h3>
              <img
                src="/assets/images/tigla_metalica_lindab/nordic400_culori_hbpe.png"
                alt="nordic400"
                className="lindab-culori"
              />{" "}
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tigla_metalica_lindab/Nordic400_maro.jpg"
                alt="nordic400"
                className="lindab-metalica-image"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabNordic;
