import React, { Component } from "react";
import "./Footer.css";
import Ticker from "react-ticker";
class Footer extends Component {
  render() {
    return (
      <div className="footer-section">
        <div className="marquee">
          <span>
            <img src="/assets/images/logos/creaton.png" alt=".." />
            <img src="/assets/images/logos/bramac.jpg" alt=".." />
            <img src="/assets/images/logos/aerodek.png" alt=".." />
            <img src="/assets/images/logos/braas.png" alt=".." />
            <img src="/assets/images/logos/awak.png" alt=".." />
            <img src="/assets/images/logos/figo.png" alt=".." />
            <img src="/assets/images/logos/lindab.png" alt=".." />
            <img src="/assets/images/logos/wetterbest.png" alt=".." />
            <img src="/assets/images/logos/nelskamp.png" alt=".." />
            <img src="/assets/images/logos/velux.png" alt=".." />
            <img src="/assets/images/logos/danfoss.png" alt=".." />
            <img src="/assets/images/logos/schiedel.jpg" alt=".." />
          </span>
        </div>

        <div className="social-icons">
          <a
            href="https://www.facebook.com/roofconstruct.ro/"
            target="_blank"
            className="facebook-icon"
            rel="noopener noreferrer"
          >
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" style={{fill: "#ffffff"}}>
         
              <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
              </svg>
          </a>
          <div className="copyright">
            COPYRIGHT © 2020 S.C. Roof Construct International S.R.L.
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
