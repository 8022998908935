import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "../Ferestre.css";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
class FerestreMansardaPremium extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Premium" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Ferestre de mansardă VELUX Premium</h1>
          </div>
          <hr />
          <div className="text-description">
            <h2>Operare de sus</h2>
          </div>
          <div className="text-description">
            Adu aerul proaspăt în casa ta cu o fereastră de mansardă VELUX
            operată de sus. Acest model de fereastră este uşor de folosit -
            chiar dacă mobilierul este amplasat dedesubtul ei. Bara de operare
            din partea de sus îți va permite să montezi fereastra mai jos decât
            o fereastră echivalentă operată de jos, astfel încât vei obţine o
            privelişte excelentă, fie că stai jos, fie că stai în picioare.
          </div>{" "}
          <hr />
          <div className="text-description">
            <h2>Operare de jos</h2>
          </div>
          <div className="text-description">
            Cerul este limita, în cazul unei ferestre de mansardă Premium cu
            mâner jos. Operată cu un mîner elegant, această fereastră îți oferă
            panoramă completă, priveliște deschisă a cerului și maximizează
            cantitatea de lumină naturală care pătrunde înăuntru. Acest model
            este recomandat dacă dorești sau dacă trebuie să amplasezi fereastră
            la o înălțime mare, de exemplu în cazul unui parapet înalt.
          </div>{" "}
          <hr />
          <div className="text-description">
            {" "}
            <h2>Acționare electrică operare de sus</h2>
          </div>
          <div className="text-description">
            Spune da operării confortabile a ferestrelor. Ferestrele electrice
            VELUX INTEGRA® îți permit controlul de la distanță, prin intermediul
            unui intrerupătror de perete wireless. Alege între operare electrică
            sau solară și bucură-te de accesul facil al ferestrelor aflate atât
            la îndemână, cât și al celor montate sus, la care nu poți ajunge.
            Mai mult, stii că ferestrele tale sunt pregătite pentru o casă
            inteligentă.
          </div>{" "}
          <hr />
          <div className="text-description">
            <h2>Acționare electrică operare de jos</h2>
          </div>
          <div className="text-description">
            Bine ai venit în lumea ferestrelor care funcționează fără niciun
            efort. Fereastra electrică cu articulare în partea superioară VELUX
            GPU INTEGRA® combină beneficiile unei vederi panoramice cu
            funcționarea confortabilă datorită motorului electric. Fereastra
            poate fi acționată și manual, în caz de nevoie. Bucură-te de o
            senzație de libertate specifică unui balcon, care umple spațiul cu
            lumină naturală.
          </div>
          <hr />
          <div className="col min-width">
            <div className="row">
              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GGL}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GGL lăcuit transparent"
                  list={[]}
                />
              </div>
              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GGU}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GGU poliuretan"
                  list={[]}
                />
              </div>
              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GGL_ELECTRIC}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GGL electric lăcuit transparent"
                  list={[]}
                />
              </div>

              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GGL_SOLAR}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GGL solar lăcuit transparent"
                  list={[]}
                  className=""
                />
              </div>
              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GGU_ELECTRIC}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GGU electric poliuretan"
                  list={[]}
                  className=""
                />
              </div>

              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GGU_SOLAR}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GGU solar poliuretan"
                  list={[]}
                  className=""
                />
              </div>

              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GPL_LACUIT}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GPL lăcuit transparent"
                  list={[]}
                  className=""
                />
              </div>
              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GPU_INTEGRA_POLIURETAN}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GPU Integra Poliuretan"
                  list={[]}
                  className=""
                />
              </div>
              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GPU_POLIURETAN}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="GPU poliuretan"
                  list={[]}
                  className=""
                />
              </div>

              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GDL}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="Balcon VELUX CABRIO®, GDL lemn lăcuit transparent"
                  list={[]}
                  className=""
                />
              </div>
              <div className="col min-width">
                <Card
                  link={ferestreVeluxAll.GEL}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="Iesire pe terasă  GEL, lemn lăcuit transparent"
                  list={[]}
                  className=""
                />
              </div>
              <div className="col min-width"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreMansardaPremium;
