import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "./Portfolio.css";
import Tag from "../misc/Tag";
import Card from "../misc/Card";
import { Link } from "react-router-dom";
const images = [
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/2.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/2.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/5.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/5.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/6.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/6.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/7.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/7.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/8.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/8.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/10.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/10.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/11.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/11.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/12.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/12.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/13.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/13.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/14.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/14.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/15.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/15.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/16.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/16.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/17.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/17.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/18.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/18.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/20.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/20.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/21.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/21.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/22.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/22.jpg"
  },

  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/23.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/23.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/24.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/24.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/25.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/25.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/26.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/26.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/27.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/27.jpg"
  },

  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/29.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/29.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/30.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/30.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/36.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/36.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/37.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/37.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/38.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/38.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/45.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/45.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/46.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/46.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/47.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/47.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/48.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/48.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/50.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/50.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/51.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/51.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/59.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/59.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/65.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/65.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/70.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/70.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/1.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/1.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/19.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/19.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/31.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/31.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/32.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/32.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/33.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/33.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/34.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/34.jpg"
  },

  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/35.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/35.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/39.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/39.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/40.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/40.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/41.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/41.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/42.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/42.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/43.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/43.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/44.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/44.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/49.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/49.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/52.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/52.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/53.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/53.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/54.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/54.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/55.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/55.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/56.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/56.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/57.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/57.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/58.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/58.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/60.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/60.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/61.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/61.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/62.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/62.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/63.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/63.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/64.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/64.jpg"
  },

  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/66.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/66.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/67.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/67.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/68.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/68.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/69.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/69.jpg"
  },
  {
    original: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/71.jpg",
    thumbnail: "/assets/images/portofoliu/ferestre_luminatoare_trape_de_fum/71.jpg"
  }
];

class FerestreLuminatoare extends Component {
  render() {
    return (
      <div className="products-container">
        <Tag name="Ferestre, luminatoare, trape de fum" className="no-padding portfolio" />
        <br />
        <ImageGallery items={images} />
      </div>
    );
  }
}

export default FerestreLuminatoare;
