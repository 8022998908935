import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Bramac.css";
const images = {
  ROSU_CARAMIZIU: {
    link: <img src="/assets/images/tigla_bramac/reviva/reviva_rosu_caramiziu.jpg" className="card-img-top" alt="..." />,
    text: "ROȘU CĂRĂMIZIU",
    className: "text-rosu-caramiziu"
  },
  ANTIC: {
    link: <img src="/assets/images/tigla_bramac/reviva/reviva_antic.jpg" className="card-img-top" alt="..." />,
    text: "ANTIC",
    className: "text-antic"
  },
  ANTRACIT_BRILIANT: {
    link: (
      <img src="/assets/images/tigla_bramac/reviva/reviva_antracit_brilliant.jpg" className="card-img-top" alt="..." />
    ),
    text: "ANTRACIT BRILIANT",
    className: "text-antracit-briliant"
  },
  MARO: {
    link: <img src="/assets/images/tigla_bramac/reviva/reviva_maro.jpg" className="card-img-top" alt="..." />,
    text: "MARO",
    className: "text-maro"
  }
};
class BramacReviva extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_CARAMIZIU.link,
      text: images.ROSU_CARAMIZIU.text,
      className: images.ROSU_CARAMIZIU.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Reviva" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Renaşterea tradiţiei.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description">
                Ţigla Reviva are forma tradiţională pe care o cunoaştem încă din copilărie. Disponibilă în cele mai
                populare culori, care evocă zilele însorite de odinioară. Reviva îmbină tradiţia cu tehnologia modernă
                şi conferă chiar şi casei bunicilor un aspect nou. Cu Reviva de la Bramac, frumuseţea trecutului se va
                păstra şi în viitor.
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_CARAMIZIU)} className="dot rosu-caramiziu"></span>
                  <span onClick={e => this.handleOnClick(images.ANTIC)} className="dot antic"></span>
                  <span
                    onClick={e => this.handleOnClick(images.ANTRACIT_BRILIANT)}
                    className="dot antracit-briliant"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO)} className="dot maro"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BramacReviva;
