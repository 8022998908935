import React, { Component } from "react";
import "./../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
const images = {
  FHB_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/jaluzea.png"
      alt="..."
      className="rulouri-imagine"
    />
  )
};

const squares = {
  DELICIOUS_WHITE_6500: "DELICIOUS WHITE 6500",
  CLASSIC_WHITE_6501: "CLASSIC WHITE 6501",
  STORMY_BEIGE_6502: "STORMY BEIGE 6502",
  DELICIOUS_CREAM_6503: "DELICIOUS CREAM 6503",
  STORMY_SAND_6504: "STORMY SAND 6504",
  WHITE_LEAVES_6505: "WHITE LEAVES 6505",
  WHITE_ORIGAMI_6506: "WHITE ORIGAMI 6506",
  BOMBAY_BEIGE_6507: "BOMBAY BEIGE 6507",
  BEIGE_RAIN_6508: "BEIGE RAIN 6508",
  INFINITE_SAND_6509: "INFINITE SAND 6509",
  DELICIOUS_GREY_6510: "DELICIOUS GREY 6510",
  FABULOUS_BEIGE_6511: "FABULOUS BEIGE 6511",
  FABULOUS_KAKI_6512: "FABULOUS KAKI 6512",
  DELICIOUS_CAPUCCINO_6513: "DELICIOUS CAPUCCINO 6513",
  FABULOUS_BURNED_GREY_6514: "FABULOUS BURNED GREY 6514",
  STYLISH_SILVER_6515: "STYLISH SILVER 6515",
  STRUCTURED_GREY_6516: "STRUCTURED GREY 6516",
  STRUCTURED_CAPUCCINO_6517: "STRUCTURED CAPUCCINO 6517",
  STRUCTURED_CHOCOLATE_6518: "STRUCTURED CHOCOLATE 6518",
  STRUCTURED_BLACK_6519: "STRUCTURED BLACK 6519"
};

class JaluzeaRomana extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuala: images.FHB_MANUAL,
      text: ""
    };
  }

  handleOnMouseOver = e => {
    this.setState({
      text: e
    });
  };

  handleOnMouseOut = e => {
    this.setState({
      text: ""
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Jaluzea romană" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Jaluzea romană</h1>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              {" "}
              <div className="text-description">
                Fii gata să te bucuri de o gamă nouă de soluţii decorative cu noua jaluzea romană VELUX. Explorează
                potenţialul infinit al frumoaselor materiale textile şi lasă-te inspirat de efectele stilistice care
                imprimă rafinament şi originalitate acestei colecţii. Mai mult, datorită designului specific al
                jaluzelei romane, poţi înlocui materialul textil cu un alt model în câteva minute. Trebuie doar să
                alegi!
              </div>
              <div className="text-description">
                Culori disponibile: <strong>{this.state.text}</strong>
              </div>
              <div className="row">
                <div className="squares">
                  <div
                    className="square jaluzea-square _6500"
                    onMouseOver={e => this.handleOnMouseOver(squares.DELICIOUS_WHITE_6500)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6501"
                    onMouseOver={e => this.handleOnMouseOver(squares.CLASSIC_WHITE_6501)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6502"
                    onMouseOver={e => this.handleOnMouseOver(squares.STORMY_BEIGE_6502)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6503"
                    onMouseOver={e => this.handleOnMouseOver(squares.DELICIOUS_CREAM_6503)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6504"
                    onMouseOver={e => this.handleOnMouseOver(squares.STORMY_SAND_6504)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6505"
                    onMouseOver={e => this.handleOnMouseOver(squares.WHITE_LEAVES_6505)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6506"
                    onMouseOver={e => this.handleOnMouseOver(squares.WHITE_ORIGAMI_6506)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6507"
                    onMouseOver={e => this.handleOnMouseOver(squares.BOMBAY_BEIGE_6507)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6508"
                    onMouseOver={e => this.handleOnMouseOver(squares.BEIGE_RAIN_6508)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6509"
                    onMouseOver={e => this.handleOnMouseOver(squares.INFINITE_SAND_6509)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6510"
                    onMouseOver={e => this.handleOnMouseOver(squares.DELICIOUS_GREY_6510)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6511"
                    onMouseOver={e => this.handleOnMouseOver(squares.FABULOUS_BEIGE_6511)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6512"
                    onMouseOver={e => this.handleOnMouseOver(squares.FABULOUS_KAKI_6512)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6513"
                    onMouseOver={e => this.handleOnMouseOver(squares.DELICIOUS_CAPUCCINO_6513)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6514"
                    onMouseOver={e => this.handleOnMouseOver(squares.FABULOUS_BURNED_GREY_6514)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6515"
                    onMouseOver={e => this.handleOnMouseOver(squares.STYLISH_SILVER_6515)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6516"
                    onMouseOver={e => this.handleOnMouseOver(squares.STRUCTURED_GREY_6516)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6517"
                    onMouseOver={e => this.handleOnMouseOver(squares.STRUCTURED_CAPUCCINO_6517)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6518"
                    onMouseOver={e => this.handleOnMouseOver(squares.STRUCTURED_CHOCOLATE_6518)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square jaluzea-square _6519"
                    onMouseOver={e => this.handleOnMouseOver(squares.STRUCTURED_BLACK_6519)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col">
              {" "}
              <div className="row justify-content-md-center">
                <div className="col min-width-rulouri">
                  <Card
                    link={this.state.manuala}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="FHB manual"
                    list={[]}
                    bodyClassName="rulouri-card"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JaluzeaRomana;
