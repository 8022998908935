import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Nelskamp.css";
const images = {
  NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Natural-01.jpg" alt="..." />,
    text: "NATURAL",
    className: "text-natural"
  },
  ROSU_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Rosu-Natural-Engobat-02-2.jpg" alt="..." />,
    text: "ROȘU NATURAL ENGOBAT",
    className: "text-rosu-natural-engobat"
  },
  BORDEAUX_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Bordeaux-Engobat-09.jpg" alt="..." />,
    text: "BORDEAUX ENGOBAT",
    className: "text-bordeaux-engobat"
  },
  MARO_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Maro-Engobat-04.jpg" alt="..." />,
    text: "MARO ENGOBAT",
    className: "text-maro-engobat"
  },
  INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Invechit-Engobat-03-1.jpg" alt="..." />,
    text: "ÎNVECHIT ENGOBAT",
    className: "text-invechit-engobat"
  },
  NEGRU_INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Negru-Invechit-Engobat-32.jpg" alt="..." />,
    text: "NEGRU ÎNVECHIT ENGOBAT",
    className: "text-negru-invechit-engobat"
  },
  ANTRACIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Antracit-Engobat-58-1.jpg" alt="..." />,
    text: "ANTRACIT ENGOBAT",
    className: "text-antracit-engobat"
  },
  NEGRU_MAT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Negru-Mat-Engobat-39-1.jpg" alt="..." />,
    text: "NEGRU MAT ENGOBAT",
    className: "text-negru-mat-engobat"
  },
  MIGDALA_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Migdala-Glazurat-26.jpg" alt="..." />,
    text: "MIGDALĂ GLAZURAT",
    className: "text-migdala-glazurat"
  },
  NEGRU_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Negru-Glazurat-18-2.jpg" alt="..." />,
    text: "NEGRU GLAZURAT",
    className: "text-negru-glazurat"
  },
  VERDE_BRAD_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Verde-Brad-Glazurat-20.jpg" alt="..." />,
    text: "VERDE BRAT GLAZURAT",
    className: "text-verde-brad-glazurat"
  },
  METALIC_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Metalic-Glazurat-29.jpg" alt="..." />,
    text: "METALIC GLAZURAT",
    className: "text-metalic-glazurat"
  },
  NEGRU_COBALT_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Negru-Cobalt-Glazurat-31.jpg" alt="..." />,
    text: "NEGRU COBALT GLAZURAT",
    className: "text-negru-cobalt-glazurat"
  },
  ARS_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Ars-Natural-144.jpg" alt="..." />,
    text: "ARS NATURAL",
    className: "text-ars-natural"
  }
};

class NelskampF10U extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.NATURAL.link,
      text: images.NATURAL.text,
      className: images.NATURAL.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
  
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="F10Ü" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla ceramică profilată F10Ü</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description text-creaton">
                <div>Modelul F10Ü iese în evidență printr-o mare varietate de culori și finisaje.</div>
                <div>
                  {" "}
                  Gama bogată de accesorii ceramice a modelului F10Ü asigură rezolvarea atât funcțională cât și estetică
                  a oricărui detaliu de acoperiș.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.NATURAL)} className="dot natural"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_NATURAL)} className="dot rosu-natural"></span>
                  <span onClick={e => this.handleOnClick(images.BORDEAUX_ENGOBAT)} className="dot bordeaux-engobat"></span>
                  <span onClick={e => this.handleOnClick(images.MARO_ENGOBAT)} className="dot maro-engobat"></span>
                  <span onClick={e => this.handleOnClick(images.INVECHIT_ENGOBAT)} className="dot invechit-engobat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_INVECHIT_ENGOBAT)}
                    className="dot negru-invechit-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.ANTRACIT_ENGOBAT)} className="dot antracit-engobat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_MAT_ENGOBAT)}
                    className="dot negru-mat-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MIGDALA_GLAZURAT)} className="dot migdala-glazurat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_GLAZURAT)}
                    className="dot negru-glazurat-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.VERDE_BRAD_GLAZURAT)}
                    className="dot verde-brad-glazurat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.METALIC_GLAZURAT)} className="dot metalic-glazurat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_COBALT_GLAZURAT)}
                    className="dot negru-cobalt-glazurat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.ARS_NATURAL)} className="dot ars-natural"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NelskampF10U;
