import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { creatonAll } from "../../../misc/creaton";
import { Link } from "react-router-dom";
class Creaton extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Țiglă ceramică" />
        <br />
        <h2>Creaton</h2>
        <hr />
        <div className="text-description">
          Creaton este un producător de țigle ceramice cu o tradiție de peste 130 de ani, fiind membru al grupului
          internațional ETEX, are experiență de zeci de ani și în producția tiglelor din beton de calitate superioară.
          Produsele <strong>Creaton</strong> beneficiază de o garanție de 50 de ani.
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="creaton/balance" className="card-link">
              <Card
                link={creatonAll.balanceRosuNatur}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Balance"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="creaton/biber" className="card-link">
              {" "}
              <Card
                link={creatonAll.biberRosu}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Klassik"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="creaton/domino" className="card-link">
              {" "}
              <Card
                link={creatonAll.dominoRosuNatur}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Domino"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="creaton/futura" className="card-link">
              {" "}
              <Card
                link={creatonAll.futuraRosuNatur}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Futura"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="creaton/premion" className="card-link">
              <Card
                link={creatonAll.premionRosuNatur}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Premion"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="creaton/rapido" className="card-link">
              <Card
                link={creatonAll.rapdioRosuNatur}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Rapido"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="creaton/titania" className="card-link">
              <Card
                link={creatonAll.titaniaRosuCupru}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Titania"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <div className="empty-card"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Creaton;
