import React, { Component } from "react";

class TermeniConditii extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="text-description politica">
          <div>
            Respectăm şi protejăm confidenţialitatea clienţilor noştri. În cele ce urmează, puteţi citi despre felul în
            care prelucrăm datele dvs. personale.
          </div>
          <div>
            Politica de Confidenţialitate Roof Construct International descrie gestionarea datelor furnizate sau
            colectate pe platformele noastre digitale, ceea ce oferă vizitatorului posibilitatea de a ne contacta şi de
            a beneficia de serviciile noastre. Politica de Confidenţialitate Roof Construct International respectă
            Regulamentul General UE privind Protecţia Datelor cu Caracter Personal 2016/679 („GDPR”) și legislația
            locală.
          </div>
          <br />
          <h4>1. Bază legală pentru procesarea datelor</h4>
          <div>
            Atunci când procesăm date personale în legătură cu un contract sau cu un potenţial contract cu dvs., baza
            legală pentru procesare este faptul că aceasta este necesară pentru îndeplinirea contractului cu dvs. sau
            pentru a lua anumite măsuri, la cererea dvs., înainte de încheierea unui contract. Dacă baza legală pentru
            procesare este acordul, aveţi dreptul de a vă retrage acordul în orice moment, după cum se menţionează în
            prezentul document, dar acest lucru nu afectează legitimitatea procesării datelor de către Roof Construct
            International SRL înainte de retragerea acordului dvs. În cazul în care dezvăluim date personale organelor
            de aplicare a legii sau altor organisme guvernamentale, acest lucru se face în baza faptului că avem
            obligaţia legală de a o face.
          </div>
          <br />
          <h4>2. Motivul şi scopul pentru care colectăm date cu caracter personal </h4>
          <div>
            Roof Construct International SRL colectează următoarele categorii principale de date cu caracter personal,
            în vederea atingerii următoarelor scopuri principale:
          </div>
          <br />
          <h5>2.1 Ca parte a operaţiunilor generale ale Roof Construct International</h5>
          <div>
            Colectăm date cu caracter personal ale persoanelor, clienţilor, furnizorilor (inclusiv ale unor terţe părţi,
            furnizoare de servicii) şi ale altor părţi. Aceste date pot include numele persoanelor, detalii de contact
            şi alte informaţii necesare pentru efectuarea operaţiunilor de afaceri cu dvs. sau cu organizaţia dvs.{" "}
          </div>
          <br />
          <h5>2.2 Pentru a răspunde solicitărilor</h5>
          <div>
            Puteţi decide să ne furnizaţi date personale, inclusiv numele dvs., adresa de e-mail sau alte informaţii de
            contact când ne contactaţi prin telefon, e-mail, poştă sau folosind platformele noastre digitale. Aceste
            informaţii personale ne permit să răspundem solicitărilor privind chestiuni legate de produsele si
            serviciile Roof Construct International, detalii privind măsurători şi oferte de preţ pentru montarea
            produselor din portofoliu.{" "}
          </div>
          <br />
          <h5>2.3 Clienţi şi potenţiali clienţi</h5>
          <div>
            Putem colecta datele personale ale clienţilor şi ale potenţialilor clienţi, inclusiv nume, informaţii de
            contact, informaţii privind plata şi carduri de credit, informaţii de credit şi alte informaţii care ne sunt
            necesare pentru efectuarea operaţiunilor cu respectiva persoană sau organizaţie. Aceste informaţii pot fi
            dezvăluite distribuitorilor sau instalatorilor independenţi şi partenerilor de logistică în vederea
            procesării comenzii unui client, inclusiv în vederea stabilirii livrării produselor noastre către clienţi
            sau în vederea răspunsului la solicitări.
          </div>
          <br />
          <h5>2.4 Dezvoltarea afacerii</h5>
          <div>
            Informaţiile personale pe care ni le furnizaţi şi informaţiile personale pe care le colectăm pe platformele
            noastre digitate vor fi folosite pentru a creşte nivelul de înţelegere a clienţilor noştri şi pentru a
            asigura o comunicare relevantă în toate aspectele relaţiei dintre dvs. şi Roof Construct International.
            Datele personale vor fi folosite şi pentru dezvoltarea de servicii.{" "}
          </div>
          <br />
          <h5>2.5 Comunicări de marketing</h5>
          <div>
            Putem folosi datele personale cu acordul dvs., dacă acest lucru este solicitat, pentru a vă informa cu
            privire la operaţiunile, produsele sau serviciile Roof Construct International. Dacă nu doriţi să primiţi
            informaţii ulterioare, puteţi să vă dezabonaţi ușor şi gratuit de la comunicarea noastră de marketing în
            orice moment. Pentru aceasta, vă rugăm să trimiteți un email pe adresa office@roofconstruct.ro, cu subiectul
            DEZABONARE. Dacă vă dezabonaţi de la comunicările de marketing, vom şterge datele dvs. personale în termen
            de două luni, dacă aceste date nu sunt procesate şi în alte scopuri menţionate în secţiunea 2.1-2.9.
          </div>
          <br />
          <h5>2.6 Sondaje ale clienţilor şi vizitatorilor </h5>
          <div>
            Putem colecta date personale de la vizitatorii platformelor digitale sau de la clienţi, ca parte a
            sondajelor noastre privind produsele şi serviciile noastre.{" "}
          </div>
          <br />
          <h5>2.7 Potenţiali angajaţi şi contractori</h5>
          <div>
            Atunci când o persoană se înscrie pentru ocuparea unei poziţii în cadrul companiei sau încheie un contract
            cu noi, putem colecta anumite date personale, cum ar fi numele, informaţii de contact, informaţii
            referitoare la istoricul de muncă, diplome de studii, verificări relevante de dosare şi informaţii
            referitoare la interese profesionale. Acestea pot fi colectate direct de la persoana în cauză, de la un
            consultant de recrutări şi de la angajatorii anteriori ai persoanei sau de la alte persoane, inclusiv din
            referinţe şi surse făcute publice. Aceste informaţii sunt folosite pentru a ne informa sau asista în luarea
            deciziei referitoare la a face o ofertă de angajare sau la încheierea unui contract cu persoana respectivă.
          </div>
          <br />
          <h5>2.8 În vederea respectării legii</h5>
          <div>
            Putem colecta date personale conform cerinţelor sau permisiunilor legii. Datele personale, precum şi cele
            nepersonale colectate de noi, sunt considerate confidenţiale şi nu vor fi niciodată vândute sau cedate unor
            terţe părţi, cu excepţia cazurilor menţionate în secţiunea 2 şi 4.
          </div>
          <br />
          <h4>3. Cum colectăm datele dvs. personale</h4>
          <div>
            Colectăm datele personale pe care ni le furnizaţi atunci când solicitaţi produse, servicii sau informaţii de
            la noi, când vă înregistraţi în vederea participării pe forumuri publice sau alte activităţi pe platformele
            noastre digitale, când răspundeţi la chestionare pentru clienţi sau când interacţionaţi în alt fel cu noi.
          </div>
          <br />
          <h5>3.1 Link-uri la alte pagini web</h5>
          <div>
            Această pagină de internet conţine linkuri către alte pagini de internet (cum ar fi Facebook, Google+,
            YouTube, Pinterest), cărora nu li se aplică Politica de Confidenţialitate Roof Construct International. Vă
            rugăm să ţineţi cont de faptul că nu ne asumăm alte pagini de internet şi conţinutul acestora. Vă încurajăm
            să citiţi politica de confidenţialitate a fiecărei pagini de internet pe care o vizitaţi.
          </div>
          <br />
          <h4>4. Transmiterea datelor dvs. către alte companii</h4>
          <div>Nu vom transmite datele dvs. personale decât cu excepţia anumitor situaţii, în care se includ:</div>
          <ul>
            <li>
              Dacă părţi ale grupului sau terţe părţi asigură servicii pentru noi, de exemplu, livrarea de colete şi
              relaţii cu clienţii. Acestor companii li se interzice să folosească datele dvs. personale pentru alte
              scopuri decât cele solicitate de către noi sau prin lege.
            </li>
            <li>
              Dacă transmitem informaţiile dvs. unor companii din cadrul grupului sau unor terţe părţi pentru a asigura
              siguranţa clienţilor noştri, pentru a ne proteja drepturile şi proprietatea noastră pentru a respecta
              procedurile legale, sau în alte cazuri, în situaţia în care considerăm, cu bună credinţă că dezvăluirea
              informaţiilor respective este solicitată prin lege.
            </li>
            <li>Dacă ne permiteţi să transmitem informaţiile dvs. personale cu terţe părţi, cum ar fi:</li>
            <ul>
              <li>
                Decideţi să transmitem datele dvs personale unor companii atent selectate pentru ca acestea să vă poată
                trimite oferte şi promoţii referitoare la produsele şi serviciile lor{" "}
              </li>
              <li>
                <div>
                  Ne îndrumaţi să transmitem datele dvs. personale unor platforme sau pagini de internet ale unor terţe
                  părţi, cum ar fi paginile de socializare.
                </div>
                În cazul în care cooperăm cu furnizori externi de servicii (procesatori de date), încheiem acorduri de
                procesare a datelor referitoare cu furnizorii de servicii care vă procesează datele dvs. personale.
              </li>
            </ul>
          </ul>
          <div>
            Nu am inclus o listă completă a tuturor celor ce primesc datele personale, doar cei care se găsesc în afara
            UE. Motivul este că suntem parte a unui grup operaţional mare, la nivel global, care lucrează cu un număr
            mare de recipienţi de date personale. Includerea tuturor acestor recipienţi nu ar ajuta la claritate. De
            asemenea, schimbăm în mod periodic furnizorii. Prin urmare, ar fi o povară administrativă uriaşă să
            actualizăm lista tuturor acestor recipienţi. De asemenea, ar trebui să vă informăm de fiecare dată când
            adăugăm sau ştergem un astfel de recipient, ceea ce ar fi împovărător pentru dumneavoastră. De aceea,
            considerăm că a vă prezenta o listă cu categoriile de recipienţi reprezintă o modalitate mai transparentă şi
            mai facilă decât includerea tuturor recipienţilor pe listă.
          </div>
          <br />
          <h4>5. Controlul şi deciziile dumneavoastră</h4>
          <div>
            Vă oferim posibilitatea de a exercita o anumită măsură de control şi anumite decizii referitoare la
            colectarea, folosirea şi transmiterea datelor dvs. personale. În conformitate cu legislaţia locală, nivelul
            dvs. de control şi decizie poate cuprinde:
          </div>
          <ul>
            <li>
              Puteţi modifica deciziile dvs. în ceea ce priveşte subscripţiile, scrisorile de informare şi alertele
            </li>
            <li>
              Puteţi decide dacă primiţi comunicări de marketing de la noi despre produse şi servicii, care credem că
              v-ar interesa
            </li>
            <li>
              Puteţi decide dacă transmitem datele dvs. personale altor companii pentru ca acestea să vă poată trimite
              comunicări de marketing referitoare la produsele şi serviciile lor
            </li>
            <li>
              Puteţi decide dacă doriţi să primiţi reclame de la reţele de publicitate, schimburi de date, analize de
              piaţă şi alţi furnizori de servicii
            </li>
            <li>
              Puteţi solicita accesul la datele dvs. personale pe care le stocăm pentru a corecta informaţiile incorecte
              sau incomplete şi, în anumite împrejurări, puteţi solicita ca datele pe care le stocăm să fie transferate
              unui alt controlor, accesul la ele să fie restricţionat sau ca acestea să fie şterse din registrele
              noastre (drepturi ca persoană fizică). De asemenea, aveţi dreptul să obiectaţi faţă de procesarea datelor
              dvs. personale de către noi.
            </li>
            <li>
              Puteţi exercita controlul şi puteţi lua decizii sau puteţi solicita accesul la datele dvs. personale,
              contactând serviciul de Relaţii cu Clienţii, Responsabilul Local pentru Confidenţialitate sau urmând
              instrucţiunile furnizate în comunicările care v-au fost trimise. Vă rugăm să ţineţi cont de faptul că dacă
              nu ne permiteţi să colectăm date personale de la dvs., este posibil să nu putem să vă furnizăm anumite
              produse şi servicii şi este posibil ca unele din serviciile noastre să nu poată ţină cont de preferinţele
              sau interesele dvs. Dacă aveţi întrebări referitoare la datele personale specifice despre dvs. pe care le
              procesăm sau reţinem, vă rugăm să contactaţi Serviciul de Relaţii cu Clienţii sau Responsabilul Local
              pentru Confidenţialitate.{" "}
            </li>
          </ul>
          <div>
            Vom răspunde solicitării dvs. de a vă exercita oricare dintre drepturile dvs. în termen de 1 lună, dar avem
            dreptul de a prelungi acest interval cu două luni. Dacă vom prelungi intervalul de răspuns, vă vom informa
            în termen de 1 lună de la data solicitării dvs.{" "}
          </div>
          <br />
          <h4>6. Securitatea, integritatea şi reţinerea datelor</h4>
          <div>
            Securitatea, integritatea şi confidenţialitatea datelor dvs. personale sunt deosebit de importante pentru
            noi. Am implementat măsuri tehnice, administrative şi de securitate fizică ce sunt menite să protejeze
            datele dvs. personale de acces, dezvăluire, utilizare şi modificare neautorizate. La anumite intervale, ne
            revizuim procedurile de securitate pentru a include tehnologii şi metode adecvate de dată recentă. Vă rugăm
            să ţineţi cont de faptul că, în ciuda celor mai bune eforturi ale noastre, nicio măsură de securitate nu
            este perfectă sau impenetrabilă. Vom reţine datele dvs. personale atâta timp cât este necesar pentru
            îndeplinirea scopurilor menţionate în prezenta Politică de Confidenţialitate Roof Construct International cu
            excepţia cazului în care o perioadă mai îndelungată de reţinere este cerută sau permisă prin lege. În mod
            regulat, vom împrospăta informaţiile pentru a ne asigura că acestea sunt actuale.
          </div>
          <br />
          <h4>7. Transfer, stocare şi procesare globală a datelor</h4>
          <div>
            Compania noastră acţionează la nivel global şi putem transfera datele dvs. personale anumitor companii sau
            unor terţe părţi din diferite locaţii în lume, în vederea atingerii scopurilor descrise în prezenta Politică
            de Confidenţialitate Roof Construct International. Ori de câte ori datele dvs. personale sunt transferate,
            stocate sau procesate de către noi, vom lua măsuri rezonabile de asigurare a confidenţialităţii datelor dvs.
            personale. În plus, atunci când folosim sau dezvăluim date personale transferate din Uniunea Europeană,
            respectăm prevederile Scuturilor de Confidenţialitate TRUSTe EU-US şi Swiss-U.S, folosim clauze contractuale
            standardizate aprobate de către Comisia Europeană, adoptăm alte mijloace în conformitate cu legislaţia din
            Uniunea Europeană în vederea obţinerii unor măsuri de siguranţă adecvate, sau obţinem acordul dvs.
          </div>
          <div>Datele dvs. personale sunt stocate pe servere amplasate în Uniunea Europeană.</div>
          <br />
          <h4>8. Modificări la Politica de Confidenţialitate ROOF CONSTRUCT INTERNATIONAL</h4>
          <div>
            Ocazional, putem modifica prezenta Politică de Confidenţialitate Roof Construct International pentru a
            cuprinde cele mai recente tehnologii, practici din industrie, cerinţe de reglementare sau pentru alte
            scopuri. Întotdeauna vom posta cea mai recentă versiune pe platformele noastre digitale. De aceea vă sfătuim
            să citiţi cu regularitate Politica de Confidenţialitate. În situaţiile în care suntem obligaţi prin lege,
            vom obţine acordul dvs.
          </div>
          <br />
          <h4>9. Observaţii şi întrebări</h4>
          <div>
            Dacă aveţi observaţii sau întrebări referitor la Politica de Confidenţialitate Roof Construct International,
            vă rugăm să ne contactaţi la telefon 0723481857 sau pe email office@roofconstruct.ro.
          </div>
          <div>
            Puteţi depune o plângere referitoare la felul în care procesăm datele dvs. personale. Ne puteţi contacta
            folosind informaţiile de contact menţionate la sfârşitul prezentei Politici de Confidenţialitate. În cazul
            în care se face o plângere, numele şi informaţiile de contact ale reclamantului trebuie comunicate către
            Roof Construct International S.R.L. Vom investiga reclamaţia şi vom răspunde în termen de 1 lună. În cazul
            în care consideraţi că nu am rezolvat în mod satisfăcător reclamaţia dvs., puteţi depune o plângere la
            Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal, B-dul G-ral Gheorghe
            Magheru, nr. 28-30, sector 1 București, România, e-mail anspdcp@dataprotection.ro.{" "}
          </div>
          <br />
          <h4>10. Informaţii de contact</h4>
          <div>Date de contact:</div>
          <ul>
            <li>Telefon: +40723481857</li>
            <li>E-mail: office@roofconstruct.ro </li>
          </ul>
          <div>Această pagină de internet este deţinută şi operată de către Roof Construct International SRL.</div>
          <div>Prezenta Politică de Confidenţialitate a fost actualizată ultima dată în luna aprilie 2020.</div>
        </div>
      </React.Fragment>
    );
  }
}

export default TermeniConditii;
