import React, { Component } from "react";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { panouriSolare } from "../../misc/panouriSolare";
class ARKPro extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="ARK PRO" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Bramac Solar ARK PRO</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  <strong>Panoul solar BRAMAC – ARK PRO</strong> se poate monta pe orice tip şi configuraţie de
                  acoperiş, fiind foarte uşor de montat. Astfel nimeni nu mai este obligat să renunţe la căldura
                  provenită cu ajutorul energiei solare.
                </div>
                <div className="row">
                  <div className="col min-width">
                    {" "}
                    <div>
                      <strong>Date tehnice:</strong>
                    </div>
                    <li>Geam: Geam securizat solar 3,2 mm, conţinut scăzut de fier, structurat, călit </li>
                    <li>Gradul de transmisie a radiaţiei: 91 %</li>
                    <li>Şipci geam: Aluminiu, eloxat negru</li>
                    <li>
                      Racorduri: 4 x 22 mm (pt fiecare set de prindere, fitinguri, termometru, sensor de conectare)
                    </li>
                    <li>Racord hidraulic tip meandră</li>
                    <li>Racord hidraulic debit: 15-50 L/m²h</li>
                    <li>Protecţie senzor temperatură: Direct în agentul termic </li>
                    <li>Presiune: maximă 10 bar</li>
                    <li>
                      Amplasare: Montajul se poate face pe orice tip de învelitoare tradiţională cu înclinaţii de la 15°
                      la 90°
                    </li>
                  </div>
                  <div className="col min-width">
                    {" "}
                    <div>
                      <strong>Avantajele panoului solar:</strong>
                    </div>
                    <li>Eficienţă mai bună – absorber din aluminiu şi ţevi din cupru</li>
                    <li>Sticlă clară securizată</li>
                    <li>Vană de aluminiu structurată</li>
                    <li>Montaj uşor fără macara</li>
                    <li>Rezistent la intemperii – fără puncte de sudură</li>
                    <li>Flexibilitate ridicată – posibilitate de montaj în diferite unghiuri</li>
                    <li>10 ani garanţie şi certificare de calitate Austria Solar</li>
                    <li>Certificare Solar Keymark</li>
                    <li>Calitate austriaca – Bramac înseamnă seriozitate, siguranţă şi încredere!</li>
                  </div>
                </div>
              </div>
            </div>
            <div className="col min-width-rulouri">
              <Card
                link={panouriSolare.ARK_PRO}
                expandedArray={[false, false, false, false, false]}
                cardTitle="ARK PRO"
                list={[]}
              />
            </div>
          </div>
          <br />
          <h3 className="text-description">Date tehnice</h3>
          <hr />
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Tipul panoului</th>
                <th scope="col">Supraf. de absorbţie</th>
                <th scope="col">Supraf. vitrată </th>
                <th scope="col">Supraf. brută</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">ARK PRO</th>
                <td>2,20 m²</td>
                <td>2,22 m²</td>
                <td>2,38 m²</td>
              </tr>
              <tr>
                <th scope="row">ARK PRO SLIM</th>
                <td>2,20 m²</td>
                <td>2,22 m²</td>
                <td>2,38 m²</td>
              </tr>
              <tr>
                <th scope="col"></th>
                <th scope="col">Dimensiuni</th>
                <th scope="col">Greutate</th>
              </tr>
              <tr>
                <th scope="row">ARK PRO</th>
                <td>2064 x 1154 x 98 mm</td>
                <td>38 kg</td>
              </tr>
              <tr>
                <th scope="row">ARK PRO SLIM</th>
                <td>2064 x 1154 x 68 mm</td>
                <td>32 kg</td>
              </tr>

              <tr>
                {" "}
                <th scope="col"></th>
                <th scope="col">Capacitate umplere panou solar</th>
              </tr>
              <tr>
                <th scope="row">ARK PRO</th>
                <td>1,57 L</td>
              </tr>
              <tr>
                <th scope="row">ARK PRO SLIM</th>
                <td>1,57 L</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col">
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">ARK PRO</th>
                    <th scope="col">ARK PRO SLIM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Factor de conversie</th>
                    <td>78,3%</td>
                    <td>78,5%</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Coeficient linear de pieredere a căldurii K<sub>1</sub>
                    </th>
                    <td>3,3674W/m2 K</td>
                    <td>3,8670W/m2 K</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Coeficient pătratic de pierdere a căldurii K<sub>2</sub>
                    </th>
                    <td>0,0166 W/m2 K</td>
                    <td>0,0181 W/m2 K</td>
                  </tr>
                  <tr>
                    <th scope="row">Unghi de radiație factor colector K(50&deg;)</th>
                    <td>94%</td>
                    <td>94%</td>
                  </tr>
                  <tr>
                    <th scope="row">Temp. în panou în caz de nefuncţionare</th>
                    <td>189&def; C</td>
                    <td>189&def; C</td>
                  </tr>
                  <tr>
                    <th scope="row">Număr înregistrare Solar Keymark</th>
                    <td>011-7S2274 F</td>
                    <td>011-7S2275 F</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col">
              {" "}
              <div className="text-description">
                <div>
                  <strong>Material suprafaţă de absorbţie:</strong> aluminiu de 0,4mm, strat de înaltă fidelitate,
                  aplicat în vacuum.
                </div>
                <li>Grad de absorbţie: 95%</li>
                <li>{"Emisie termică: 5%"}</li>
                <li>Ramă panou ARK PRO: vată minerală solară 40 mm</li>
                <li>Ramă panou ARK PRO SLIM: vată minerală solară 30 mm</li>
                <li>Absorber: Meandre de cupru fi 8 mm, sudate cu laser, 4 posibilităţi de conectare</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ARKPro;
