import React, { Component } from "react";
import Tag from "../../../misc/Tag";

class LindabSerena extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Lindab Serena" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Serena</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col col-figo">
              <div className="text-description text-creaton">
                <br />
                <div>
                  Tigla metalica cu acoperire de granule minerale Roca Serena reproduce intr-o forma inedita modelul
                  tiglelor profilate.
                </div>
                <div>Roca Serena este modelul potrivit pentru constructii cu arhitectura moderna.​​</div>
              </div>
              <img
                src="/assets/images/tigla_metalica_roca/lindab/serena_culori.png"
                alt="culori"
                className="lindab-roca-culori"
              />
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tigla_metalica_roca/lindab/lindab_serena.jpg"
                alt="lindab Serena"
                className="lindab-roca-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabSerena;
