import React, { Component } from "react";
import Tag from "../../../misc/Tag";

const images = {
  ALB: {
    text: "001 ALB",
    className: "alb-010"
  },
  NEGRU: {
    text: "015 NEGRU",
    className: "negru-015"
  },
  GRI_DESCHIS: {
    text: "022 GRI DESCHIS",
    className: "gri-deschis-022"
  },
  GRAFIT: {
    text: "044 GRAFIT",
    className: "grafit-044"
  },
  SILVER_METALIC: {
    text: "045 SILVER METALIC",
    className: "silver-metalic-045"
  },
  GRI_INCHIS: {
    text: "087 GRI ÎNCHIS",
    className: "gri-inchis-087"
  },
  MARO: {
    text: "434 MARO",
    className: "maro-434"
  },
  GRI_NEUTRU: {
    text: "461 GRI NEUTRU",
    className: "gri-neutru-461"
  },
  ALBASTRU_DESCHIS: {
    text: "502 ALBASTRU DESCHIS",
    className: "albastru-deschis-502"
  },
  CARAMIZIU: {
    text: "742 CĂRĂMIZIU",
    className: "caramiziu-742"
  },
  ROSU_INCHIS: {
    text: "758 ROȘU ÎNCHIS",
    className: "rosu-inchis-758"
  },
  ARAMIU: {
    text: "778 ARĂMIU",
    className: "aramiu-778"
  },
  VERDE_PADURE: {
    text: "830 VERDE PĂDURE",
    className: "verde-padure-830"
  },
  VERDE_INCHIS: {
    text: "874 VERDE ÎNCHIS",
    className: "verde-inchis-874"
  },
  VERDE_DESCHIS: {
    text: "975 VERDE DESCHIS",
    className: "verde-deschis-975"
  }
};

class LindabFAP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: images.ALB.text,
      className: images.ALB.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    this.setState({
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="FAP" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Tabla semidură FAP</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              {" "}
              <div className="text-description">
                <div>
                  Tabla plana din otel galvanizat a fost folosita ca material pentru realizarea invelitorilor si a
                  fatadelor cu mult inainte de secolul XIX. De-a lungul anilor, tehnologia de prelucrare a otelului a
                  avansat si odata cu aceasta Lindab a intensificat activitatea de cercetare ce a avut ca urmare
                  promovarea produsul FAP, o tabla plana semidura din otel galvanizat vopsita in sistem multistrat si cu
                  acoperire finala HBPE.
                </div>
              </div>
              <div className="paletar">
                {" "}
                <div className="text-culoare">{this.state.text}</div>
                <div className={this.state.className + " lindab-rectangle"}></div>
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ALB)} className="dot alb-010"></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU)} className="dot negru-015"></span>
                  <span onClick={e => this.handleOnClick(images.GRI_DESCHIS)} className="dot gri-deschis-022"></span>
                  <span onClick={e => this.handleOnClick(images.GRAFIT)} className="dot grafit-044"></span>
                  <span
                    onClick={e => this.handleOnClick(images.SILVER_METALIC)}
                    className="dot silver-metalic-045"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO)} className="dot maro-434"></span>
                  <span onClick={e => this.handleOnClick(images.GRI_NEUTRU)} className="dot gri-neutru-461"></span>
                  <span
                    onClick={e => this.handleOnClick(images.ALBASTRU_DESCHIS)}
                    className="dot albastru-deschis-502"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.CARAMIZIU)} className="dot caramiziu-742"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_INCHIS)} className="dot rosu-inchis-758"></span>
                  <span onClick={e => this.handleOnClick(images.ARAMIU)} className="dot aramiu-778"></span>
                  <span onClick={e => this.handleOnClick(images.VERDE_PADURE)} className="dot verde-padure-830"></span>
                  <span onClick={e => this.handleOnClick(images.VERDE_INCHIS)} className="dot verde-inchis-874"></span>
                  <span
                    onClick={e => this.handleOnClick(images.VERDE_DESCHIS)}
                    className="dot verde-deschis-975"
                  ></span>
                </div>
              </div>
            </div>
            <div className="col">
              <img
                src="/assets/images/tabla_plana_minirulou/lindab/lindab_fap.jpg"
                alt="lindab plx"
                className="lindab-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabFAP;
