import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "./Portfolio.css";
import Tag from "../misc/Tag";
import Card from "../misc/Card";
import { Link } from "react-router-dom";
const images = [
  {
    original: "/assets/images/portofoliu/renovari/1.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/1.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/2.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/2.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/3.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/3.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/4.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/4.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/5.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/5.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/6.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/6.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/7.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/7.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/8.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/8.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/9.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/9.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/10.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/10.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/11.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/11.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/12.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/12.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/13.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/13.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/14.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/14.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/15.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/15.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/16.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/16.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/17.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/17.jpg"
  },

  {
    original: "/assets/images/portofoliu/renovari/18.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/18.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/19.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/19.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/20.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/20.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/21.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/21.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/22.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/22.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/23.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/23.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/24.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/24.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/25.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/25.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/26.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/26.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/27.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/27.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/28.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/28.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/29.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/29.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/30.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/30.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/31.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/31.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/32.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/32.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/33.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/33.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/34.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/34.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/35.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/35.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/36.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/36.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/37.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/37.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/38.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/38.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/39.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/39.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/40.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/40.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/41.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/41.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/42.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/42.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/43.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/43.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/44.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/44.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/45.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/45.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/46.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/46.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/47.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/47.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/48.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/48.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/49.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/49.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/50.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/50.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/51.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/51.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/52.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/52.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/53.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/53.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/54.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/54.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/55.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/55.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/56.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/56.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/57.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/57.jpg"
  },
  {
    original: "/assets/images/portofoliu/renovari/58.jpg",
    thumbnail: "/assets/images/portofoliu/renovari/58.jpg"
  }
];

class Renovari extends Component {
  render() {
    return (
      <div className="products-container">
        <Tag name="Renovări" />
        <br />
        <ImageGallery items={images} />
      </div>
    );
  }
}

export default Renovari;
