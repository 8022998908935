import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "../Ferestre.css";
class RamaEtansare extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Ramă de etanșare" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Ramă de etanşare</h1>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <h3>
                  Nu uita să comanzi rama de etanşare potrivită pentru montajul
                  ferestrei tale
                </h3>
                <hr />
                Noua ta fereastră de mansardă VELUX trebuie să fie montată
                împreună cu o ramă de etanșare. Ramele noastre sunt fabricate cu
                precizie pentru a se potrivi fiecărei ferestre de mansardă VELUX
                și pentru a asigura etanșeitatea la apă între fereastră și
                materialul învelitorii. Sunt create pentru drenarea sigură și
                eficientă a apei și sunt disponibile în diferite versiuni pentru
                a se potrivi diferitelor tipuri de învelitori și combinații de
                ferestre.
              </div>
              <br />
              <div className="text-description">
                <h3>Montaj</h3>
              </div>
              <hr />
              <iframe
                style={{ width: "100%", maxHeight: 340, height: 340 }}
                src="https://www.youtube.com/embed/loTdkuSUSPE"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            <div className="col min-width">
              {" "}
              <img
                src="/assets/images/ferestre_velux/ferestre_mansarda/rama.jpg"
                alt="..."
                className="ferestre-imagine-multiple"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RamaEtansare;
