import React, { Component } from "react";
import "./../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
import $ from "jquery";
const images = {
  RULOU_MHL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/rulou_exterior_parasolar/MHL_manual/mhl_manual.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
  RULOU_MML_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/rulou_exterior_parasolar/MML_electric/mml_electric.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
  RULOU_MSL_SOLAR: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/rulou_exterior_parasolar/MSL_solar/msl_solar.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
  RULOU_MSG: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/rulou_exterior_parasolar/msg.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
};
class RulouParasolar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuala: images.RULOU_MHL_MANUAL,
      electrica: images.RULOU_MML_ELECTRIC,
      solara: images.RULOU_MSL_SOLAR,
      msg: images.RULOU_MSG,
    };
  }
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#last").css("height", $("#previous").outerHeight() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Rulou parasolar" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Rulou parasolar</h1>
          </div>
          <hr />
          <div className="text-description">
            Ruloul exterior parasolar VELUX împiedică razele solare să atingă
            fereastra, reducând temperatura interioară cu până la 5 grade C*.
            Ruloul menţine confortul termic în interior într-o zi călduroasă,
            însorită. Ruloul exterior parasolar este transparent, astfel încât
            te poți bucura de privelişte.
          </div>

          <div className="row justify-content-md-center">
            <div className="col min-width-rulouri">
              <Card
                link={this.state.manuala}
                expandedArray={[false, false, false, false, false]}
                cardTitle="MHL Manual"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
            <div id="previous" className="col min-width-rulouri">
              <Card
                link={this.state.electrica}
                expandedArray={[false, false, false, false, false]}
                cardTitle="MML cu motor Electric"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
            <div id="last" className="col ">
              <Card
                link={this.state.solara}
                expandedArray={[false, false, false, false, false]}
                cardTitle="MSL cu motor solar"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
          </div>
          <br />
          <div className="row justify-content-md-center">
            <div className="col">
              <h3>Prezentare</h3>
              <hr />
              <iframe
                style={{ width: "100%", maxHeight: 340, height: 340 }}
                src="https://www.youtube.com/embed/Mzq8gbv7H3E"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="col">
              <h3>Montaj</h3>
              <hr />
              <iframe
                style={{ width: "100%", maxHeight: 340, height: 340 }}
                src="https://www.youtube.com/embed/yAYruuL6xWE"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col">
              <div className="text-description">
                <h3>MSG</h3>
                <br />
              </div>
              <div className="text-description">
                {" "}
                Montat între fereastră şi acoperiş, ruloul exterior parasolar
                filtrează razele soarelui înainte ca acestea să atingă geamul,
                menţinând confortul termic în interior. Acest rulou este
                fabricat din material tip plasă și este automat, având motor
                solar. Se acționează cu telecomanda şi este compatibil
                io-homecontrol®.
              </div>
            </div>
            <div className="col min-width-rulouri">
              {" "}
              <Card
                link={this.state.msg}
                expandedArray={[false, false, false, false, false]}
                cardTitle="MSG"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RulouParasolar;
