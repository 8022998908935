import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Figo.css";

class FigoRobustJgheaburi extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="FIgo Robust" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Aspect clasic și elegant care atrage atenția.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col col-figo">
              <div className="text-description">
                <div>
                  Figo Robust promite ceea ce sugerează numele. Ploaia puternică, vântul, grindina și alunecările de
                  zăpadă de pe acoperiș reprezintă provocările la care trebuie să facă față un sistem de drenaj. O
                  dimensionare corectă a jgheaburilor, burlanelor și a componentelor acestora, pentru o drenare bună a
                  precipitațiilor, necesită un calcul amănunțit, ținând cont de cantitatea reală de precipitații din
                  zonă. Funcționalitatea corectă a unui sistem de drenaj al apelor pluviale face parte din confortul pe
                  care trebuie să îl ofere un acoperiș.
                </div>
                <hr />
              </div>
              <img
                src="/assets/images/tabla_plana_minirulou/figo/figo_robust_culori.png"
                alt="culori"
                className="figo-culori"
              />
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/sisteme_jgheaburi/figo/figo_robust.jpg"
                alt="figo robust"
                className="figo-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FigoRobustJgheaburi;
