import React, { Component } from "react";
import "./../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import "./ControlLumina.css";
import Card from "../../../../misc/Card";
const images = {
  DFD: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/DFD_manual/dfd_manual.png"
      alt="..."
      className="rulouri-imagine"
    />
  )
};

const squares = {
  ALB_1025: "ALB 1025",
  ALBASTRU_PAL_4555: "ALBASTRU PAL 4555",
  BEJ_DESCHIS_1085: "BEJ DESCHIS 1085",
  MODEL_ESENTIAL_4558: "MODEL ESENTIAL 4558",
  MARO_INCHIS_4559: "MARO INCHIS 4559",
  ROSU_INCHIS_4560: "ROSU INCHIS 4560",
  PURPURIU_INCHIS_4561: "PURPURIU INCHIS 4561",
  MODEL_INUNECAT_4562: "MODEL INTUNECAT 4562",
  CURRY_4563: "CURRY 4563",
  BEJ_4556: "BEJ 4556",
  PORTOCALIU_4564: "PORTOCALIU 4564",
  ROZ_PAL_4565: "ROZ PAL 4565",
  VERDE_MASLINIU_4567: "VERDE MASLINIU 4567",
  MODEL_VEGETAL_4568: "MODEL VEGETAL 4568",
  VERDE_PAL_4569: "VERDE PAL 4569",
  GALBEN_APRINS_4570: "GALBEN APRINS 4570",
  ALBASTRU_DESCHIS_4571: "ALBASTRU DESCHIS 4571",
  ROSU_APRINS_4572: "ROSU APRINS 4572",
  MODEL_GRAFIC_4573: "MODEL GRAFIC 4573",
  ALBASTRU_2055: "ALBASTRU 2055",
  ALBASTRU_INCHIS_1100: "ALBASTRU INCHIS 1100",
  GRI_DESCHIS_1705: "GRI DESCHIS 1705",
  GRI_0705: "GRI 0705",
  NEGRU_3009: "NEGRU 3009",
  MODEL_4650: "MODEL 4650",
  MODEL_4651: "MODEL 4651",
  MODEL_4653: "MODEL 4653",
  MODEL_4654: "MODEL 4654",
  MODEL_4655: "MODEL 4655",
  MODEL_4656: "MODEL 4656",
  MODEL_4657: "MODEL 4657",
  MODEL_4658: "MODEL 4658",
  MODEL_4659: "MODEL 4659",
  MODEL_4660: "MODEL 4660",
  MODEL_4661: "MODEL 4661",
  MODEL_4662: "MODEL 4662",
  MODEL_4663: "MODEL 4663",
  MODEL_4664: "MODEL 4664",
  MODEL_4665: "MODEL 4665",
  MODEL_4666: "MODEL 4666",
  MODEL_4667: "MODEL 4667"
};
class RulouDuo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuala: images.DFD,

      text: ""
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleonMouseOver = e => {
    this.setState({
      text: e
    });
  };

  handleOnMouseOut = () => {
    this.setState({
      text: ""
    });
  };
  handleonMouseOver1 = e => {
    this.setState({
      textDisney: e
    });
  };

  handleOnMouseOut1 = () => {
    this.setState({
      textDisney: ""
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="Rulou DUO" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Rulou DUO</h1>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <div className="text-description">
                Ruloul VELUX Duo este o combinație între un rulou opac și un rulou plisat care îți permite să
                beneficiezi de cantitatea de lumină dorită - ruloul opac blochează pătrunderea luminii, iar ruloul
                plisat diminuează lumina în funcție de preferințe. Cele două rulouri sunt cuplate în tandem printr-o
                bară de operare, cu ajutorul căreia se creează combinația dorită dintre lumina difuză și întuneric.
              </div>
              <br />{" "}
              <div className="text-description">
                Culori disponibile: <strong>{this.state.text}</strong>
              </div>
              <div className="row justify-content-md-left">
                <div className="squares">
                  {" "}
                  <div
                    className="square duo-model-alb-1025"
                    onMouseOver={e => this.handleonMouseOver(squares.ALB_1025)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-albastru-pal-4555"
                    onMouseOver={e => this.handleonMouseOver(squares.ALBASTRU_PAL_4555)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-bej-1085"
                    onMouseOver={e => this.handleonMouseOver(squares.BEJ_DESCHIS_1085)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-esential-4558"
                    onMouseOver={e => this.handleonMouseOver(squares.MODEL_ESENTIAL_4558)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-rosu-inchis-4560"
                    onMouseOver={e => this.handleonMouseOver(squares.ROSU_INCHIS_4560)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-purpuriu-inchis-4561"
                    onMouseOver={e => this.handleonMouseOver(squares.PURPURIU_INCHIS_4561)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-intunecat-4562"
                    onMouseOver={e => this.handleonMouseOver(squares.MODEL_INUNECAT_4562)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-curry-4563"
                    onMouseOver={e => this.handleonMouseOver(squares.CURRY_4563)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-portocaliu-4564"
                    onMouseOver={e => this.handleonMouseOver(squares.PORTOCALIU_4564)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-roz-pal-4565"
                    onMouseOver={e => this.handleonMouseOver(squares.ROZ_PAL_4565)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-verde-masliniu-4567"
                    onMouseOver={e => this.handleonMouseOver(squares.VERDE_MASLINIU_4567)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-vegetal-4568"
                    onMouseOver={e => this.handleonMouseOver(squares.MODEL_VEGETAL_4568)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-verde-pal-4569"
                    onMouseOver={e => this.handleonMouseOver(squares.VERDE_PAL_4569)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-galben-aprins-4570"
                    onMouseOver={e => this.handleonMouseOver(squares.GALBEN_APRINS_4570)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-rosu-aprins-4572"
                    onMouseOver={e => this.handleonMouseOver(squares.ROSU_APRINS_4572)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-grafic-4573"
                    onMouseOver={e => this.handleonMouseOver(squares.MODEL_GRAFIC_4573)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-maro-inchis-4559"
                    onMouseOver={e => this.handleonMouseOver(squares.MARO_INCHIS_4559)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-bej-4556"
                    onMouseOver={e => this.handleonMouseOver(squares.BEJ_4556)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-albastru-deschis-4571"
                    onMouseOver={e => this.handleonMouseOver(squares.ALBASTRU_DESCHIS_4571)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-albastru-2055"
                    onMouseOver={e => this.handleonMouseOver(squares.ALBASTRU_2055)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-albastru-1100"
                    onMouseOver={e => this.handleonMouseOver(squares.ALBASTRU_INCHIS_1100)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-gri-deschis-1705"
                    onMouseOver={e => this.handleonMouseOver(squares.GRI_DESCHIS_1705)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-gri-0705"
                    onMouseOver={e => this.handleonMouseOver(squares.GRI_0705)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                  <div
                    className="square duo-model-negru-3009"
                    onMouseOver={e => this.handleonMouseOver(squares.NEGRU_3009)}
                    onMouseOut={e => this.handleOnMouseOut()}
                  ></div>
                </div>
                
              </div>
              <div className="text-description" style={{marginTop:'20px'}}>
          Modele pentru copii disponibile: <strong>{this.state.textDisney}</strong>
        </div>

        <div className="row">
          <div className="squares">
        
    
            <div
              className="square model-4653"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4653)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
            <div
              className="square model-4654"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4654)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
            <div
              className="square model-4655"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4655)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
          
            <div
              className="square model-4659"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4659)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
            <div
              className="square model-4660"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4660)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
            <div
              className="square model-4661"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4661)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
          
            <div
              className="square model-4665"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4665)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
            <div
              className="square model-4666"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4666)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
            <div
              className="square model-4667"
              onMouseOver={e => this.handleonMouseOver1(squares.MODEL_4667)}
              onMouseOut={e => this.handleOnMouseOut1()}
            ></div>
          </div>
        </div>
            </div>
            <div className="col">
              {" "}
              <div className="row justify-content-md-center">
                <div className="col min-width-rulouri">
                  <Card
                    link={this.state.manuala}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="DFD manual"
                    list={[]}
                    bodyClassName="rulouri-card"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RulouDuo;
