import React, { Component } from "react";
import "./Card.css";
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expandedArray
    };
  }

  handleOnClick = index => {
    var newState = this.state.expanded;
    newState[index] = !this.state.expanded[index];
    this.setState({ expanded: newState });
  };

  renderContent = () => {
    const content = this.props.list.map((object, index) => {
      return (
        <li className="list-group-item">
          <div className="category-title" onClick={e => this.handleOnClick(index)}>
            {object.categoryTitle}
          </div>
          {this.state.expanded[index] && (
            <div className="secondary-list">
              <ul className="list-group ">
                {object.categories.map(category => {
                  return <li className="list-group-item list-link">{category}</li>;
                })}
              </ul>
            </div>
          )}
        </li>
      );
    });
    return content;
  };
  render() {
    return (
      <div className={this.props.cardClassName + " card"}>
        {this.props.link}
        <div className={this.props.bodyClassName + " card-body"}>
          <h4 className="card-title">{this.props.cardTitle}</h4>
        </div>
        <ul className={this.props.className + " list-group list-group-flush"}>{this.renderContent()}</ul>
      </div>
    );
  }
}

export default Card;
