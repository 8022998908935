import React, { Component } from "react";
import "./Creaton.css";
import Tag from "../../../misc/Tag";
const images = {
  CUPRU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/titania/titania_rosu_cupru_angoba.jpg" alt="..." />,
    text: "CUPRU ANGOBA",
    className: "text-cupru-angoba"
  },
  ARDEZIE: {
    link: <img src="/assets/images/tigla_ceramica_creaton/titania/titania_ardezie_angoba.jpg" alt="..." />,
    text: "ARDEZIE",
    className: "text-ardezie"
  },
  ARDEZIE_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/titania/titania_ardezie_smaltuit.jpg" alt="..." />,
    text: "ARDEZIE SMĂLȚUIT",
    className: "text-ardezie-smaltuit"
  },
  MARO_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/titania/titania_maro_smaltuit.jpg" alt="..." />,
    text: "MARO SMĂLȚUIT",
    className: "text-maro-smaltuit"
  },
  NEGRU_MAT_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/titania/titania_negru_mat_angoba.jpg" alt="..." />,
    text: "NEGRU MAT ANGOBA",
    className: "text-negru-mat-angoba"
  },
  NEGRU_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/titania/titania_negru_smaltuit.jpg" alt="..." />,
    text: "NEGRU SMĂLȚUIT",
    className: "text-negru-smaltuit"
  }
};
class CreatonTitania extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.CUPRU_ANGOBA.link,
      text: images.CUPRU_ANGOBA.text,
      className: images.CUPRU_ANGOBA.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
   
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Titania" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Flexibilă cu joc maxim de deplasare.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                <div>Raport preț-performanță de top cu 9 bucăți/m²</div>
                <div>Flexibilitate sporită și montare simplificată datorită spațiului mare de deplasare de 44 mm</div>
                <div>
                  Tehnica ingenioasă de fălțuire asigură o dirijare optimă a apei și oferă protecție sporită împotriva
                  pătrunderii apei pluviale
                </div>
                <div>
                  Cel mai mare joc de deplasare de 44 mm în segmentul de țiglă de acoperiș de la CREATON oferă o
                  ajustare optimă la împărțirea pe lățime a șipcii
                </div>
              </div>
            </div>
            <div className="col min-width">
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.CUPRU_ANGOBA)} className="dot cupru-angoba"></span>
                  <span onClick={e => this.handleOnClick(images.ARDEZIE)} className="dot ardezie"></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU_MAT_ANGOBA)} className="dot negru-mat-angoba"></span>
                  <span onClick={e => this.handleOnClick(images.ARDEZIE_SMALTUIT)} className="dot ardezie-smaltuit"></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU_SMALTUIT)} className="dot negru-smaltuit"></span>
                  <span onClick={e => this.handleOnClick(images.MARO_SMALTUIT)} className="dot maro-smaltuit"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatonTitania;
