import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";

import { dispozitiveComanda } from "../../../misc/dispozitiveComanda";
import "../TuneluriSolare/TuneluriSolare.css";
class DispozitiveComanda extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Dispozitive comandă" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Dispozitive comandă și control</h2>
          </div>
          <hr />
          <div className="text-description">
            <div>
              Ai o fereastră montată la înălțime? Noi avem dispozitive pentru controlul acestora și al rulourilor
              potrivite acestora. Aici găsești tije, adaptoare pentru acționare, dar și o gamă foarte bogată de sisteme
              de acționare electrică sau cu motor solar pentru transformarea ferestrelor manuale în ferestre acționate
              cu telecomanda.
            </div>
          </div>
        </div>
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width">
            <div className="text-title tuneluri-title">
              <h2>Tije şi adaptoare</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                <Card
                  link={dispozitiveComanda.TIJE}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="Tije şi adaptoare"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <div>
                  Tijele și adaptoarele cu operare manuală sunt recomandate pentru deschiderea/închiderea ferestrelor și
                  rulourilor cu operare de sus montate până la maxim 5 m. De exemplu, tija telescopică se fixează în
                  adaptorul montat pe rulou și astfel se acționează ruloul
                </div>
              </div>
            </div>
          </div>
          <div className="col min-width ">
            <div className="text-title tuneluri-title">
              <h2>Protecție</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                <Card
                  link={dispozitiveComanda.PROTECTIE}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="PROTECȚIE"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <div>
                  Blocatorul pentru ferestre asigură o securitate sporită prin blocarea ferestrei. Recomandat în camera
                  copiilor
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width">
            <div className="text-title tuneluri-title">
              <h2>Accesorii electrice sau solare</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                {" "}
                <Card
                  link={dispozitiveComanda.ACCESORII_ELECTRICE}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="ACCESORII ELECTRICE SAU SOLARE"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <div>
                  Poți transforma fereastra ta de mansardă VELUX cu operare manuală într-o fereastră de mansardă
                  acţionată electric sau cu motor solar VELUX INTEGRA® dacă instalezi unul dintre sistemele noastre de
                  acționare electrică. Noi avem soluții pentru orice situație, inclusiv pentru ferestre VELUX
                  achiziționate acum 20 de ani.
                </div>
              </div>
            </div>
          </div>
          <div className="col min-width ">
            <div className="text-title tuneluri-title">
              <h2>Noul kit de control climatic VELUX ACTIVE</h2>
            </div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                {" "}
                <Card
                  link={dispozitiveComanda.VELUX_ACTIVE}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="VELUX ACTIVE"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <div>
                  VELUX ACTIVE cu NETATMO este cea mai simplă metodă pentru a obține un climat interior mai sănătos.
                  Senzorii inteligenți măsoară în permanență temperatura, umiditatea și nivelurile de CO2 din locuință
                  și închid sau deschid ferestrele de mansardă, rulourile și roletele exterioare electrice VELUX
                  INTEGRA®, pentru a crea un climat interior mai sănătos.
                </div>
                <div>
                  <strong>Beneficii esențiale:</strong>
                  <li>
                    <strong>Ventilare bazată pe senzori</strong>: senzorii inteligenți măsoară în permanență
                    temperatura, umiditatea și nivelurile de CO2 și închid sau deschid ferestrele de mansardă
                  </li>
                  <li>
                    <strong>Mereu în control</strong>: folosiți aplicația VELUX ACTIVE pentru a acționa ferestrele de
                    mansardă, rulourile și roletele exterioare electrice VELUX INTEGRA® indiferent de locul în care vă
                    aflați, cu ajutorul smartphone-ului.
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DispozitiveComanda;
