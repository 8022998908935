import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Nelskamp.css";
const images = {
  NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Natural-115-2.jpg" alt="..." />,
    text: "NATURAL ENGOBAT",
    className: "text-natural"
  },
  ROSU_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Rosu-Natural-Engobat-116-1.jpg" alt="..." />,
    text: "ROȘU NATURAL ENGOBAT",
    className: "text-rosu-engobat"
  },

  NATURAL_ARS: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Natural-Ars-155.jpg" alt="..." />,
    text: "NATURAL ARS",
    className: "text-natural-ars"
  },
  ARS_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Ars-Natural-144-5.jpg" alt="..." />,
    text: "ARS NATURAL",
    className: "text-ars-natural"
  },

  MEDITERAN_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Mediteran-Engobat-146.jpg" alt="..." />,
    text: "MEDITERAN ENGOBAT",
    className: "text-mediteran-engobat"
  },

  NEGRU_GRAFIT_ARS: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Negru-Grafit-Ars-160.jpg" alt="..." />,
    text: "NEGRU GRAFIT ARS",
    className: "text-negru-grafit-ars"
  },

  NEGRU_ARDEZIE_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Negru-Ardezie-Engobat-133-1.jpg" alt="..." />,
    text: "NEGRU ARDEZIE ENGOBAT",
    className: "text-negru-ardezie-engobat"
  },

  INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Invechit-Engobat-119-1.jpg" alt="..." />,
    text: "ÎNVECHIT ENGOBAT",
    className: "text-invechit-engobat"
  }
};

class NelskampH15 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATURAL.link,
      text: images.ROSU_NATURAL.text,
      className: images.ROSU_NATURAL.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
  
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="H15" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla ceramică sinusoidală H15</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="textx-description text-creaton">
                <div>
                  Modelul H15 reprezintă varianta actuală a tiglei ceramice sinusoidale tradiționale din Europa.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.NATURAL)} className="dot natural"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_NATURAL)} className="dot rosu-natural"></span>
                  <span onClick={e => this.handleOnClick(images.NATURAL_ARS)} className="dot natural-ars"></span>
                  <span
                    onClick={e => this.handleOnClick(images.MEDITERAN_ENGOBAT)}
                    className="dot mediteran-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU_GRAFIT_ARS)} className="dot negru-grafit-ars"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_ARDEZIE_ENGOBAT)}
                    className="dot negru-ardezie-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.INVECHIT_ENGOBAT)} className="dot invechit-engobat"></span>
                  <span onClick={e => this.handleOnClick(images.ARS_NATURAL)} className="dot ars-natural"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NelskampH15;
