import React, { Component } from "react";
import "./../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import $ from "jquery";

import { sistemeDanfoss } from "../../misc/sistemeDanfoss";
class IncalzirePardoseala extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Încălzire în pardoseală" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Încălzirea în pardoseală</h2>
          </div>
          <hr />
          <div className="text-description">
            <div>
              Este cel mai simplu sistem de incalzire format dintr-un cablu incalzitor si un termostat dispuse pe
              fiecare camera. Acest sistem electric de incalzire in pardoseala ofera confortul maxim distribuind caldura
              uniform in intreaga incapere, creand zona cea mai calda acolo unde este nevoie de ea, in pardoseala.
            </div>
            <div>
              Chiar daca este un sistem electric si multe persoane cand aud de un astfel de sistem de incalzire se
              sperie imediat, de costurile mari cu functionarea !!!, insa realitatea este alta. Fiind vorba despre
              incalzire in pardoseala si in conditiile in care spatiul este bine izolat (atat la exterior cat si sub
              sapa), costurile cu functionarea sunt decente, mai mici cu 10-20% comparativ cu sistemul clasic cu
              radiatoare.
            </div>
            <div>
              Când sistemul de încălzire Danfoss este instalat ca unică sursă de încălzire trebuie folosite cablurile{" "}
              <strong>ECsafe™</strong> cu o putere maximă de 20W/m. Pe langă acestea, benzile adezive Devifast™ vor
              asigura o instalare uşoară şi rapidă. Ca alternativă, puteţi alege <strong>covoraşul încălzitor</strong>{" "}
              cu o putere maximă de 150W/m2.
            </div>
            <div>
              <strong>ECsafe™ 20T</strong> este un cablu electric de încălzire flexibil, de înaltă performanţă, care
              respectă exigenţele riguroase ale clasei M2 a standardului IEC 60800:2009. Materialele de calitate
              superioară şi construcţia robustă ne permit să vă oferim cu plăcere toate cablurile ECsafe™ cu garanţia
              unică de <strong>20 de ani</strong> Danfoss.
            </div>
          </div>
        </div>
        <br />
        <div className="row ">
          <div className="col min-width">
            <div className="text-title tuneluri-title"></div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                <Card
                  link={sistemeDanfoss.ECSAFE}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="ECsafe™ 20T"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <div>
                  <h5>
                    CABLU INCALZITOR ECsafe™ 20T, conductor bifilar circular cu înveliş de protecţie, un cablu rece
                  </h5>
                  <li>Uşor de instalat</li>
                  <li>Cablu flexibil</li>
                  <li>Sigur şi robust</li>
                  <li>Protecţie UV</li>
                  <li>Durată de viaţă mare</li>
                  <li>Garanţie Danfoss warranty 20 de ani</li>
                </div>
              </div>
            </div>
          </div>
          <div id="previous" className="col min-width ">
            <div className="text-title tuneluri-title"></div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                <Card
                  link={sistemeDanfoss.EFTM}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="EFTM"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <div>
                  <h5>COVORAS INCALZITOR EFTM™, cablu bifilar ecranat, o singură conexiune rece</h5>
                  <li>Puternic auto-adeziv</li>
                  <li>Grosime a cablului de 3,0 – 3,5 mm</li>
                  <li> Cablu bifilar </li>
                  <li>Conductori spiralat/masivi</li>
                  <li>Ecranat 100%</li>
                  <li>Gamă de covoraşe de mari dimensiuni</li>
                  <li>Nu conţine PVC</li>
                  <li>Garanţie 10 ani Respectă cerinţele standardului IEC60335-2-96 Certificare SEMKO</li>
                </div>
              </div>
            </div>
          </div>
          <div id="last" className="col min-width ">
            <div className="text-title tuneluri-title"></div>
            <div className="row justify-content-md-center">
              <div className="col min-width-rulouri">
                <Card
                  link={sistemeDanfoss.DEVREG}
                  expandedArray={[false, false, false, false, false]}
                  cardTitle="DEVIreg"
                  list={[]}
                  bodyClassName="rulouri-card"
                />
                <br />
                <div>
                  <h5>TERMOSTAT DEVIreg™ Touch</h5>
                  <li>Temporizator complet flexibil şi intuitiv</li>
                  <li>Display tactil de 2” cu design discret, modern</li>
                  <li>
                    Buton pentru comenzi rapide pentru modul Plecat, Protecţie la îngheţ, Temporizator oprit şi modul
                    Oprit
                  </li>
                  <li>Pachet de reducere a pierderilor energetice</li>
                  <li>Valoare CA minimă</li>
                  <li>Detectare fereastră deschisă</li>
                  <li>Contor de consum</li>
                  <li>
                    A treia generație de termostate inteligente cu funcție de prognozare, pentru optimizare
                    pornire/pornire
                  </li>
                  <li>Monitorizarea avarierii senzorilor</li>
                  <li>Conform EN 60730 certificare acordată de SEMKO</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default IncalzirePardoseala;
