import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Nelskamp.css";
const images = {
  NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Natural-01-1.jpg" alt="..." />,
    text: "NATURAL ENGOBAT",
    className: "text-natural"
  },
  ROSU_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Rosu-Natural-Engobat-02-4.jpg" alt="..." />,
    text: "ROȘU NATURAL ENGOBAT",
    className: "text-rosu-engobat"
  },
  ROSU_ANTIC: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Rosu-Antic-38.jpg" alt="..." />,
    text: "ROȘU ANTIC",
    className: "text-rosu-antic"
  },

  INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Invechit-Engobat-03-2.jpg" alt="..." />,
    text: "ÎNVECHIT ENGOBAT",
    className: "text-invechit-engobat"
  },

  GRI_INCHIS_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Gri-Inchis-Engobat-25.jpg" alt="..." />,
    text: "GRI ÎNCHIS ENGOBAT",
    className: "text-gri-inchis-engobat"
  },

  NEGRU_INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Negru-Invechit-Engobat-32-2.jpg" alt="..." />,
    text: "NEGRU ÎNVECHIT ENGOBAT",
    className: "text-negru-invechit-engobat"
  },

  NEGRU_MAT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Negru-Mat-Engobat-39-3.jpg" alt="..." />,
    text: "NEGRU MAT ENGOBAT",
    className: "text-negru-mat-engobat"
  },

  ANTRACIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Antracit-Engobat-58-2.jpg" alt="..." />,
    text: "ANTRACIT ENGOBAT",
    className: "text-antracit-engobat"
  },

  METALIC_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Metalic-Glazurat-29-1.jpg" alt="..." />,
    text: "METALIC GLAZURAT",
    className: "text-metalic-glazurat"
  },

  NEGRU_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Negru-Glazurat-18-4.jpg" alt="..." />,
    text: "NEGRU GLAZURAT",
    className: "text-negru-glazurat"
  },

  GRI_ZINC_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Gri-Zinc-Glazurat-36.jpg" alt="..." />,
    text: "GRI ZINC GLAZURAT",
    className: "text-gri-zinc-glazurat"
  },

  ARS_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Ars-Natural-144-2.jpg" alt="..." />,
    text: "ARS NATURAL",
    className: "text-ars-natural"
  }
};

class NelskampG10 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATURAL.link,
      text: images.ROSU_NATURAL.text,
      className: images.ROSU_NATURAL.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="G10" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla ceramică dreaptă G10</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                <div>
                  Acest model de țiglă ceramică este optimizat pantru a fi deosebit de rezistent la ploi. Țigla ceramică
                  G10 poate fi instalată intercalat sau aliniat și poate îndeplini cele mai moderne și clare linii
                  conceptuale. Acest model de țiglă ceramica, prin forma sa, deschide noi perspective de design pentru
                  învelitori sau fațade.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.NATURAL)} className="dot natural"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_NATURAL)} className="dot rosu-natural"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_ANTIC)} className="dot rosu-antic"></span>
                  <span onClick={e => this.handleOnClick(images.INVECHIT_ENGOBAT)} className="dot invechit-engobat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.GRI_INCHIS_ENGOBAT)}
                    className="dot gri-inchis-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_INVECHIT_ENGOBAT)}
                    className="dot negru-invechit-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_MAT_ENGOBAT)}
                    className="dot negru-mat-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.ANTRACIT_ENGOBAT)} className="dot antracit-engobat"></span>
                  <span onClick={e => this.handleOnClick(images.METALIC_GLAZURAT)} className="dot metalic-glazurat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_GLAZURAT)}
                    className="dot negru-glazurat-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.GRI_ZINC_GLAZURAT)}
                    className="dot gri-zinc-glazurat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.ARS_NATURAL)} className="dot ars-natural"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NelskampG10;
