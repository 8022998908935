import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
class LindabLTM extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="LTM 300" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h1>LTM 300</h1>
            <hr />
          </div>
          <div className="row">
            <div className="col braas-col">
              <div className="text-description">
                <div>
                  Tigla metalica Lindab LTM 300 vine mai aproape de buzunarul tau, reprezentand o varianta economica din
                  portofoliul de produse Lindab.{" "}
                </div>
                <div>
                  Tigla metalica LTM 300 este disponibila in cele mai populare culori pentru acoperisuri. Stratul final
                  de acoperire este un poliester lucios (PE), cu tabla in culorile maro 434, mros 758 si brun 387 sau
                  poliester mat (MATT), cu tabla in culorile maro 436, mros 758 si gran 035.{" "}
                </div>
              </div>
              <hr />
              <h3>Culori PE</h3>
              <img
                src="/assets/images/tigla_metalica_lindab/ltm300_culori_pe.png"
                alt="ltm300"
                className="lindab-culori"
              />{" "}
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tigla_metalica_lindab/LTM300_maro.jpg"
                alt="LTM"
                className="lindab-metalica-image"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabLTM;
