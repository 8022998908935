import React, { Component } from "react";
import "./../../Products.css";
import "./braas.css";
import Tag from "../../../misc/Tag";
const images = {
  ANTRACIT_BRILIANT: {
    link: <img src="/assets/images/tigla_ceramica_braas/braas_antracit_briliant.jpg" className="card-img-top" alt="..." />,
    text: "ANTRACIT BRILIANT",
    className: "text-antracit-briliant"
  },
  CASTANIU: {
    link: <img src="/assets/images/tigla_ceramica_braas/braas_castaniu.jpg" className="card-img-top" alt="..." />,
    text: "CASTANIU",
    className: "text-castaniu"
  },
  TEAK: {
    link: <img src="/assets/images/tigla_ceramica_braas/braas_teak.jpg" className="card-img-top" alt="..." />,
    text: "TEAK",
    className: "text-teak"
  }
};
class Braas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ANTRACIT_BRILIANT.link,
      text: images.ANTRACIT_BRILIANT.text,
      className: images.ANTRACIT_BRILIANT.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {

    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Braas Smaragd" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Braas Smaragd</h2>
            <hr />
            Inspiră-te din frumusețea naturii. Alege farmecul natural al țiglelor ceramice.
          </div>
          <hr />
          <div className="row">
            <div className="col braas-col">
              <div className="text-description">
                Țigla ceramică <strong>BMI Braas</strong> este alegerea ideală pentru toți cei care doresc să se bucure
                de un acoperiș cu un stil aparte. Culorile sale elegante și forma deosebită de diamant oferă casei un
                aspect exclusivist, unic prin formă și suprafață. Produsele <strong>BMI Braas</strong> beneficiază de o
                garanție de 30 de ani.
              </div>
              <div className="text-description">
                De secole, ţigla ceramică reprezintă frumuseţea clasică a naturii. Produsă din materii prime naturale,
                ţigla ceramică conferă acoperişului casei dumneavoastră un farmec aparte. Disponibilă într-o gamă
                variată de modele, ţigla ceramică Braas vă oferă posibilitatea de a vă configura acoperişul casei în
                funcţie de dorinţele dumneavoastră.
              </div>
            </div>
            <div className="col">
              {" "}
              <div className={this.state.className + " text-culoare text-culoare-braas"}>{this.state.text}</div>
              <div className="tigla-imagine braas-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span
                    onClick={e => this.handleOnClick(images.ANTRACIT_BRILIANT)}
                    className="dot antracit-briliant"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.CASTANIU)} className="dot castaniu"></span>
                  <span onClick={e => this.handleOnClick(images.TEAK)} className="dot teak"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col  padding-top">
              <img
                src="/assets/images/tigla_ceramica_braas/bmi-romania-braas-1.jpg"
                alt="braas-1"
                className="braas-images"
              />
            </div>
            <div className="col  padding-top">
              <img
                src="/assets/images/tigla_ceramica_braas/bmi-romania-braas-2.jpg"
                alt="braas-1"
                className="braas-images"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Braas;
