import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Figo.css";

class FigoHighline extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="FIgo Highline" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Figo Highline</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col col-figo">
              <div className="text-description-figo">
                <br />
                <div>
                  Figo Highline este o tablă special dezvoltată pentru acoperișuri și fațade metalice. <hr />
                  Figo Highline, prin calitatea înaltă a materialului, facilitează îndoirea și prelucrarea pe
                  majoritatea formelor de acoperiș.
                  <hr />
                </div>
                <div className="properties-figo">
                  {" "}
                  <li>Grad scăzut al reflexiilor.</li>
                  <li>Finisaj mat.</li>
                  <li>Suprafață netedă sau structurată.</li>
                  <li>Folie de protecție la cerere.</li>
                </div>
              </div>
              <img
                src="/assets/images/tabla_plana_minirulou/figo/figo_highline_culori.jpg"
                alt="culori"
                className="figo-culori"
              />
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tabla_plana_minirulou/figo/card_figo_highline.png"
                alt="figo highline"
                className="figo-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FigoHighline;
