import React, { Component } from "react";
import "../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { cardThumbs } from "../../Homepage/Main";
import { Link } from "react-router-dom";
import $ from "jquery";
import ScrollableSection, { ScrollableLink } from "react-update-url-on-scroll";
class SistemeInvelitori extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#last").css("height", $("#previous").outerHeight() + "px");
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    console.log("yes");
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Sisteme învelitori" id="top" className="no-padding" />
        <br />
        <h2>Țiglă ceramică și beton</h2> <hr />
        <div className="row">
          <div className="col">
            <Link to="/produse/sisteme-invelitori/tigla-ceramica/creaton" className="card-link">
              <Card
                link={cardThumbs.tiglaCeramicaCreaton}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Creaton"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            <Link to="/produse/sisteme-invelitori/tigla-ceramica/braas/smaragd" className="card-link">
              {" "}
              <Card
                link={cardThumbs.tiglaCeramicaBrass}
                expandedArray={[false, false, false, false, false]}
                cardTitle="BMI BRAAS"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row margin-top">
          <div className="col">
            <Link to="/produse/sisteme-invelitori/tigla-ceramica/nelskamp" className="card-link">
              {" "}
              <Card
                link={cardThumbs.tiglaCeramicaNelskamp}
                expandedArray={[false, false, false, false, false]}
                cardTitle="NELSKAMP"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <Link to="/produse/sisteme-invelitori/tigla-beton/bramac" className="card-link">
              <Card
                link={cardThumbs.tiglaBetonBramac}
                expandedArray={[false, false, false, false, false]}
                cardTitle="BMI BRAMAC"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
        <h2>Țiglă metalică</h2>
        <hr />
        <div className="row">
          <div className="col">
            {" "}
            <Link to="/produse/sisteme-invelitori/tigla-metalica/lindab" className="card-link">
              {" "}
              <Card
                link={cardThumbs.tiglaMetalicaLindab}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Lindab"
                list={[]}
                className=""
              />{" "}
            </Link>
            </div>
           <div className="col"> <Link to="/produse/sisteme-invelitori/tigla-metalica/wetterbest" className="card-link">
              {" "}
              <Card
                link={cardThumbs.tiglaMetalicaWetterbest}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Wetterbest"
                list={[]}
                className=""
              />{" "}
            </Link>
          </div>

     
        </div>
        <br />
        <h2>Tablă plană minirulou</h2>
        <hr />
        <div className="row">
          <div className="col">
            {" "}
            <Link to="/produse/sisteme-invelitori/tabla-plana-minirulou/lindab" className="card-link">
              <Card
                link={cardThumbs.tablaPlanaLindab}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Lindab"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <Link to="/produse/sisteme-invelitori/tabla-plana-minirulou/figo" className="card-link">
              {" "}
              <Card
                link={cardThumbs.tablaPlanaFigo}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Figo"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
        <h2>Țiglă metalică acoperită cu rocă vulcanică</h2>
        <hr />
        <br />
        <div className="row">
          <div className="col">
            {" "}
            <Link to="/produse/sisteme-invelitori/tigla-metalica/roca/lindab" className="card-link">
              {" "}
              <Card
                link={cardThumbs.tiglaMetalicaRocaLindab}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Lindab Roca"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <Link to="/produse/sisteme-invelitori/tigla-metalica/roca/bmi/aerodek" className="card-link">
              <Card
                link={cardThumbs.tiglaMetalicaRocaBmi}
                expandedArray={[false, false, false, false, false]}
                cardTitle="BMI AeroDek"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
        <h2>Sisteme de jgheaburi și burlane</h2>
        <hr />
        <div className="row">
          <div className="col">
            {" "}
            <Link to="/produse/sisteme-invelitori/sisteme-jgheaburi/lindab/rainline" className="card-link">
              <Card
                link={cardThumbs.jgheaburiLindab}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Lindab"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col" id="previous">
            {" "}
            <Link to="/produse/sisteme-invelitori/sisteme-jgheaburi/bmi/stabicor" className="card-link">
              <Card
                link={cardThumbs.jgheaburiStabicor}
                expandedArray={[false, false, false, false, false]}
                cardTitle="BMI Stabicor"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col" id="last">
            {" "}
            <Link to="/produse/sisteme-invelitori/sisteme-jgheaburi/figo/robust" className="card-link">
              {" "}
              <Card
                link={cardThumbs.jgheaburiFigo}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Figo Robust"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SistemeInvelitori;
