import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
import $ from "jquery";
class FerestreTerasaSticlaCurbata extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
  };
  componentDidMount() {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Evacuarea fumului" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Fereastra VELUX pentru evacuarea fumului </h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  Ferestrele de mansardă cu pivotare centrală GGL şi GGU cu operator premontat pentru evacuarea fumului
                  sunt marcate CE în conformitate cu standardul european pentru ventilatoarele de evacuare a fumului
                  natural şi a căldurii EN 12101-2.
                  <div>
                    Fereastra poate fi deschisă pentru o ventilare confortabilă zilnică. Se recomandă până la 200 mm.
                  </div>
                  <div>
                    {" "}
                    Fereastra pentru evacuarea fumului este oferită în două variante, cu sau fără deflectoare de vând,
                    pentru a îndeplini cerinţele diverselor aplicaţii.
                  </div>
                  <div>
                    {" "}
                    Toate variantele de fereastră sunt cu geam interior laminat pentru o siguranţă personală sporită.
                  </div>
                  <div>
                    {" "}
                    Când este activată funcţia de evacuare a fumului, fereastra se deschide la 90° cu un motor cu lanţ
                    şi arcuri cu gaz.
                  </div>
                </div>
              </div>
            </div>
            <div className="col min-width">
              <div className="row">
                <div className="col min-width">
                  {" "}
                  <Card
                    link={ferestreVeluxAll.EVACUARE_1}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră de mansardă pentru evacuarea fumului"
                    list={[]}
                    bodyClassName="evacuare-min-height"
                  />
                </div>
                <div id="previous" className="col min-width">
                  {" "}
                  <Card
                    link={ferestreVeluxAll.EVACUARE_2}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră de mansardă pentru evacuarea fumului cu deflector de vânt"
                    list={[]}
                    bodyClassName="evacuare-min-height-90"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description">
                {" "}
                <div>
                  Fereastra VELUX pentru evacuarea fumului este soluția potrivită în cazul clădirilor care trebuie să
                  îndeplinească cerințele de securitate la incendii, oferind în același timp un grad de confort
                  rezidenților clădirii. Folosită în special pe casa scărilor, se deschide automat în cazul unui
                  incendiu pentru a elibera fumul și gazele din clădire. Domul se deschide 50 de cm în decursul unui
                  minut.
                </div>
                <hr />
                <li>AA fire rated</li>
                <li>Se deschide 50 de cm</li>
                <li>Testată și certificată conform EN ISO 12101-2</li>
                <li>
                  Din punct de vedere vizual, aspectul este similar cu cel al ferestrelor VELUX pentru acoperiș terasă
                </li>
              </div>
            </div>

            <div id="last" className="col min-width">
              {" "}
              <Card
                link={ferestreVeluxAll.CSP}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Evacuarea fumului CSP"
                list={[]}
                className=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreTerasaSticlaCurbata;
