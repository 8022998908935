import React, { Component } from "react";
import "../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
import { Link } from "react-router-dom";
import { rulouriFiltrare } from "../../../../misc/rulouriDecorative.js";
class FiltrareLumina extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Filtrare a luminii" className="no-padding" />
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width-rulouri-decorative">
            <Link
              to="/produse/ferestre-velux/rulouri-decorative/filtrare-lumina/rulou-decorativ"
              className="card-link"
            >
              <Card
                link={rulouriFiltrare.RHL_MANUAL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Rulou decorativ"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>

          <div className="col min-width-rulouri-decorative">
            <Link to="/produse/ferestre-velux/rulouri-decorative/filtrare-lumina/jaluzea-romana" className="card-link">
              <Card
                link={rulouriFiltrare.JALUZEA_ROMANA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Jaluzea romană"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default FiltrareLumina;
