import React, { Component } from "react";
import "../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { sistemeDanfoss } from "../../misc/sistemeDanfoss";
import { Link } from "react-router-dom";
class SistemeDanfoss extends Component {
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Sisteme Danfoss" />
        <br />
        <div className="text-description"></div>
        <div className="row">
          <div className="col">
            {" "}
            <h2>Încălzire în pardoseală</h2>
            <hr />
            <Link to="/produse/sisteme-danfoss/incalzire-in-pardoseala" className="card-link">
              <Card
                link={sistemeDanfoss.INCALZIRE_PARDOSEALA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Încălzire în pardoseală"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <h2>Sisteme degivrare</h2> <hr />{" "}
            <Link to="/produse/sisteme-danfoss/sisteme-degivrare" className="card-link">
              {" "}
              <Card
                link={sistemeDanfoss.SISTEME_DEGIVRARE}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Sisteme degivrare"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SistemeDanfoss;
