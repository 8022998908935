import React, { Component } from "react";
import "./Slider.css";
import { Carousel } from "react-responsive-carousel";
class Slider extends Component {
  render() {
    return (
      <div className="carousel">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={2000}
        >
          <div>
            <img
              src="/assets/images/slideshow/tabla_minirulou_figo.png"
              className="carousel-img"
              alt=".."
            />
          </div>
          <div>
            <img
              src="/assets/images/slideshow/slider_2.png"
              className="carousel-img"
              alt=".."
            />
          </div>

          <div>
            <img
              src="/assets/images/slideshow/slider_4.png"
              className="carousel-img"
              alt=".."
            />
          </div>
          <div>
            <img
              src="/assets/images/slideshow/slider_5.png"
              className="carousel-img"
              alt=".."
            />
          </div>
        </Carousel>
      </div>
    );
  }
}
export default Slider;
