import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
import { Link } from "react-router-dom";
class FerestreMansarda extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Ferestre VELUX" />
        <br />
        <h2>Ferestre de mansardă</h2>
        <hr />
        <div className="row">
          <div className="col">
            {" "}
            <Link to="mansarda/standard" className="card-link">
              <Card
                link={ferestreVeluxAll.STANDARD}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Standard"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="mansarda/standard-plus" className="card-link">
              {" "}
              <Card
                link={ferestreVeluxAll.STANDARD_PLUS}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Standard Plus"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="mansarda/premium" className="card-link">
              {" "}
              <Card
                link={ferestreVeluxAll.PREMIUM}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Premium"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
        <h2>Rame de etanșare</h2>
        <hr />
        <div className="row">
          <div className="col">
            <Link to="mansarda/rama-etansare" className="card-link">
              {" "}
              <Card
                link={ferestreVeluxAll.RAME_ETANSARE}
                expandedArray={[false, false, false, false, false]}
                cardTitle="
                Ramă de etanşare"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col min-width"></div>
          <div className="col min-width"></div>
        </div>
      </div>
    );
  }
}

export default FerestreMansarda;
