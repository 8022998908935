import React, { Component } from "react";
import "./../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import $ from "jquery";

import { sistemeDanfoss } from "../../misc/sistemeDanfoss";
class CosuriDeFumInox extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", "604" + "px");
    $("#last").css("height", $("#previous").outerHeight() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", "604" + "px");

    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Coș de fum inox" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Sisteme de coş de fum din inox – ICS25, PERMETER & PRIMA PLUS</h2>
          </div>
          <hr />
          <img id="previous" src="/assets/images/cosuri_de_fum/inox1.png" alt=".." className="cosuri-de-fum-imagine" />
          <div className="row">
            <div className="col">
              {" "}
              <div className="text-description">
                <h3>Avantaje multiple:</h3>
                <li>Pretabil pentru toate tipurile de combustibil: solid, lichid, gazos</li>
                <li>Gamă largă de diametre interioare</li>
                <li>Rezistenţă la coroziune</li>
                <li>Montaj rapid şi curat</li>
                <li>Siguranţă în funcţionare </li>
              </div>
            </div>
            <div className="col min-width">
              <div className="text-description">
                {" "}
                <h3>Garanție</h3>
                <li>
                  În condiţiile punerii în operă a sistemului complet, producătorul acordă o garanţie de 10 de ani
                  pentru rezistenţă la şocuri termice, rezistenţă la coroziune chimică şi insensibilitate la umiditate a
                  structurii coşului de fum.
                </li>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default CosuriDeFumInox;
