import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Bramac.css";
const images = {
  ROSU_CARAMIZIU: {
    link: <img src="/assets/images/tigla_bramac/donau/donau_rosu_caramiziu.jpg" className="card-img-top" alt="..." />,
    text: "ROȘU CĂRĂMIZIU",
    className: "text-rosu-caramiziu"
  },

  ANTRACIT_BRILIANT: {
    link: (
      <img src="/assets/images/tigla_bramac/donau/donau_antracit_briliant.jpg" className="card-img-top" alt="..." />
    ),
    text: "ANTRACIT BRILIANT",
    className: "text-antracit-briliant"
  },
  MARO: {
    link: <img src="/assets/images/tigla_bramac/donau/donau_maro.jpg" className="card-img-top" alt="..." />,
    text: "MARO",
    className: "text-maro"
  }
};
class BramacDonau extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_CARAMIZIU.link,
      text: images.ROSU_CARAMIZIU.text,
      className: images.ROSU_CARAMIZIU.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Donau" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Ţigla Donau într-o formă specială, ondulată, inovează frumuseţea unei case autentice.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description">
                <div>
                  Când vine vorba de un acoperiş de calitate acum ştii că le poţi avea pe toate: frumuseţe, protecţie şi
                  accesibilitate.
                </div>
                <div>
                  Tigla Donau este disponibilă cu suprafața PROTECTOR în trei variante de culoare: roșu cărămiziu, maro
                  și antracit briliant.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_CARAMIZIU)} className="dot rosu-caramiziu"></span>
                  <span
                    onClick={e => this.handleOnClick(images.ANTRACIT_BRILIANT)}
                    className="dot antracit-briliant"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO)} className="dot maro"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BramacDonau;
