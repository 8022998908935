import React, { Component } from "react";
import "./../../Products.css";
import "./Lindab.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { Link } from "react-router-dom";
import { lindabAll } from "../../../misc/lindab";
class Lindab extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Țiglă metalică" />
        <br />
        <h2>Lindab</h2>
        <hr />
        <div className="text-description">
          <strong>Lindab</strong> este un concern international care dezvolta, produce si promoveaza produse si solutii
          moderne pentru constructii civile& industriale si sisteme de ventilatii pentru un climat interior imbunatatit.{" "}
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="lindab/nordic400" className="card-link">
              <Card
                link={lindabAll.NORDIC}
                expandedArray={[false, false, false, false, false]}
                cardTitle="NORDIC 400"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="lindab/skane" className="card-link">
              {" "}
              <Card
                link={lindabAll.SKANE}
                expandedArray={[false, false, false, false, false]}
                cardTitle="SKANE"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="lindab/ltm300" className="card-link">
              {" "}
              <Card
                link={lindabAll.LTM}
                expandedArray={[false, false, false, false, false]}
                cardTitle="LTM 300"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Lindab;
