import React, { Component } from "react";
import "./Creaton.css";
import Tag from "../../../misc/Tag";
const images = {
  GRI: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_gri_angoba.jpg" alt="..." />,
    text: "GRI ANGOBA",
    className: "text-gri-angoba"
  },
  ROSU_NATUR: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_rosu_natur.jpg" alt="..." />,
    text: "ROȘU NATUR",
    className: "text-rosu-natur"
  },
  CUPRU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_rosu_cupru_angoba.jpg" alt="..." />,
    text: "CUPRU ANGOBA",
    className: "text-cupru-angoba"
  },
  MARO_INCHIS_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_maro_inchis_angoba.jpg" alt="..." />,
    text: "MARO ÎNCHIS ANGOBA",
    className: "text-maro-inchis-angoba"
  },
  NEGRU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_negru_angoba.jpg" alt="..." />,
    text: "NEGRU ANGOBA",
    className: "text-negru-angoba"
  },
  NEGRU_MAT_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_negru_mat_angoba.jpg" alt="..." />,
    text: "NEGRU MAT ANGOBA",
    className: "text-negru-mat-angoba"
  },
  ROSU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_rosu_cupru_angoba.jpg" alt="..." />,
    text: "ROȘU ANGOBA",
    className: "text-rosu-angoba"
  },
  ROSU_VIN_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_rosu_vin_angoba.jpg" alt="..." />,
    text: "ROȘU VIN ANGOBA",
    className: "text-rosu-vin-angoba"
  },
  ADREZIE_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_ardezie_angoba.jpg" alt="..." />,
    text: "ARDEZIE ANGOBA",
    className: "text-ardezie-angoba"
  },
  ROSU_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_rosu_smaltuit.jpg" alt="..." />,
    text: "ROȘU SMĂLȚUIT",
    className: "text-rosu-smaltuit"
  },
  VERDE_INCHIS: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_verde_inchis.jpg" alt="..." />,
    text: "VERDE ÎNCHIS",
    className: "text-verde-inchis"
  },
  NOBLESSE_MARO_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_noblesse_maro_smaltuit.jpg" alt="..." />,
    text: "NOBLESSE MARO SMĂLȚUIT",
    className: "text-noblesse-maro-smaltuit"
  },
  ALBASTRU_INCHIS: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_albastru_inchis.jpg" alt="..." />,
    text: "ALBASTRU ÎNCHIS",
    className: "text-albastru-inchis"
  },

  MARO_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_maro_smaltuit.jpg" alt="..." />,
    text: "MARO SMĂLȚUIT",
    className: "text-maro-smaltuit"
  },
  NEGRU_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_negru_smaltuit.jpg" alt="..." />,
    text: "NEGRU SMĂLȚUIT",
    className: "text-negru-smaltuit"
  },
  ARDEZIE_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_ardezie_smaltuit.jpg" alt="..." />,
    text: "ARDEZIE SMĂLȚUIT",
    className: "text-ardezie-smaltuit"
  },

  ROSU_VIN_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/futura/futura_rosu_vin_smaltuit.jpg" alt="..." />,
    text: "ROȘU VIN SMĂLȚUIT",
    className: "text-rosu-vin-smaltuit"
  }
};
class CreatonFutura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATUR.link,
      text: images.ROSU_NATUR.text,
      className: images.ROSU_NATUR.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Futura" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Fără rosturi spre viitor.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                {" "}
                <div>Spațiu de deplasare deosebit de mare de 29 mm per țiglă</div>
                <div>
                  Materiile prime de mare clasă permit 15 culori fascinante în patru variații catifelate de suprafață
                </div>
                <div>
                  Suprapunere fără rosturi și comportament optim la curgere grație unei construcții tehnice de tip nou
                </div>
                <div>
                  „Tehnica de labirint” cu cinci nervuri laterale special formate și deosebit de pronunțate împiedică
                  pătrunderea apei pluviale chiar și în caz de rafale puternice de vânt
                </div>
                <div>
                  Fălțuirea, dirijarea apei și coeficientul de rezistență la scurgere deosebit de avantajos transformă
                  FUTURA într-o țiglă extraordinară, adecvată atât pentru acoperișuri cu pantă mai mică, cât și foarte
                  înclinate{" "}
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATUR)} className="dot rosu-natur"></span>
                  <span
                    name="rosu-natur"
                    onClick={e => this.handleOnClick(images.CUPRU_ANGOBA)}
                    className="dot cupru-angoba"
                  ></span>
                  <span
                    name="cupru-angoba"
                    onClick={e => this.handleOnClick(images.ROSU_VIN_ANGOBA)}
                    className="dot rosu-vin-angoba"
                  ></span>
                  <span
                    name="rosu-vin-angoba"
                    onClick={e => this.handleOnClick(images.MARO_INCHIS_ANGOBA)}
                    className="dot maro-inchis-angoba"
                  ></span>
                  <span
                    name="maro-inchis-angoba"
                    onClick={e => this.handleOnClick(images.GRI)}
                    className="dot gri"
                  ></span>
                  <span
                    name="adrezie-angoba"
                    onClick={e => this.handleOnClick(images.ADREZIE_ANGOBA)}
                    className="dot adrezie-angoba"
                  ></span>
                  <span
                    name="negru-angoba"
                    onClick={e => this.handleOnClick(images.NEGRU_MAT_ANGOBA)}
                    className="dot negru-mat-angoba"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.ROSU_SMALTUIT)}
                    className="dot rosu-smaltuit"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.ROSU_VIN_SMALTUIT)}
                    className="dot rosu-vin-smaltuit"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.ARDEZIE_SMALTUIT)}
                    className="dot ardezie-smaltuit"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.NEGRU_SMALTUIT)}
                    className="dot negru-smaltuit"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.MARO_SMALTUIT)}
                    className="dot maro-smaltuit"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.ALBASTRU_INCHIS)}
                    className="dot albastru-inchis"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.VERDE_INCHIS)}
                    className="dot verde-inchis"
                  ></span>
                  <span
                    name="negru-mat-angoba"
                    onClick={e => this.handleOnClick(images.NOBLESSE_MARO_SMALTUIT)}
                    className="dot noblesse-maro-smaltuit"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatonFutura;
