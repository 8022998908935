import React from "react";
export const sistemeDanfoss = {
  INCALZIRE_PARDOSEALA: (
    <img src="/assets/images/sisteme_danfoss/incalzire_pardoseala.png" className="card-img-top" alt="..." />
  ),
  SISTEME_DEGIVRARE: (
    <img src="/assets/images/sisteme_danfoss/sisteme_degivrare.png" className="card-img-top" alt="..." />
  ),
  ECSAFE: <img src="/assets/images/sisteme_danfoss/ecsafe.png" className="card-img-top" alt="..." />,
  EFTM: <img src="/assets/images/sisteme_danfoss/eftm.png" className="card-img-top" alt="..." />,
  DEVREG: <img src="/assets/images/sisteme_danfoss/devreg.png" className="card-img-top" alt="..." />,
  DEGIVRARE1: <img src="/assets/images/sisteme_danfoss/degivrare1.png" className="poza" alt="..." />,
  DEGIVRARE2: <img src="/assets/images/sisteme_danfoss/degivrare2.png" className="poza" alt="..." />,
  DEGIVRARE3: <img src="/assets/images/sisteme_danfoss/degivrare3.png" className="poza" alt="..." />,
  DEGIVRARE4: <img src="/assets/images/sisteme_danfoss/degivrare4.png" className="poza" alt="..." />,
  DEGIVRARE5: <img src="/assets/images/sisteme_danfoss/degivrare5.png" className="poza" alt="..." />,
  DEVISNOW: <img src="/assets/images/sisteme_danfoss/devisnow.png" className="card-img-top" alt="..." />,
  UNITATE_CONTROL: <img src="/assets/images/sisteme_danfoss/unitate_control.png" className="card-img-top" alt="..." />,
  SENZOR_SOL: <img src="/assets/images/sisteme_danfoss/senzor_sol.png" className="card-img-top" alt="..." />,
  SENZOR_ACOPERIS: <img src="/assets/images/sisteme_danfoss/senzor_acoperis.png" className="card-img-top" alt="..." />,
  SURSA_ENERGIE: <img src="/assets/images/sisteme_danfoss/sursa_energie.png" className="card-img-top" alt="..." />
};
