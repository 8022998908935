import React, { Component } from "react";
import "./../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
import $ from "jquery";
const images = {
  ROLETA_SSS: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSS/SSS.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
  ROLETA_SHL_MANUALA_2: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SHL/SHL_manual_2.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),

  ROLETA_SML_ELECTRICA_1: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SML_electric/SML_electric1.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
  ROLETA_SML_ELECTRICA_2: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SML_electric/SML_electric2.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
  ROLETA_SSL_SOLARA_1: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSL_solar/SSL_solar1.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
  ROLETA_SSL_SOLARA_2: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSL_solar/SSL_solar2.png"
      alt="..."
      className="rulouri-imagine"
    />
  ),
};
class RoletaExterioara extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuala: images.ROLETA_SSS,
      electrica: images.ROLETA_SML_ELECTRICA_2,
      solara: images.ROLETA_SSL_SOLARA_2,
    };
  }

  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#last").css("height", $("#previous").outerHeight() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  handleonClickSHL = (e) => {
    this.setState({
      manuala: e,
    });
  };
  handleOnClickExt = (e) => {
    this.setState({
      electrica: e,
    });
  };
  handleOnClickSol = (e) => {
    this.setState({
      solara: e,
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="Roletă exterioară" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Roletă exterioară</h1>
          </div>
          <hr />
          <div className="text-description">
            Este perfectă pentru orice condiții climaterice și pentru orice
            perioadă a anului. Previne supraîncălzirea în timpul verii și reduce
            pierderile de căldură în timpul iernii. Oferă posibilitatea
            controlului total al luminii în orice moment al zilei.
          </div>
          <hr />
          <div className="row justify-content-md-center">
            <div className="col min-width-rulouri">
              <Card
                link={this.state.manuala}
                expandedArray={[false, false, false, false, false]}
                cardTitle="SSS"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="rulouri-culori">
                <span className="rulouri-culoare">
                  <img
                    src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSS/SSS_culoare1.jpg"
                    className="rulouri-culoare"
                    alt=".."
                  />
                </span>

                <span className="rulouri-culoare">
                  <img
                    src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSS/SSS_culoare2.jpg"
                    className="rulouri-culoare"
                    alt=".."
                  />
                </span>
                <span className="rulouri-culoare">
                  <img
                    src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSS/SSS_culoare3.jpg"
                    className="rulouri-culoare"
                    alt=".."
                  />
                </span>
              </div>
            </div>
            <div id="previous" className="col min-width-rulouri">
              {" "}
              <Card
                link={this.state.electrica}
                expandedArray={[false, false, false, false, false]}
                cardTitle="SML cu motor electric"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="rulouri-culori">
                <span className="rulouri-culoare">
                  <img
                    src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SML_electric/sml_electric_culoare1.png"
                    className="rulouri-culoare"
                    onClick={(e) =>
                      this.handleOnClickExt(images.ROLETA_SML_ELECTRICA_1)
                    }
                    alt=".."
                  />
                </span>
                <span className="rulouri-culoare">
                  <img
                    src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SML_electric/sml_electric_culoare2.png"
                    className="rulouri-culoare"
                    onClick={(e) =>
                      this.handleOnClickExt(images.ROLETA_SML_ELECTRICA_2)
                    }
                    alt=".."
                  />
                </span>
              </div>
            </div>
            <div id="last" className="col ">
              <Card
                link={this.state.solara}
                expandedArray={[false, false, false, false, false]}
                cardTitle="SSL cu motor solar"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="rulouri-culori">
                <span className="rulouri-culoare">
                  <img
                    src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSL_solar/SSL_solar_culoare1.png"
                    className="rulouri-culoare"
                    onClick={(e) =>
                      this.handleOnClickSol(images.ROLETA_SSL_SOLARA_1)
                    }
                    alt=".."
                  />
                </span>
                <span className="rulouri-culoare">
                  <img
                    src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSL_solar/SSL_solar_culoare2.png"
                    className="rulouri-culoare"
                    onClick={(e) =>
                      this.handleOnClickSol(images.ROLETA_SSL_SOLARA_2)
                    }
                    alt=".."
                  />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              {" "}
              <div className="text-description">
                Roleta solara economică oferă o protecție eficientă împotriva
                căldurii, menținând o atmosferă răcoroasă în zilele călduroase.
                Materialul special din care este fabricată oferă totodată un
                efect foarte bun de întunecare. Roleta vine cu un întrerupător
                presetat fără fir, pentru operare ușoară.
              </div>
            </div>
            <div className="col">
              {" "}
              <iframe
                style={{ width: "100%", maxHeight: 340, height: 340 }}
                src="https://www.youtube.com/embed/w2mE1jnFe9s"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoletaExterioara;
