import React, { Component } from "react";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { panouriSolare } from "../../misc/panouriSolare";
class IndaxPeInvelitoare extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Montaj pe acoperiş" className="no-padding" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Sistemul cu montaj pe acoperiş</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  Curentul obţinut cu ajutorul energiei solare este una dintre cele mai valoroase forme de energie pe
                  care o avem la dispoziţie în zilele noastre. Transformarea energiei solare în energie electrică prin
                  intermediul modulelor fotovoltaice nu consumă resurse naturale.
                </div>
                <div>
                  <strong>Sistemul fotovoltaic Bramac cu montaj pe acoperiş</strong> oferă posibilitatea de echipare a
                  propriului acoperiş cu o instalaţie de module, atât pentru clădiri noi, cât şi pentru clădiri
                  reabilitate. Sistemul se poate instala cu un efort minim pe suprafaţa acoperişului, în condiţiile în
                  care învelitoarea existentă se păstrează în cea mai mare parte. Sistemul are o posibilitate flexibilă
                  de extindere de la o instalaţie mică de 1 kW până la acoperişuri solare cu suprafaţa de acoperire
                  mare.
                </div>
                <div>Se poate instala cu un efort minim atât pe clădiri noi, câtşi pe clădiri reabilitate.</div>
                <br />
                <li>Garanție BMI: 12 ani</li>
              </div>
            </div>
            <div className="col min-width-rulouri">
              <Card
                link={panouriSolare.INDAX_FOTOVOLTAIC}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Montaj pe acoperiş"
                list={[]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IndaxPeInvelitoare;
