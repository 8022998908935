import React from "react";
export const tuneluriSolare = {
  TCF_FLEXIBIL: (
    <img
      src="/assets/images/ferestre_velux/tuneluri_solare/acoperis_terasa/TCF_flexibil.png"
      className="card-img-top"
      alt="..."
    />
  ),
  TCR_RIGID: (
    <img
      src="/assets/images/ferestre_velux/tuneluri_solare/acoperis_terasa/TCR_rigid.png"
      className="card-img-top"
      alt="..."
    />
  ),
  TWF_FLEXIBIL: (
    <img
      src="/assets/images/ferestre_velux/tuneluri_solare/acoperis_inclinat/twf_flexibil.png"
      className="card-img-top"
      alt="..."
    />
  ),
  TWR_RIGID: (
    <img
      src="/assets/images/ferestre_velux/tuneluri_solare/acoperis_inclinat/twr_rigid.png"
      className="card-img-top"
      alt="..."
    />
  )
};
