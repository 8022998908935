import React, { Component } from "react";
import "./../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
import $ from "jquery";
const images = {
  RFL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rfl_manual.png"
      className="rulouri-imagine"
      alt="..."
    />
  ),
  RHL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rhl_manual.png"
      className="rulouri-imagine"
      alt="..."
    />
  ),
  RML_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rml_electric.png"
      className="rulouri-imagine"
      alt="..."
    />
  ),
  RSL_SOLAR: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rsl_solar.png"
      className="rulouri-imagine"
      alt="..."
    />
  ),
  PLISAT_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/fmk.png"
      className="rulouri-imagine"
      alt="..."
    />
  )
};

const squares = {
  ALB_1028: "ALB 1028",
  BEJ_1086: "BEJ 1086",
  ALBASTRU_1952: "ALBASTRU 1952",
  GRANULAT_NATURAL_4000: "GRANULAT NATURAL 4000",
  MARO_INCHIS_4060: "MARO INCHIS 4060",
  NEGRU_4069: "NEGRU 4069",
  GALBEN_APRINS_4073: "GALBEN APRINS 4073",
  VERDE_MASLINIU_4079: "VERDE MASLINIU 4079",
  NISIP_4155: "NISIP 4155",
  MODEL_MINIMALIST_4156: "MODEL MINIMALIST 4156",
  PURPURIU_INCHIS_4157: " PURPURIU INCHIS 4157",
  MODEL_ROMANTIC_4158: "MODEL ROMANTIC 4158",
  ROSU_APRINS_4159: "ROSU APRINS 4159",
  MODEL_CONSTRUCTIVIST_4160: "MODEL CONSTRUCTIVIST 4160",
  ALBASTRU_INCHIS_9050: "ALBASTRU INCHIS 9050",
  ALB_1016: "ALB 1016",
  CLASSIC_SAND_1259: "CLASSIC SAND 1259",
  ALBASTRU_METALIC_1265: "ALBASTRU METALIC 1265"
};

class FiltrareRulouDecorativ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RFL_manuala: images.RFL_MANUAL,
      RHL_manuala: images.RHL_MANUAL,
      electrica: images.RML_ELECTRIC,
      solara: images.RSL_SOLAR,
      plisat_electric: images.PLISAT_ELECTRIC,
      text: "",
      textRhl: "",
      textFmk: ""
    };
  }
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#last").css("height", $("#previous").outerHeight() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }
  handleonMouseOver = e => {
    this.setState({
      text: e
    });
  };

  handleOnMouseOut = () => {
    this.setState({
      text: ""
    });
  };
  handleonMouseOverRhl = e => {
    this.setState({
      textRhl: e
    });
  };

  handleOnMouseOutRhl = () => {
    this.setState({
      textRhl: ""
    });
  };
  handleonMouseOverFmk = e => {
    this.setState({
      textFmk: e
    });
  };

  handleOnMouseOutFmk = () => {
    this.setState({
      textFmk: ""
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="Rulou decorativ" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Rulou decorativ</h1>
          </div>
          <hr />
          <div className="text-description">
            Ruloul decorativ VELUX constituie o modalitate simplă pentru diminuarea razelor puternice ale soarelui și
            asigurarea intimității.
          </div>
          <br />

          <div className="row">
            <div className="col min-width-rulouri">
              <Card
                link={this.state.RHL_manuala}
                expandedArray={[false, false, false, false, false]}
                cardTitle="RHL manual"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
            <div className="col min-width-rulouri">
              <Card
                link={this.state.RFL_manuala}
                expandedArray={[false, false, false, false, false]}
                cardTitle="RFL manual"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
            <div id="previous" className="col min-width-rulouri">
              <Card
                link={this.state.electrica}
                expandedArray={[false, false, false, false, false]}
                cardTitle="RML cu motor electric"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
            <div id="last" className="col">
              <Card
                link={this.state.solara}
                expandedArray={[false, false, false, false, false]}
                cardTitle="RSL cu motor solar"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
          </div>
          <br />
          <div className="text-description">
            Culorile disponibile pentru modelul RHL: <strong>{this.state.textRhl}</strong>
          </div>
          <div className="row">
            <div className="squares">
              <div
                className="square alb-1028"
                onMouseOver={e => this.handleonMouseOverRhl(squares.ALB_1028)}
                onMouseOut={e => this.handleOnMouseOutRhl()}
              ></div>
              <div
                className="square bej-1086"
                onMouseOver={e => this.handleonMouseOverRhl(squares.BEJ_1086)}
                onMouseOut={e => this.handleOnMouseOutRhl()}
              ></div>
              <div
                className="square granulat_natural_4000"
                onMouseOver={e => this.handleonMouseOverRhl(squares.GRANULAT_NATURAL_4000)}
                onMouseOut={e => this.handleOnMouseOutRhl()}
              ></div>
              <div
                className="square _9050"
                onMouseOver={e => this.handleonMouseOverRhl(squares.ALBASTRU_INCHIS_9050)}
                onMouseOut={e => this.handleOnMouseOutRhl()}
              ></div>
            </div>
          </div>
          <div className="text-description">
            Culori disponibile pentru celelalte modele: <strong>{this.state.text}</strong>
          </div>
          <div className="row">
            <div className="squares">
              {" "}
              <div
                className="square alb-1028"
                onMouseOver={e => this.handleonMouseOver(squares.ALB_1028)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square bej-1086"
                onMouseOver={e => this.handleonMouseOver(squares.BEJ_1086)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square albastru-1952"
                onMouseOver={e => this.handleonMouseOver(squares.ALBASTRU_1952)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square granulat_natural_4000"
                onMouseOver={e => this.handleonMouseOver(squares.GRANULAT_NATURAL_4000)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square maro_inchis_4060"
                onMouseOver={e => this.handleonMouseOver(squares.MARO_INCHIS_4060)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square negru_4069"
                onMouseOver={e => this.handleonMouseOver(squares.NEGRU_4069)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square galben_aprins_4073"
                onMouseOver={e => this.handleonMouseOver(squares.GALBEN_APRINS_4073)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square verde_masliniu_4079"
                onMouseOver={e => this.handleonMouseOver(squares.VERDE_MASLINIU_4079)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square nisip_4155"
                onMouseOver={e => this.handleonMouseOver(squares.NISIP_4155)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square model-minimalist_4156"
                onMouseOver={e => this.handleonMouseOver(squares.MODEL_MINIMALIST_4156)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square purpuriu_inchis_4157"
                onMouseOver={e => this.handleonMouseOver(squares.PURPURIU_INCHIS_4157)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square model_romantic_4158"
                onMouseOver={e => this.handleonMouseOver(squares.MODEL_ROMANTIC_4158)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square rosu_aprins_4159"
                onMouseOver={e => this.handleonMouseOver(squares.ROSU_APRINS_4159)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square model_constructivist_4160"
                onMouseOver={e => this.handleonMouseOver(squares.MODEL_CONSTRUCTIVIST_4160)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
              <div
                className="square _9050"
                onMouseOver={e => this.handleonMouseOver(squares.ALBASTRU_INCHIS_9050)}
                onMouseOut={e => this.handleOnMouseOut()}
              ></div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <h3>Ruloul Plisat Electric </h3>
              <div className="text-description">
                Perfect pentru diminuarea luminii naturale şi pentru a adăuga o notă decorativă, ruloul plisat electric
                se deschide şi se închisde ușor cu telecomanda. Ruloul este compatibil io-homecontrol şi este disponibil
                în trei culori. Ruloul este potrivit perfect ferestrelor electrice pentru acoperiș terasă, model CVP.
                Pentru montarea la ferestrele fixe pentru acoperiș terasă, model CFP, achiziționează și un sistem de
                operare electrică model KUX 110, care necesită conectare la rețeaua electrică.
              </div>
              <div className="text-description">
                Culori disponibile pentru modelul FMK: <strong>{this.state.textFmk}</strong>
              </div>
              <div className="row">
                <div className="squares">
                  {" "}
                  <div
                    className="square alb-1016"
                    onMouseOver={e => this.handleonMouseOverFmk(squares.ALB_1016)}
                    onMouseOut={e => this.handleOnMouseOutFmk()}
                  ></div>
                  <div
                    className="square classic-sand-1259"
                    onMouseOver={e => this.handleonMouseOverFmk(squares.CLASSIC_SAND_1259)}
                    onMouseOut={e => this.handleOnMouseOutFmk()}
                  ></div>
                  <div
                    className="square albastru-metalic-1265"
                    onMouseOver={e => this.handleonMouseOverFmk(squares.ALBASTRU_METALIC_1265)}
                    onMouseOut={e => this.handleOnMouseOutFmk()}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col min-width-rulouri">
              <Card
                link={this.state.plisat_electric}
                expandedArray={[false, false, false, false, false]}
                cardTitle="FMK"
                list={[]}
                bodyClassName="rulouri-card"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiltrareRulouDecorativ;
