import React, { Component } from "react";
import Tag from "../misc/Tag";
import "./Contact.css";
import $ from "jquery";
import TermeniConditii from "../TermeniConditii";
// Get the modal
var modal = document.getElementById("myModal");

// Get the button that opens the modal
var btn = document.getElementById("myBtn");

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks on the button, open the modal

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      nrTelefon: "",
      checked: false,
      showWarning: false,
    };
  }
  updateDimensions = () => {
    $(".modal-content").height($(window).height() * 0.97);
  };
  componentDidMount() {
    $(".modal-content").height($(window).height() * 0.97);
    window.addEventListener("resize", this.updateDimensions);
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        $(".modal").css("display", "none");
      }
    };
  }

  handleOnCheck = (e) => {
    this.setState({ checked: !this.state.checked, showWarning: false });
  };

  handleAcord = (e) => {
    this.setState({ checked: true, showWarning: false });
  };

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.checked) {
      var me = this;
      $.ajax({
        type: "POST",
        url: "https://roofconstruct.ro/send.php",
        dataType: "json",
        data: me.state,
        success: function (response) {
          if (response.success) {
            alert(response.success);
            me.resetForm();
          } else if (response.error) {
            alert(response.error);
          }
        },
      });
    } else {
      this.setState({ showWarning: true });
    }
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
      nrTelefon: "",
      checked: false,
      showWarning: false,
    });
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  onTelChange(event) {
    let cleaned = ("" + event.target.value).replace(/\D/g, "");
    if (cleaned.length <= 15) {
      this.setState({ nrTelefon: cleaned });
    }
  }

  render() {
    console.log(this.state.nrTelefon);
    return (
      <React.Fragment>
        <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <center>
                  {" "}
                  <div className="politica-title">
                    POLITICA DE CONFIDENŢIALITATE ROOF CONSTRUCT INTERNATIONAL
                  </div>
                </center>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <TermeniConditii />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={(e) => this.handleAcord()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Sunt de acord
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Închide
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="products-container">
          <Tag name="Contact" />

          <br />
          <div className="row">
            <div className="col min-width">
              <h3>S.C. Roof Construct International S.R.L.</h3>
              <div>CUI: RO36363899</div>
              <div>Reg. com.: J 40/10115/2016</div>
              <div>Str. Valea Oltului nr. 12, Sector 6, București</div>
              <div>Telefon: 0723481857</div>
              <div>E-mail: office@roofconstruct.ro</div>
              <br />
              <div>
                <form
                  id="contact-form"
                  onSubmit={this.handleSubmit.bind(this)}
                  method="POST"
                >
                  <div className="form-group">
                    <label htmlFor="name">Nume</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={this.state.name}
                      onChange={this.onNameChange.bind(this)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Adresa de e-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      value={this.state.email}
                      onChange={this.onEmailChange.bind(this)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputSubject">Subiect</label>
                    <input
                      type="subject"
                      className="form-control"
                      id="subject"
                      aria-describedby="subjectHelp"
                      value={this.state.subject}
                      onChange={this.onSubjectChange.bind(this)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputSubject">
                      Număr de telefon
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nrTelefon"
                      aria-describedby="nrTelefonHelp"
                      value={this.state.nrTelefon}
                      onChange={this.onTelChange.bind(this)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Mesaj</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      id="message"
                      value={this.state.message}
                      onChange={this.onMessageChange.bind(this)}
                    />
                  </div>
                  <div
                    class={
                      this.state.showWarning
                        ? "form-check outline-red"
                        : "form-check"
                    }
                  >
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      onClick={(e) => this.handleOnCheck()}
                      checked={this.state.checked}
                    />
                    Am citit și sunt de acord cu{" "}
                    <strong
                      data-toggle="modal"
                      data-target="#myModal"
                      className="termeni"
                    >
                      Termenii și Condițiile.
                    </strong>
                  </div>
                  <br />
                  <button type="submit" className="btn btn-primary">
                    Trimitere
                  </button>
                </form>
              </div>
            </div>

            <br />
            <div className="col min-width">
              {" "}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.7392310931605!2d26.011818015741365!3d44.417996510656586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b200443f23e0cd%3A0x40fc970add432d1b!2sBloc%20C9%2C%20Strada%20Valea%20Oltului%2012%2C%20Bucure%C8%99ti%20061974!5e0!3m2!1sro!2sro!4v1584551176286!5m2!1sro!2sro"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                className="map"
              ></iframe>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
