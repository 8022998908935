import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Nelskamp.css";
const images = {
  ROSU_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F14/Rosu-Natural-Engobat-115.jpg" alt="..." />,
    text: "ROȘU NATURAL ENGOBAT",
    className: "text-rosu-engobat"
  },
  ROSU_CUPRU_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F14/Rosu-Cupru-Engobat-142-1.jpg" alt="..." />,
    text: "ROȘU CUPRU ENGOBAT",
    className: "text-rosu-cupru-engobat"
  },

  MARO_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F14/Maro-Engobat-118-1.jpg" alt="..." />,
    text: "MARO ENGOBAT",
    className: "text-maro-engobat"
  },
  MARO_INCHIS_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F14/Maro-Inchis-Engobat-134.jpg" alt="..." />,
    text: "MARO ÎNCHIS ENGOBAT",
    className: "text-maro-engobat"
  },
  NEGRU_INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F14/Negru-Invechit-Engobat-141-2.jpg" alt="..." />,
    text: "NEGRU ÎNVECHIT ENGOBAT",
    className: "text-negru-invechit-engobat"
  },
  NEGRU_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F14/Negru-Glazurat-120-1.jpg" alt="..." />,
    text: "NEGRU GLAZURAT",
    className: "text-negru-glazurat-engobat"
  }
};

class NelskampF14 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATURAL.link,
      text: images.ROSU_NATURAL.text,
      className: images.ROSU_NATURAL.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {

    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="F14" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla ceramică profilată F14</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                <div>
                  Modelul F14 este varianta puțin mai mare a țiglei ceramice clasice profilate produsă în majoritatea
                  țărilor europene.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATURAL)} className="dot rosu-natural"></span>
                  <span
                    onClick={e => this.handleOnClick(images.ROSU_CUPRU_ENGOBAT)}
                    className="dot rosu-cupru-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO_ENGOBAT)} className="dot maro-engobat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.MARO_INCHIS_ENGOBAT)}
                    className="dot maro-inchis-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_INVECHIT_ENGOBAT)}
                    className="dot negru-invechit-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_GLAZURAT)}
                    className="dot negru-glazurat-engobat"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NelskampF14;
