import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Main from "./components/Homepage/Main";

import Portfolio from "./components/Portfolio/Portfolio";
import Services from "./components/Services/Services";

import Contact from "./components/Contact/Contact";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import SistemeInvelitori from "./components/produse/SistemeInvelitori/SistemeInvelitori";
import FerestreVelux from "./components/produse/FerestreVelux/FerestreVelux";
import PanouriSolare from "./components/produse/PanouriSolare/PanouriSolare";
import CosuriDeFum from "./components/produse/CosuriDeFum/CosuriDeFum";
import ScrollUpButton from "react-scroll-up-button";
import TrapeDeFum from "./components/produse/TrapeDeFum";
import Creaton from "./components/produse/SistemeInvelitori/Creaton/Creaton";
import CreatonBalance from "./components/produse/SistemeInvelitori/Creaton/CreatonBalance";
import CreatonBiber from "./components/produse/SistemeInvelitori/Creaton/CreatonBiber";
import CreatonDomino from "./components/produse/SistemeInvelitori/Creaton/CreatonDomino";
import CreatonFutura from "./components/produse/SistemeInvelitori/Creaton/CreatonFutura";
import CreatonPremion from "./components/produse/SistemeInvelitori/Creaton/CreatonPremion";
import CreatonRapido from "./components/produse/SistemeInvelitori/Creaton/CreatonRapido";
import CreatonTitania from "./components/produse/SistemeInvelitori/Creaton/CreatonTitania";
import Nelskamp from "./components/produse/SistemeInvelitori/Nelskamp/Nelskamp";
import NelskampF10U from "./components/produse/SistemeInvelitori/Nelskamp/NelskampF10U";
import NelskampF12U from "./components/produse/SistemeInvelitori/Nelskamp/NelskampF12U";
import NelskampF13 from "./components/produse/SistemeInvelitori/Nelskamp/NelskampF13";
import NelskampF14 from "./components/produse/SistemeInvelitori/Nelskamp/NelskampF14";
import NelskampF15 from "./components/produse/SistemeInvelitori/Nelskamp/NelskampF15";
import NelskampG10 from "./components/produse/SistemeInvelitori/Nelskamp/NelskampG10";
import NelskampH15 from "./components/produse/SistemeInvelitori/Nelskamp/NelskampH15";
import Braas from "./components/produse/SistemeInvelitori/Braas/Braas";
import Bramac from "./components/produse/SistemeInvelitori/Bramac/Bramac";
import BramacTectura from "./components/produse/SistemeInvelitori/Bramac/BramacTectura";
import BramacReviva from "./components/produse/SistemeInvelitori/Bramac/BramacReviva";
import BramacAdria from "./components/produse/SistemeInvelitori/Bramac/BramacAdria";
import BramacAlpinaClasic from "./components/produse/SistemeInvelitori/Bramac/BramacAlpinaClasic";
import BramacDonau from "./components/produse/SistemeInvelitori/Bramac/BramacDonau";
import BramacRomana from "./components/produse/SistemeInvelitori/Bramac/BramacRomana";
import Lindab from "./components/produse/SistemeInvelitori/Lindab/Lindab";
import LindabNordic from "./components/produse/SistemeInvelitori/Lindab/LindabNordic";
import LindabSkane from "./components/produse/SistemeInvelitori/Lindab/LindabSkane";
import LindabLTM from "./components/produse/SistemeInvelitori/Lindab/LindabLTM";
import LindabMinirulou from "./components/produse/SistemeInvelitori/Lindab/LindabMinirulou";
import LindabPLX from "./components/produse/SistemeInvelitori/Lindab/LindabPLX";
import LindabFAP from "./components/produse/SistemeInvelitori/Lindab/LindabFAP";
import LindabLXD from "./components/produse/SistemeInvelitori/Lindab/LindabLXD";
import Wetterbest from "./components/produse/SistemeInvelitori/Wetterbest/Wetterbest";
import Figo from "./components/produse/SistemeInvelitori/Figo/Figo";
import FigoHighline from "./components/produse/SistemeInvelitori/Figo/FigoHighline";
import FigoRobust from "./components/produse/SistemeInvelitori/Figo/FigoRobust";
import LindabRoca from "./components/produse/SistemeInvelitori/Lindab/LindabRoca";
import LindabToscana from "./components/produse/SistemeInvelitori/Lindab/LindabToscana";
import LindabMadera from "./components/produse/SistemeInvelitori/Lindab/LindabMadera";
import LindabRustica from "./components/produse/SistemeInvelitori/Lindab/LindabRustica";
import LindabSerena from "./components/produse/SistemeInvelitori/Lindab/LindabSerena";
import BMIAerodek from "./components/produse/SistemeInvelitori/BMI/BMIAerodek";
import LindabRainline from "./components/produse/SistemeInvelitori/Lindab/LindabRainline";
import BMIStabicor from "./components/produse/SistemeInvelitori/BMI/BMIStabicor";
import FigoRobustJgheaburi from "./components/produse/SistemeInvelitori/Figo/FigoRobustJgheaburi";
import FerestreMansarda from "./components/produse/FerestreVelux/FerestreMansarda/FerestreMansarda";
import FerestreMansardaStandard from "./components/produse/FerestreVelux/FerestreMansarda/FerestreMansardaStandard";
import FerestreMansardaStandardPlus from "./components/produse/FerestreVelux/FerestreMansarda/FerestreMansardaStandardPlus";
import FerestreMansardaPremium from "./components/produse/FerestreVelux/FerestreMansarda/FerestreMansardaPremium";
import FerestreTerasa from "./components/produse/FerestreVelux/FerestreTerasa/FerestreTerasa";
import FerestreTerasaSticlaCurbata from "./components/produse/FerestreVelux/FerestreTerasa/FerestreTerasaSticlaCurbata";
import FerestreTerasaSticlaPlata from "./components/produse/FerestreVelux/FerestreTerasa/FerestreTerasaSticlaPlata";
import FerestreTerasaCupolaAcrilica from "./components/produse/FerestreVelux/FerestreTerasa/FerestreTerasaCupolaAcrilica";
import FerestreTerasaEvacuareaFumului from "./components/produse/FerestreVelux/FerestreTerasa/FerestreTerasaEvacuareaFumului";
import RamaEtansare from "./components/produse/FerestreVelux/FerestreMansarda/RamaEtansare";
import RulouriDecorative from "./components/produse/FerestreVelux/RulouriDecorative/RulouriDecorative";
import RulouriControlCaldura from "./components/produse/FerestreVelux/RulouriDecorative/ControlCaldura/RulouriControlCaldura";
import RoletaExterioara from "./components/produse/FerestreVelux/RulouriDecorative/ControlCaldura/RoletaExterioara";
import RulouParasolar from "./components/produse/FerestreVelux/RulouriDecorative/ControlCaldura/RulouParasolar";
import ControlLumina from "./components/produse/FerestreVelux/RulouriDecorative/ControlLumina/ControlLumina";
import RulouOpac from "./components/produse/FerestreVelux/RulouriDecorative/ControlLumina/RulouOpac";
import RulouDuo from "./components/produse/FerestreVelux/RulouriDecorative/ControlLumina/RulouDuo";
import FiltrareLumina from "./components/produse/FerestreVelux/RulouriDecorative/FiltrareLumina/FiltrareLumina";
import FiltrareRulouDecorativ from "./components/produse/FerestreVelux/RulouriDecorative/FiltrareLumina/FiltrareRulouDecorativ";
import JaluzeaRomana from "./components/produse/FerestreVelux/RulouriDecorative/FiltrareLumina/JaluzeaRomana";
import ProtectieInsecte from "./components/produse/FerestreVelux/RulouriDecorative/ProtectieInsecte/ProtectieInsecte";
import TuneluriSolare from "./components/produse/FerestreVelux/TuneluriSolare/TuneluriSolare";
import AcoperisTerasa from "./components/produse/FerestreVelux/TuneluriSolare/AcoperisTerasa";
import AcoperisInclinat from "./components/produse/FerestreVelux/TuneluriSolare/AcoperisInclinat";
import DispozitiveComanda from "./components/produse/FerestreVelux/DispozitiveComanda/DispozitiveComanda";
import BSDPro from "./components/produse/PanouriSolare/BSDPro";
import ARKPro from "./components/produse/PanouriSolare/ARKPro";
import Indax from "./components/produse/PanouriSolare/Indax";
import IndaxPeInvelitoare from "./components/produse/PanouriSolare/IndaxPeInvelitoare";
import SistemeDanfoss from "./components/produse/Danfoss/SistemeDanfoss";
import IncalzirePardoseala from "./components/produse/Danfoss/IncalzirePardoseala";
import SistemeDegivrare from "./components/produse/Danfoss/SistemeDegivrare";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import $ from "jquery";
import LucrariNoi from "./components/Portfolio/LucrariNoi";
import FerestreLuminatoare from "./components/Portfolio/FerestreLuminatoare";
import IncalzireDegivrari from "./components/Portfolio/IncalzireDegivrari";
import Renovari from "./components/Portfolio/Renovari";
import CosuriDeFumCeramic from "./components/produse/CosuriDeFum/CosuriDeFumCeramic";
import CosuriDeFumInox from "./components/produse/CosuriDeFum/CosuriDeFumInox";
import TermeniConditii from "./components/TermeniConditii";
import Termeni from "./components/Termeni";
import WetterbestColosseum from "./components/produse/SistemeInvelitori/Wetterbest/WetterbestColosseum";
import WetterbestPlus from "./components/produse/SistemeInvelitori/Wetterbest/WetterbestPlus";
import WetterbestGladiator from "./components/produse/SistemeInvelitori/Wetterbest/WetterbestGladiator";
import WetterbestClasic from "./components/produse/SistemeInvelitori/Wetterbest/WetterbestClasic";
import WetterbestCardinal from "./components/produse/SistemeInvelitori/Wetterbest/WetterbestCardinal";
const override = css`
  display: block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-color: red;
`;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    var me = this;
    $(window).on("load", function () {
      me.setState({ loading: false });
    });
  }

  render() {
    return (
      <Router>
        {this.state.loading ? (
          <div className="sweet-loading">
            <BounceLoader
              css={override}
              size={150}
              color={"#123abc"}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <div className="App">
            <React.Fragment>
              <Header />
              <ScrollUpButton />

              <div className="main-container">
                <div className="body-section">
                  <div className="body-container">
                    <Route exact path="/" component={Main} />
                    <Route exact path="/index.html" component={Main} />
                    <Route exact path="/termeni-conditii" component={Termeni} />

                    <Route
                      exact
                      path="/produse/sisteme-invelitori"
                      component={SistemeInvelitori}
                    />
                    {/*Creaton */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton"
                      component={Creaton}
                    />

                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton/balance"
                      component={CreatonBalance}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton/biber"
                      component={CreatonBiber}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton/domino"
                      component={CreatonDomino}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton/futura"
                      component={CreatonFutura}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton/premion"
                      component={CreatonPremion}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton/rapido"
                      component={CreatonRapido}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/creaton/titania"
                      component={CreatonTitania}
                    />
                    {/*end of Creaton */}
                    {/*braas */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/braas/smaragd"
                      component={Braas}
                    />
                    {/*end of braas*/}

                    {/*nelskamp */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp"
                      component={Nelskamp}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp/f10u"
                      component={NelskampF10U}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp/f12u"
                      component={NelskampF12U}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp/f13"
                      component={NelskampF13}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp/f14"
                      component={NelskampF14}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp/f15"
                      component={NelskampF15}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp/g10"
                      component={NelskampG10}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-ceramica/nelskamp/h15"
                      component={NelskampH15}
                    />
                    {/*end of nelskamp */}
                    {/*bramac */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-beton/bramac"
                      component={Bramac}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-beton/bramac/tectura"
                      component={BramacTectura}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-beton/bramac/reviva"
                      component={BramacReviva}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-beton/bramac/adria"
                      component={BramacAdria}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-beton/bramac/alpina-clasic"
                      component={BramacAlpinaClasic}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-beton/bramac/donau"
                      component={BramacDonau}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-beton/bramac/romana"
                      component={BramacRomana}
                    />
                    {/*end of bramac */}
                    {/*lindab/tigla-metalica*/}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/lindab"
                      component={Lindab}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/lindab/nordic400"
                      component={LindabNordic}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/lindab/skane"
                      component={LindabSkane}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/lindab/ltm300"
                      component={LindabLTM}
                    />
                    {/*end of lindab/tigla-metalica */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/wetterbest"
                      component={Wetterbest}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/wetterbest/colosseum"
                      component={WetterbestColosseum}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/wetterbest/plus"
                      component={WetterbestPlus}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/wetterbest/gladiator"
                      component={WetterbestGladiator}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/wetterbest/clasic"
                      component={WetterbestClasic}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/wetterbest/cardinal"
                      component={WetterbestCardinal}
                    />
                    {/*tabla plana minirulou lindab*/}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tabla-plana-minirulou/lindab"
                      component={LindabMinirulou}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tabla-plana-minirulou/lindab/plx"
                      component={LindabPLX}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tabla-plana-minirulou/lindab/fap"
                      component={LindabFAP}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tabla-plana-minirulou/lindab/lxd"
                      component={LindabLXD}
                    />
                    {/*end of tabla plana minirulou lindab */}
                    {/*tabla plana minirulou figo*/}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tabla-plana-minirulou/figo"
                      component={Figo}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tabla-plana-minirulou/figo/highline"
                      component={FigoHighline}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tabla-plana-minirulou/figo/robust"
                      component={FigoRobust}
                    />
                    {/*end of tabla plana minirulou figo */}
                    {/*tigla metalica roca lindab */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/roca/lindab"
                      component={LindabRoca}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/roca/lindab/toscana"
                      component={LindabToscana}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/roca/lindab/madera"
                      component={LindabMadera}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/roca/lindab/rustica"
                      component={LindabRustica}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/roca/lindab/serena"
                      component={LindabSerena}
                    />
                    {/*end of tigla metalica roca lindab*/}
                    {/*tigla metalica roca bmi */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/tigla-metalica/roca/bmi/aerodek"
                      component={BMIAerodek}
                    />
                    {/*end of tigla metalica roca bmi*/}
                    {/*sisteme jgheaburi */}
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/sisteme-jgheaburi/lindab/rainline"
                      component={LindabRainline}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/sisteme-jgheaburi/bmi/stabicor"
                      component={BMIStabicor}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-invelitori/sisteme-jgheaburi/figo/robust"
                      component={FigoRobustJgheaburi}
                    />
                    {/*end of sisteme jgheaburi */}
                    {/*ferestre velux  */}
                    {/*ferestre velux mansarda */}
                    <Route
                      exact
                      path="/produse/ferestre-velux"
                      component={FerestreVelux}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/mansarda"
                      component={FerestreMansarda}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/mansarda/standard"
                      component={FerestreMansardaStandard}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/mansarda/standard-plus"
                      component={FerestreMansardaStandardPlus}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/mansarda/premium"
                      component={FerestreMansardaPremium}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/mansarda/rama-etansare"
                      component={RamaEtansare}
                    />
                    {/*end of ferestre velux mansarda*/}
                    {/*ferestre velux terasa */}
                    <Route
                      exact
                      path="/produse/ferestre-velux/terasa"
                      component={FerestreTerasa}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/terasa/sticla-curbata"
                      component={FerestreTerasaSticlaCurbata}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/terasa/sticla-plata"
                      component={FerestreTerasaSticlaPlata}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/terasa/cupola-acrilica"
                      component={FerestreTerasaCupolaAcrilica}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/terasa/evacuarea-fumului"
                      component={FerestreTerasaEvacuareaFumului}
                    />
                    {/*end of ferestre velux terasa*/}
                    {/* end of ferestre velux */}
                    {/*rulouri decorative */}
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative"
                      component={RulouriDecorative}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/control-caldura"
                      component={RulouriControlCaldura}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/control-caldura/roleta-exterioara"
                      component={RoletaExterioara}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/control-caldura/rulou-parasolar"
                      component={RulouParasolar}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/control-lumina"
                      component={ControlLumina}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/control-lumina/rulou-opac"
                      component={RulouOpac}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/control-lumina/rulou-duo"
                      component={RulouDuo}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/filtrare-lumina"
                      component={FiltrareLumina}
                    />

                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/filtrare-lumina/rulou-decorativ"
                      component={FiltrareRulouDecorativ}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/filtrare-lumina/jaluzea-romana"
                      component={JaluzeaRomana}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/rulouri-decorative/protectie-insecte/plasa-zil"
                      component={ProtectieInsecte}
                    />
                    {/*end of rulouri decorative */}
                    {/*tuneluri solare */}
                    <Route
                      exact
                      path="/produse/ferestre-velux/tuneluri-solare"
                      component={TuneluriSolare}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/tuneluri-solare/acoperis-terasa"
                      component={AcoperisTerasa}
                    />
                    <Route
                      exact
                      path="/produse/ferestre-velux/tuneluri-solare/acoperis-inclinat"
                      component={AcoperisInclinat}
                    />
                    {/*end of tuneluri solare */}
                    {/* dispozitive comanda */}
                    <Route
                      exact
                      path="/produse/ferestre-velux/dispozitive-comanda"
                      component={DispozitiveComanda}
                    />
                    {/*end of dispozitive comanda */}
                    <Route
                      exact
                      path="/produse/panouri-solare-fotovoltaice"
                      component={PanouriSolare}
                    />
                    <Route
                      exact
                      path="/produse/panouri-solare-fotovoltaice/panouri-solare/montaj-incastrat-in-invelitoare/bsd-pro"
                      component={BSDPro}
                    />
                    <Route
                      exact
                      path="/produse/panouri-solare-fotovoltaice/panouri-solare/montaj-pe-invelitoare/ark-pro"
                      component={ARKPro}
                    />
                    <Route
                      exact
                      path="/produse/panouri-solare-fotovoltaice/panouri-fotovoltaice/montaj-incastrat-in-invelitoare/pv-indax"
                      component={Indax}
                    />
                    <Route
                      exact
                      path="/produse/panouri-solare-fotovoltaice/panouri-fotovoltaice/montaj-pe-invelitoare/pv-indax-pe-invelitoare"
                      component={IndaxPeInvelitoare}
                    />

                    <Route
                      exact
                      path="/produse/cosuri-de-fum"
                      component={CosuriDeFum}
                    />
                    <Route
                      exact
                      path="/produse/cosuri-de-fum/ceramic"
                      component={CosuriDeFumCeramic}
                    />
                    <Route
                      exact
                      path="/produse/cosuri-de-fum/inox"
                      component={CosuriDeFumInox}
                    />

                    <Route
                      exact
                      path="/produse/trape-de-fum"
                      component={TrapeDeFum}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-danfoss"
                      component={SistemeDanfoss}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-danfoss/incalzire-in-pardoseala"
                      component={IncalzirePardoseala}
                    />
                    <Route
                      exact
                      path="/produse/sisteme-danfoss/sisteme-degivrare"
                      component={SistemeDegivrare}
                    />
                    <Route exact path="/servicii" component={Services} />
                    <Route exact path="/portofoliu" component={Portfolio} />
                    <Route
                      exact
                      path="/portofoliu/proiecte-noi"
                      component={LucrariNoi}
                    />
                    <Route
                      exact
                      path="/portofoliu/ferestre-luminatoare-trape-de-fum"
                      component={FerestreLuminatoare}
                    />
                    <Route
                      exact
                      path="/portofoliu/incalzire-in-pardoseala-degivrari"
                      component={IncalzireDegivrari}
                    />
                    <Route
                      exact
                      path="/portofoliu/renovari"
                      component={Renovari}
                    />
                    <Route exact path="/contact" component={Contact} />
                  </div>
                </div>
              </div>
            </React.Fragment>
            <Footer />
          </div>
        )}
      </Router>
    );
  }
}
export default App;
