import React, { Component } from "react";
import "../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { cardThumbs } from "../../Homepage/Main";
import { Link } from "react-router-dom";
class FerestreVelux extends Component {
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Ferestre VELUX" />
        <br />
        <div className="text-description">
          <strong>VELUX România</strong> este o companie de vânzări care
          comercializează ferestre de mansardă, produse de montaj, rulouri
          decorative și parasolare, tuneluri solare, atât în România cât și în
          Republica Moldova. <strong>VELUX România</strong> face parte din
          Grupului internaţional VELUX care deține companii de vânzări şi
          producţie în peste 40 de ţări în care lucrează 10.000 de angajaţi din
          întreaga lume. Compania <strong>VELUX România</strong> a fost
          înregistrată în 1997.
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <h2>Ferestre de mansardă</h2>
            <hr />
            <Link to="/produse/ferestre-velux/mansarda" className="card-link">
              <Card
                link={cardThumbs.ferestreMansardaVelux}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Ferestre de mansardă"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <h2>Ferestre pentru acoperiș terasă</h2> <hr />{" "}
            <Link to="/produse/ferestre-velux/terasa" className="card-link">
              {" "}
              <Card
                link={cardThumbs.ferestreAcoperisTerasa}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Ferestre pentru acoperiș terasă
              "
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <h2>Rulouri Ferestre VELUX</h2>
            <hr />{" "}
            <Link
              to="/produse/ferestre-velux/rulouri-decorative"
              className="card-link"
            >
              {" "}
              <Card
                link={cardThumbs.rulouriDecorative}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Rulouri Ferestre VELUX"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <h2>Tuneluri solare</h2>
            <hr />{" "}
            <Link
              to="/produse/ferestre-velux/tuneluri-solare"
              className="card-link"
            >
              {" "}
              <Card
                link={cardThumbs.tuneluriSolare}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Tuneluri solare"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col">
            <h2>Dispozitive de comandă și control</h2>
            <hr />
            <Link
              to="/produse/ferestre-velux/dispozitive-comanda"
              className="card-link"
            >
              <Card
                link={cardThumbs.dispozitiveComanda}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Dispozitive de comandă și control"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <div className="empty-card"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreVelux;
