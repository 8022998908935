import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Nelskamp.css";
const images = {
  ROSU_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F13/Rosu-Natural-Engobat-156-1.jpg" alt="..." />,
    text: "ROȘU NATURAL ENGOBAT",
    className: "text-rosu-engobat"
  },

  NEGRU_INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F13/Negru-Invechit-Engobat-141-1.jpg" alt="..." />,
    text: "NEGRU ÎNVECHIT ENGOBAT",
    className: "text-negru-invechit-engobat"
  },

  MARO_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F13/Maro-Engobat-118.jpg" alt="..." />,
    text: "MARO ENGOBAT",
    className: "text-maro-engobat"
  }
};

class NelskampF13 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATURAL.link,
      text: images.ROSU_NATURAL.text,
      className: images.ROSU_NATURAL.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
   
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="F13" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla ceramică profilată F13 Classic</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                <div>
                  Modelul F13 Classic este varianta mai mare a țiglei ceramice clasice profilate, produsă în majoritatea
                  țărilor europene.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATURAL)} className="dot rosu-natural"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_INVECHIT_ENGOBAT)}
                    className="dot negru-invechit-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO_ENGOBAT)} className="dot maro-engobat"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NelskampF13;
