import React from "react";
export const creatonAll = {
  balanceRosuNatur: (
    <img
      src="/assets/images/tigla_ceramica_creaton/balance/balance_rosu_natur.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  biberRosu: (
    <img src="/assets/images/tigla_ceramica_creaton/biber/biber_rosu.jpg" className="card-img-top" alt="..." />
  ),
  dominoRosuNatur: (
    <img src="/assets/images/tigla_ceramica_creaton/domino/domino_rosu_natur.jpg" className="card-img-top" alt="..." />
  ),
  premionRosuNatur: (
    <img
      src="/assets/images/tigla_ceramica_creaton/premion/premion_rosu_natur.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  futuraRosuNatur: (
    <img src="/assets/images/tigla_ceramica_creaton/futura/futura_rosu_natur.jpg" className="card-img-top" alt="..." />
  ),
  rapdioRosuNatur: (
    <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_rosu_natur.jpg" className="card-img-top" alt="..." />
  ),
  titaniaRosuCupru: (
    <img
      src="/assets/images/tigla_ceramica_creaton/titania/titania_rosu_cupru_angoba.jpg"
      className="card-img-top"
      alt="..."
    />
  )
};
