import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
class FerestreTerasaSticlaCurbata extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Sticlă plată" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Fereastra VELUX cu protecție din sticlă plată</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  Fereastra VELUX pentru acoperiș terasă cu protecție din sticlă plată se evidențiază ca și soluție
                  stilizată, deși discretă pentru acoperișul cu pantă între 5-15. Suprafața geamului este tratată
                  special pentru curățare ușoară. Alege între varianta cu motor electric sau cea cu cercevea fixă.
                </div>
                <hr />
                <li>Sticlă exterioară formată dintr-o foaie de geam rezistent la zgârieturi, cu o grosime de 4 mm</li>
                <li>Protecție fonică ridicată împotriva furtunii, grindinii sau altor factori externi</li>
                <li>
                  Cadrul pirametral al protecției este din PVC și integrează un panou de geam termoizolant din 2 foi de
                  sticlă
                </li>
              </div>
            </div>
            <div className="col min-width">
              <div className="row">
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.CVP_PLATA}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră electrică CVP pentru acoperiş terasă"
                    list={[]}
                  />
                </div>
                <div className="col ">
                  <Card
                    link={ferestreVeluxAll.CFP_PLATA}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră fixă CFP pentru acoperiş terasă"
                    list={[]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreTerasaSticlaCurbata;
