import React, { Component } from "react";
import "../../Products.css";
import "./RulouriDecorative.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";

import { Link } from "react-router-dom";
import {
  rulouriDecorativeControl,
  rulouriDecorativeControlLumina,
  rulouriFiltrare,
  plasaInsecte
} from "../../../misc/rulouriDecorative.js";

class RulouriDecorative extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Rulouri și parasolare" className="no-padding" />
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width-rulouri-decorative">
            <Link to="/produse/ferestre-velux/rulouri-decorative/control-caldura" className="card-link">
              <Card
                link={rulouriDecorativeControl.ROLETA_SML_ELECTRICA_2}
                expandedArray={[false, false, false, false, false]}
                cardTitle="CONTROL AL CĂLDURII"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col  min-width-rulouri-decorative">
            <Link to="/produse/ferestre-velux/rulouri-decorative/control-lumina" className="card-link">
              <Card
                link={rulouriDecorativeControlLumina.RULOU_OPAC_DKL_MANUAL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="CONTROL AL LUMINII"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
        <div className="row justify-content-md-center">
          <div className="col  min-width-rulouri-decorative">
            <Link to="/produse/ferestre-velux/rulouri-decorative/filtrare-lumina" className="card-link">
              {" "}
              <Card
                link={rulouriFiltrare.RFL_MANUAL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="FILTRARE A LUMINII"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col min-width-rulouri-decorative">
            <Link to="/produse/ferestre-velux/rulouri-decorative/protectie-insecte/plasa-zil" className="card-link">
              <Card
                link={plasaInsecte.ZIL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="PROTECȚIE ÎMPOTRIVA INSECTELOR"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default RulouriDecorative;
