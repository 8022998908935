import React, { Component } from "react";
import "./Creaton.css";
import Tag from "../../../misc/Tag";
const images = {
  ROSU_NATUR: {
    link: (
      <img src="/assets/images/tigla_ceramica_creaton/domino/domino_rosu_natur.jpg" className="card-img-top" alt="..." />
    ),
    text: "ROȘU NATUR",
    className: "text-rosu-natur"
  },
  GRI: {
    link: <img src="/assets/images/tigla_ceramica_creaton/domino/domino_gri.jpg" className="card-img-top" alt="..." />,
    text: "GRI ANGOBA",
    className: "text-gri-angoba"
  },

  NEGRU: {
    link: <img src="/assets/images/tigla_ceramica_creaton/domino/domino_negru.jpg" className="card-img-top" alt="..." />,
    text: "NEGRU MAT ANGOBA",
    className: "text-negru-mat-angoba"
  },
  ROSU_CUPRU: {
    link: (
      <img src="/assets/images/tigla_ceramica_creaton/domino/domino_rosu_cupru.jpg" className="card-img-top" alt="..." />
    ),
    text: "ROȘU CUPRU ANGOBA",
    className: "text-rosu-angoba"
  },
  ROSU_VIN_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/domino/domino_rosu_vin.jpg" alt="..." />,
    text: "ROȘU VIN ANGOBA",
    className: "text-rosu-vin-angoba"
  },

  ADREZIE_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/domino/domino_ardezie.jpg" className="card-img-top" alt="..." />,
    text: "ARDEZIE ANGOBA",
    className: "text-ardezie-angoba"
  },
  ADREZIE_SMALTUIT: {
    link: (
      <img
        src="/assets/images/tigla_ceramica_creaton/domino/domino_ardezie_smaltuit.jpg"
        className="card-img-top"
        alt="..."
      />
    ),
    text: "ARDEZIE SMĂLȚUIT",
    className: "text-ardezie-smaltuit"
  }
};
class CreatonDomino extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATUR.link,
      text: images.ROSU_NATUR.text,
      className: images.ROSU_NATUR.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {

    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Domino" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla netedă cu linii.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                {" "}
                <div>Spațiu mare de deplasare de 12 mm per țiglă</div>
                <div>
                  Materiile prime de primă clasă permit o omogenitate sporită, comportament scăzut de contracție, iar
                  conținutul ridicat de oxid de fier oferă culori naturale cu strălucire intensă
                </div>
                <div>Pronunțare deosebit de puternică a adâncimii în cazul fălțuirii verticale</div>
                <div>
                  Conform ZVDH (Asociația centrală germană a montatorilor de acoperișuri), poate fi montată în cazul
                  clădirilor subordonate, în acoperire îmbinată la o înclinare a acoperișului începând cu 25° fără
                  măsuri suplimentare
                </div>
                <div>
                  DOMINO este fălțuit o dată pe verticală. Și încă cum! Pronunțarea deosebit de puternică a adâncimii
                  oferă o evacuare optimă a apei și în caz de rafale masive de vânt. Suprapunerea în trepte protejează
                  în mod fiabil împotriva pătrunderii apei și murdăriei
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATUR)} className="dot rosu-natur"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_CUPRU)} className="dot rosu-cupru"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_VIN_ANGOBA)} className="dot rosu-vin"></span>
                  <span onClick={e => this.handleOnClick(images.GRI)} className="dot gri"></span>
                  <span onClick={e => this.handleOnClick(images.ADREZIE_ANGOBA)} className="dot ardezie"></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU)} className="dot negru"></span>
                  <span onClick={e => this.handleOnClick(images.ADREZIE_SMALTUIT)} className="dot ardezie-smaltuit"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatonDomino;
