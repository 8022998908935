import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import $ from "jquery";
import Card from "../../../misc/Card";
import { wetterbestAll } from "../../../misc/wetterbest";
const texts = {
  RAL3009: {
    text: "RAL 3009",
  },
  RAL7024: {
    text: "RAL 7024",
  },
  RAL8017: {
    text: "RAL 8017",
  },
  RAL8019: {
    text: "RAL 8019",
  },

  RAL1015: {
    text: "RAL 1015",
  },
  RAL3000: {
    text: "RAL 3000",
  },
  RAL3005: {
    text: "RAL 3005",
  },
  RAL3009: {
    text: "RAL 3009",
  },
  RAL3011: {
    text: "RAL 3011",
  },
  RAL5010: {
    text: "RAL 5010",
  },
  RAL6005: {
    text: "RAL 6005",
  },
  RAL6011: {
    text: "RAL 6011",
  },
  RAL6020: {
    text: "RAL 6020",
  },
  RAL7016: {
    text: "RAL 7016",
  },

  RAL8004: {
    text: "RAL 8004",
  },
  RAL9002: {
    text: "RAL 9002",
  },
  RAL9006: {
    text: "RAL 9006",
  },
  RAL9007: {
    text: "RAL 9007",
  },
  RAL9005: {
    text: "RAL 9005",
  },
};
class WetterbestGladiator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textNeomat: "",
      textLucios: "",
      textMat: "",
      textSuprem: "",
    };
  }
  updateDimensions = () => {
    $("#col1").css("maxWidth", $("#col2").outerWidth() - 20 + "px");
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }

    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#col1").css("maxWidth", $("#col2").outerWidth() - 20 + "px");
    window.addEventListener("resize", this.updateDimensions);
  }

  handleOnHoverNeomat = (e) => {
    this.setState({
      textNeomat: e.text,
    });
  };

  handleOnMouseOutNeomat = () => {
    this.setState({
      textNeomat: "",
    });
  };
  handleOnHoverLucios = (e) => {
    this.setState({
      textLucios: e.text,
    });
  };

  handleOnMouseOutLucios = () => {
    this.setState({
      textLucios: "",
    });
  };
  handleOnHoverMat = (e) => {
    this.setState({
      textMat: e.text,
    });
  };

  handleOnMouseOutMat = () => {
    this.setState({
      textMat: "",
    });
  };
  handleOnHoverSuprem = (e) => {
    this.setState({
      textSuprem: e.text,
    });
  };

  handleOnMouseOutSuprem = () => {
    this.setState({
      textSuprem: "",
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="Gladiator" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h1>Wetterbest Gladiator</h1>
            <hr />
          </div>
          <div className="row">
            <div className="col braas-col" id="col2">
              <div className="text-description">
                <div>
                  Conceptul profilului Gladiator îmbină robustețea mecanică a
                  sistemelor de învelitori Wetterbest® cu eleganța
                  acoperișurilor clasice mediteraneene aducând în prim plan
                  profilul olanelor de tip antic roman.
                </div>
                <div>
                  <table class="table table-striped">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <th scope="row">Materie primă</th>
                        <td>Oţel galvanizat la cald prevopsit</td>
                      </tr>
                      <tr>
                        <th scope="row">Grosime</th>
                        <td>până la 0,6mm </td>
                      </tr>
                      <tr>
                        <th scope="row">Strat protecție Zinc</th>
                        <td>225-275 g/m²</td>
                      </tr>
                      <tr>
                        <th scope="row">Strat protecție vopsea</th>
                        <td>25 μm/35 μm/50 μm/60 μm</td>
                      </tr>
                      <tr>
                        <th scope="row">Lungimi panouri</th>
                        <td>475-6000mm</td>
                      </tr>
                      <tr>
                        <th scope="row">Pantă minimă la montaj</th>
                        <td>14⁰ (1/4)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col">
              <img
                src="/assets/images/tigla_metalica_wetterbest/gladiator.jpg"
                alt="skane"
                id="col1"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="text-description">
                <h3>Descriere</h3>
                <hr />
                <div>
                  Cu o geometrie fermă și echilibrată dimensional, țigla
                  metalică Wetterbest® Gladiator se remarcă prin siguranța
                  sporită impotriva acțiunii factorilor de mediu asupra
                  acoperișului.
                </div>
                <br />
                <div>
                  Panourile de țiglă metalică Wetterbest Gladiator se montează
                  în sistem clasic, de la dreapta la stânga și de sus în jos,
                  fiind prevăzute în zona de suprapunere longitudinală cu două
                  canale capilare pentru drenare.
                </div>
                <br />
                <h4>Domeniul de utilizare:</h4>

                <div>
                  Ideal pentru învelirea caselor de vacanță, a proiectelor din
                  domeniul turismului etc.
                </div>
                <br />
                <h3>Beneficii țiglă metalică Wetterbest Gladiator</h3>
                <ul>
                  <li>Pierderi semnificativ reduse de material</li>
                  <li>Robust și elegant</li>
                </ul>
              </div>
            </div>{" "}
            <div className="col">
              <div className="text-description">
                <h3>Secțiunea transversală</h3>
                <table class="table table-striped">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th scope="row">Lăţime totală panou ţiglă</th>
                      <td>1200 mm</td>
                    </tr>
                    <tr>
                      <th scope="row">Lăţime utilă panou ţiglă</th>
                      <td>1120 mm</td>
                    </tr>
                    <tr>
                      <th scope="row">Înălţime ondulă ţiglă</th>
                      <td>30 mm</td>
                    </tr>
                  </tbody>
                </table>
                <h3>Secțiunea longitudinală</h3>
                <table class="table table-striped">
                  <tbody>
                    {" "}
                    <tr>
                      <th scope="row">Distanţa la şipcuire</th>
                      <td>350mm</td>
                    </tr>
                    <tr>
                      <th scope="row">Înălţime treaptă între paşi</th>
                      <td>20mm</td>
                    </tr>
                    <tr>
                      <th scope="row"> Lungime pas ţiglă</th>
                      <td>350mm</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h4>{"NEOMAT 30 " + this.state.textNeomat}</h4>

              <div className="row">
                <span className="wetterbest-culori">
                  <span
                    className="wetterbest-square ral3009"
                    onMouseOver={(e) => this.handleOnHoverNeomat(texts.RAL3009)}
                    onMouseOut={(e) => this.handleOnMouseOutNeomat()}
                  ></span>
                  <span
                    className="wetterbest-square ral7024"
                    onMouseOver={(e) => this.handleOnHoverNeomat(texts.RAL7024)}
                    onMouseOut={(e) => this.handleOnMouseOutNeomat()}
                  ></span>
                  <span
                    className="wetterbest-square ral8017"
                    onMouseOver={(e) => this.handleOnHoverNeomat(texts.RAL8017)}
                    onMouseOut={(e) => this.handleOnMouseOutNeomat()}
                  ></span>
                  <span
                    className="wetterbest-square ral8019"
                    onMouseOver={(e) => this.handleOnHoverNeomat(texts.RAL8019)}
                    onMouseOut={(e) => this.handleOnMouseOutNeomat()}
                  ></span>
                  <span
                    className="wetterbest-square ral9005"
                    onMouseOver={(e) => this.handleOnHoverNeomat(texts.RAL9005)}
                    onMouseOut={(e) => this.handleOnMouseOutNeomat()}
                  ></span>
                </span>
              </div>
            </div>
            <div className="col">
              <h4>{"Lucios " + this.state.textLucios}</h4>

              <div className="row">
                <span className="wetterbest-culori">
                  <span
                    className="wetterbest-square ral1015"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL1015)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral3000"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL3000)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral3005"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL3005)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral3009"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL3009)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral3011"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL3011)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral5010"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL5010)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral6005"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL6005)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral6011"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL6011)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral7024"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL7024)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral8004"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL8004)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral8017"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL8017)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral9002"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL9002)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral9006"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL9006)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                  <span
                    className="wetterbest-square ral9007"
                    onMouseOver={(e) => this.handleOnHoverLucios(texts.RAL9007)}
                    onMouseOut={(e) => this.handleOnMouseOutLucios()}
                  ></span>
                </span>
              </div>
            </div>
            <div className="col" id="previous">
              <h4>{"Mat " + this.state.textMat}</h4>
              <div className="row">
                <span className="wetterbest-culori">
                  <span
                    className="wetterbest-square ral3005"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL3005)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral3009"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL3009)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral3011"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL3011)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral6005"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL6005)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral6020"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL6020)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral7016"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL7016)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral7024"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL7024)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral8004"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL8004)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral8017"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL8017)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral8019"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL8019)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                  <span
                    className="wetterbest-square ral9005"
                    onMouseOver={(e) => this.handleOnHoverMat(texts.RAL9005)}
                    onMouseOut={(e) => this.handleOnMouseOutMat()}
                  ></span>
                </span>
              </div>
            </div>
            <div className="col" id="last">
              <h4>{"SUPREM 50 " + this.state.textSuprem}</h4>
              <div className="row">
                <span className="wetterbest-culori">
                  <span
                    className="wetterbest-square ral7024"
                    onMouseOver={(e) => this.handleOnHoverSuprem(texts.RAL7024)}
                    onMouseOut={(e) => this.handleOnMouseOutSuprem()}
                  ></span>
                  <span
                    className="wetterbest-square ral8017"
                    onMouseOver={(e) => this.handleOnHoverSuprem(texts.RAL8017)}
                    onMouseOut={(e) => this.handleOnMouseOutSuprem()}
                  ></span>
                  <span
                    className="wetterbest-square ral8019"
                    onMouseOver={(e) => this.handleOnHoverSuprem(texts.RAL8019)}
                    onMouseOut={(e) => this.handleOnMouseOutSuprem()}
                  ></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WetterbestGladiator;
