import React from "react";
export const rulouriDecorativeControl = {
  ROLETA_SSS: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSS/SSS.png"
      className="card-img-top"
      alt="..."
    />
  ),
  ROLETA_SML_ELECTRICA_1: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SML_electric/SML_electric1.png"
      className="card-img-top"
      alt="..."
    />
  ),
  ROLETA_SML_ELECTRICA_2: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SML_electric/SML_electric2.png"
      className="card-img-top"
      alt="..."
    />
  ),
  ROLETA_SSL_SOLARA_1: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSL/SSL_solar1.png"
      className="card-img-top"
      alt="..."
    />
  ),
  ROLETA_SSL_SOLARA_2: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/roleta_exterioara/SSL/SSL_solar2.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RULOU_MHL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/rulou_exterior_parasolar/MHL_manual/mhl_manual.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RULOU_MML_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/rulou_exterior_parasolar/MML_electric/mml_electric.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RULOU_MSL_SOLAR: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_caldura/rulou_exterior_parasolar/MSL_solar/msl_solar.png"
      className="card-img-top"
      alt="..."
    />
  ),
};

export const rulouriDecorativeControlLumina = {
  RULOU_OPAC_DKL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/DKL_manual/dkl_manual.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RULOU_OPAC_DML_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/DML_electric/dml_electric.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RULOU_OPAC_DSL_SOLAR: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/DSL_solar/dsl_solar.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RULOU_DUO_DFD_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/DFD_manual/dfd_manual.png"
      className="card-img-top"
      alt="..."
    />
  ),
};

export const rulouriFiltrare = {
  RFL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rfl_manual.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RHL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rhl_manual.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RML_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rml_electric.png"
      className="card-img-top"
      alt="..."
    />
  ),
  RSL_SOLAR: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rsl_solar.png"
      className="card-img-top"
      alt="..."
    />
  ),
  JALUZEA_ROMANA: (
    <img
      src="/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/jaluzea.png"
      className="card-img-top"
      alt="..."
    />
  ),
};

export const plasaInsecte = {
  ZIL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/protectie_insecte/zil_manual.png"
      className="card-img-top"
      alt="..."
    />
  ),
};
