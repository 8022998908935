import React, { Component } from "react";
import "./../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
const images = {
  ZIL_MANUAL: (
    <img
      src="/assets/images/ferestre_velux/rulouri/protectie_insecte/zil_manual.png"
      alt="..."
      className="rulouri-imagine"
    />
  )
};

class ProtectieInsecte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuala: images.ZIL_MANUAL
    };
  }

  handleOnMouseOver = e => {
    this.setState({
      text: e
    });
  };

  handleOnMouseOut = e => {
    this.setState({
      text: ""
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Plasa ZIL" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Plasa ZIL</h1>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              {" "}
              <div className="text-description">
                Cu plasa VELUX împotriva insectelor te poți bucura de priveliște și de aer proaspăt - fără să fi
                deranjat de insecte. Plasa se potrivește perfect ferestrei tale de mansardă VELUX, este transparentă,
                iar proveliștea către exterior nu va fi obstrucționată. Plasa rulează pe șine glisante din aluminiu,
                fiind foarte ușor de operat, iar când nu este folosită se rulează integral în caseta discretă din partea
                de sus.
              </div>
              <div className="text-description">Plasa este de culoare neagră.</div>
            </div>
            <div className="col">
              {" "}
              <div className="row justify-content-md-center">
                <div className="col min-width-rulouri">
                  <Card
                    link={this.state.manuala}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="ZIL manual"
                    list={[]}
                    bodyClassName="rulouri-card"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProtectieInsecte;
