import React, { Component } from "react";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { panouriSolare } from "../../misc/panouriSolare";
class Indax extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="PV INDAX" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Modul PV InDaX</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  <strong>Sistemul fotovoltaic Bramac lnDaX®</strong> este conceput pentru o integrare perfectă a
                  modulelor fotovoltaice cristaline de calitate superioară, pe toate tipurile de învelitori. Sistemul
                  preia funcţia de protecţie a învelitorii, are un aspect deosebit şi în plus produce energie electrică.
                </div>
                <div>
                  <strong>Sistemul InDaX</strong> îndeplineşte toate cerinţele necesare pentru un sistem fotovoltaic
                  încastrat în acoperiş, şi anume protecţia anti-incendiu, impermeabilitatea la apă şi subventilaţia.
                  Acest sistem şi-a dovedit calităţile în materie de protecţie şi siguranţă, fiind supus celor mai
                  riguroase teste de impermeabilitate în tunelul aerodinamic. Sistemul fotovoltaic încorporat în
                  acoperiş se pretează în egală măsură atât pentru construcţii noi cât şi cladiri reabilitate, ieşind în
                  evidenţă printr-un montaj simplu.
                </div>

                <br />
                <li>Garanție BMI: 10 ani</li>
                <li>Putere nominală: 305 W</li>
                <li>Număr bucăți per palet: 30</li>
              </div>
            </div>
            <div className="col min-width-rulouri">
              <Card
                link={panouriSolare.INDAX}
                expandedArray={[false, false, false, false, false]}
                cardTitle="PV INDAX"
                list={[]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Indax;
