import React, { Component } from "react";
import "./Creaton.css";
import Tag from "../../../misc/Tag";
const images = {
  NEGRU_MAT_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_negru_mat_angoba.jpg" alt="..." />,
    text: "NEGRU MAT ANGOBA",
    className: "text-negru-mat-angoba"
  },
  CUPRU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_rosu_cupru_angoba.jpg" alt="..." />,
    text: "CUPRU ANGOBA",
    className: "text-cupru-angoba"
  },
  ARDEZIE: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_ardezie_angoba.jpg" alt="..." />,
    text: "ARDEZIE",
    className: "text-ardezie"
  },
  ROSU_NATUR: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_rosu_natur.jpg" alt="..." />,
    text: "ROȘU NATUR",
    className: "text-rosu-natur"
  },
  ROSU_VIN_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_rosu_vin_angoba.jpg" alt="..." />,
    text: "ROȘU VIN ANGOBA",
    className: "text-rosu-vin-angoba"
  },
  ROSU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_rosu_angoba.jpg" alt="..." />,
    text: "ROȘU ANGOBA",
    className: "text-rosu-angoba"
  },
  GRI_INCHIS: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_gri_inchis_angoba.jpg" alt="..." />,
    text: "GRI ÎNCHIS ANGOBA",
    className: "text-gri-inchis-angoba"
  },
  NEGRU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/rapido/rapido_negru_angoba.jpg" alt="..." />,
    text: "NEGRU ANGOBA",
    className: "text-negru-angoba"
  }
};
class CreatonRapido extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATUR.link,
      text: images.ROSU_NATUR.text,
      className: images.ROSU_NATUR.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
 
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Rapido" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla fălțuită flexibilă în megaformat.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                <div>Model tradițional marcant în format XXL</div>
                <div>Flexibilitate sporită și montare simplificată datorită spațiului mare de deplasare de 44 mm</div>

                <div>Raport preț-performanță de top cu circa 8,1 bucăți/m²</div>

                <div>
                  Nervurile de susținere asigură rezistență statică îmbunătățită și evacuarea oricărei forme de apă de
                  condens
                </div>

                <div>
                  Tehnica de labirint” cu cinci nervuri laterale special formate și deosebit de pronunțate împiedică
                  pătrunderea apei pluviale chiar și în caz de rafale puternice vânt.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATUR)} className="dot rosu-natur"></span>
                  <span
                    name="rosu-angoba"
                    onClick={e => this.handleOnClick(images.ROSU_ANGOBA)}
                    className="dot rosu-angoba"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.CUPRU_ANGOBA)} className="dot cupru-angoba"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_VIN_ANGOBA)} className="dot rosu-vin-angoba"></span>
                  <span onClick={e => this.handleOnClick(images.GRI_INCHIS)} className="dot gri-inchis-angoba"></span>
                  <span onClick={e => this.handleOnClick(images.ARDEZIE)} className="dot ardezie"></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU_ANGOBA)} className="dot negru-angoba"></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU_MAT_ANGOBA)} className="dot negru-mat-angoba"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatonRapido;
