import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Bramac.css";
const images = {
  ROSU_CARAMIZIU: {
    link: (
      <img
        src="/assets/images/tigla_bramac/alpina_clasic/alpina_clasic_rosu_caramiziu.jpg"
        className="card-img-top"
        alt="..."
      />
    ),
    text: "ROȘU CĂRĂMIZIU",
    className: "text-rosu-caramiziu"
  },
  BRUN_ROSCAT_INCHIS: {
    link: (
      <img
        src="/assets/images/tigla_bramac/alpina_clasic/alpina_clasic_brun_roscat_inchis.jpg"
        className="card-img-top"
        alt="..."
      />
    ),
    text: "BRUN ROȘCAT ÎNCHIS",
    className: "text-brun-roscat-inchis"
  },
  ANTRACIT_BRILIANT: {
    link: (
      <img
        src="/assets/images/tigla_bramac/alpina_clasic/alpina_clasic_antracit_briliant.jpg"
        className="card-img-top"
        alt="..."
      />
    ),
    text: "ANTRACIT BRILIANT",
    className: "text-antracit-briliant"
  },
  MARO: {
    link: <img src="/assets/images/tigla_bramac/alpina_clasic/alpina_clasic_maro.jpg" className="card-img-top" alt="..." />,
    text: "MARO",
    className: "text-maro"
  }
};
class BramacAlpinaClasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_CARAMIZIU.link,
      text: images.ROSU_CARAMIZIU.text,
      className: images.ROSU_CARAMIZIU.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {

    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="alpina clasic" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Simplitate şi rafinament într-o singură formă.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
            <div className="text-description">
              <div>
                Profil de o frumuseţe clasică se adaptează oricărui tip de arhitectură, cu siguranţă şi în regiunea
                dumneavoastră. Gama variată de culori este însoţită de întregul sistem de accesorii şi de garanţia de 30
                de ani.
              </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_CARAMIZIU)} className="dot rosu-caramiziu"></span>
                  <span
                    onClick={e => this.handleOnClick(images.BRUN_ROSCAT_INCHIS)}
                    className="dot brun-roscat-inchis"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.ANTRACIT_BRILIANT)}
                    className="dot antracit-briliant"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO)} className="dot maro"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BramacAlpinaClasic;
