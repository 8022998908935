import React from "react";
export const ferestreVeluxAll = {
  STANDARD: (
    <img src="/assets/images/ferestre_velux/ferestre_mansarda/standard.jpg" className="card-img-top" alt="..." />
  ),
  STANDARD_PLUS: (
    <img src="/assets/images/ferestre_velux/ferestre_mansarda/standard_plus.jpg" className="card-img-top" alt="..." />
  ),
  PREMIUM: <img src="/assets/images/ferestre_velux/ferestre_mansarda/premium.jpg" className="card-img-top" alt="..." />,
  STICLA_CURBATA: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/sticla_curbata.jpg" className="card-img-top" alt="..." />
  ),
  STICLA_PLATA: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/sticla_plata.jpg" className="card-img-top" alt="..." />
  ),
  CUPOLA_ACRILICA: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/cupola_acrilica.jpg" className="card-img-top" alt="..." />
  ),
  EVACUAREA_FUMULUI: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/evacuarea_fumului.jpg" className="card-img-top" alt="..." />
  ),

  EVACUARE_1: (
    <img
      src="/assets/images/ferestre_velux/ferestre_terasa/evacuare/mansarda_1.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  EVACUARE_2: (
    <img
      src="/assets/images/ferestre_velux/ferestre_terasa/evacuare/mansarda_2.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  RAME_ETANSARE: (
    <img src="/assets/images/ferestre_velux/ferestre_mansarda/rama.jpg" className="card-img-top" alt="..." />
  ),
  CVP: <img src="/assets/images/ferestre_velux/ferestre_terasa/curbata/cvp.png" className="card-img-top" alt="..." />,
  CFP: <img src="/assets/images/ferestre_velux/ferestre_terasa/curbata/cfp.png" className="card-img-top" alt="..." />,
  CVP_PLATA: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/plata/cvp.png" className="card-img-top" alt="..." />
  ),
  CFP_PLATA: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/plata/cfp.png" className="card-img-top" alt="..." />
  ),
  CVP_CUPOLA: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/cupola/cvp.png" className="card-img-top" alt="..." />
  ),
  CFP_CUPOLA: (
    <img src="/assets/images/ferestre_velux/ferestre_terasa/cupola/cfp.png" className="card-img-top" alt="..." />
  ),
  CXP: <img src="/assets/images/ferestre_velux/ferestre_terasa/cupola/cxp.png" className="card-img-top" alt="..." />,
  CSP: <img src="/assets/images/ferestre_velux/ferestre_terasa/evacuare/csp.png" className="card-img-top" alt="..." />,

  GZL_SUS: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard/gzl_sus.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GZL_JOS: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard/gzl_jos.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GLU_SUS: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard/glu_sus.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GLU_JOS: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard/glu_jos.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GLL_SUS: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard_plus/gll_sus.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GLL_JOS: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard_plus/gll_jos.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GLU_SUS_SP: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard_plus/glu_sus.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GLU_JOS_SP: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/standard_plus/glu_jos.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GGL: <img src="/assets/images/ferestre_velux/ferestre_mansarda/premium/ggl.png" className="card-img-top" alt="..." />,
  GGU: <img src="/assets/images/ferestre_velux/ferestre_mansarda/premium/ggu.png" className="card-img-top" alt="..." />,
  GGL_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/premium/ggl_electric.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GPU_INTEGRA_POLIURETAN: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/premium/gpu_integra_poliuretan.jpeg"
      className="card-img-top"
      alt="..."
    />
  ),
  GGL_SOLAR: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/premium/ggl_solar.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GGU_ELECTRIC: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/premium/ggu_electric.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GGU_SOLAR: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/premium/ggu_solar.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GPL_LACUIT: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/premium/gpl_lacuit.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GPU_POLIURETAN: (
    <img
      src="/assets/images/ferestre_velux/ferestre_mansarda/premium/gpu_poliuretan.png"
      className="card-img-top"
      alt="..."
    />
  ),
  GDL: <img src="/assets/images/ferestre_velux/ferestre_mansarda/premium/gdl.png" className="card-img-top" alt="..." />,
  GEL: <img src="/assets/images/ferestre_velux/ferestre_mansarda/premium/gel.png" className="card-img-top" alt="..." />
};
