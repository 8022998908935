import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Nelskamp.css";
const images = {
  NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Natural-115-1.jpg" alt="..." />,
    text: "NATURAL ENGOBAT",
    className: "text-natural"
  },
  ROSU_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Rosu-Natural-Engobat-116.jpg" alt="..." />,
    text: "ROȘU NATURAL ENGOBAT",
    className: "text-rosu-engobat"
  },
  ROSU_RUSTIC: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Rosu-Rustic-Engobat-121.jpg" alt="..." />,
    text: "ROȘU RUSTIC ENGOBAT",
    className: "text-rosu-rustic-engobat"
  },

  ROSU_CUPRU_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Rosu-Cupru-Engobat-142-2.jpg" alt="..." />,
    text: "ROȘU CUPRU ENGOBAT",
    className: "text-rosu-cupru-engobat"
  },

  NEGRU_ARDEZIE_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Negru-Ardezie-Engobat-133.jpg" alt="..." />,
    text: "NEGRU ARDEZIE ENGOBAT",
    className: "text-negru-ardezie-engobat"
  },

  MARO_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Maro-Engobat-118-2.jpg" alt="..." />,
    text: "MARO ENGOBAT",
    className: "text-maro-engobat"
  },
  MARO_INCHIS_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Maro-Inchis-Engobat-134.jpg" alt="..." />,
    text: "MARO ÎNCHIS ENGOBAT",
    className: "text-maro-engobat"
  },
  INVECHIT_RUSTIC_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Invechit-Rustic-Engobat-122.jpg" alt="..." />,
    text: "ÎNVECHIT RUSTIC ENGOBAT",
    className: "text-negru-invechit-engobat"
  },
  INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Invechit-Engobat-119.jpg" alt="..." />,
    text: "ÎNVECHIT ENGOBAT",
    className: "text-invechit-engobat"
  }
};

class NelskampF15 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATURAL.link,
      text: images.ROSU_NATURAL.text,
      className: images.ROSU_NATURAL.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
   
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="F15" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla ceramică profilată F15</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description text-creaton">
                <div>
                  Modelul F15 este varianta puțin mai mare a țiglei ceramice clasice profilate produsă în majoritatea
                  țărilor europene.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.NATURAL)} className="dot natural"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_NATURAL)} className="dot rosu-natural"></span>
                  <span onClick={e => this.handleOnClick(images.ROSU_RUSTIC)} className="dot rosu-rustic-engobat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.ROSU_CUPRU_ENGOBAT)}
                    className="dot rosu-cupru-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO_ENGOBAT)} className="dot maro-engobat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.INVECHIT_RUSTIC_ENGOBAT)}
                    className="dot invechit-rustic"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.INVECHIT_ENGOBAT)} className="dot invechit-engobat"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_ARDEZIE_ENGOBAT)}
                    className="dot negru-ardezie-engobat"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NelskampF15;
