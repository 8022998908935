import React, { Component } from "react";
import "./../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import $ from "jquery";
import "./Danfoss.css";
import { sistemeDanfoss } from "../../misc/sistemeDanfoss";
class SistemeDegivrare extends Component {
  updateDimensions = () => {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#last").css("height", $("#previous").outerHeight() + "px");
    $("#lastCard").css("maxWidth", $("#previousCard").outerWidth() + "px");
    $("#lastCard").css("height", $("#previousCard").outerHeight() + "px");
  };

  componentDidMount() {
    $("#last").css("maxWidth", $("#previous").outerWidth() + "px");
    $("#lastCard").css("maxWidth", $("#previousCard").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Degivrări" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Degivrări</h2>
          </div>
          <hr />
          <br />
          <div className="text-description">
            <div>
              <strong>Sistemul de degivrare</strong> montat in rampa prin cabluri electrice si cu ajutorul termostatelor
              inteligente care pornesc sau opresc sistemul, asigura automat pornirea sa la primii fulgi de zapada si
              previne de la inceput depunerea zapezii sau crearea de polei. Degivrarea poate fi folosita si pentru
              jgheaburi, burlane si insotiri conducte.
            </div>
            <br />
            <h4>Degivrări jgheaburi și burlane</h4>
            <hr />
          </div>
          <div className="degivrare-poza">{sistemeDanfoss.DEGIVRARE1}</div>
          <br />
          <div id="text1" className="text-description">
            {" "}
            <div>
              Sistemele <strong>Danfoss</strong> de protectie contra inghetului impiedica inghetul fluidelor din
              conductele de plastic sau metal. Elimina de asemenea pericolul crearii de formatiuni de gheata in
              interiorul jgheaburilor sau crearii de turturi. Gheata care se topeste si ingheata din nou poate avaria
              acoperisurile si jgheaburile. Turturii grei pot cadea si rani persoanele din preajma. Apa acumulata poate
              sa se scurga prin peretii interiori si prin tencuiala.
            </div>
          </div>
          <div className="text-description">
            <div>
              <strong>Sistemul automatizat Danfoss de topire a zapezii</strong> mentine fluxul de apa in jgheaburi si
              burlane de scurgere, oferind si o cale prin care gheata care se topeste si zapada se pot scurge in
              siguranta de pe acoperis, de-a lungul jgheabului si apoi in jos prin burlan.
            </div>
            <div>
              Cablul încălzitor poate fi asezat in jgheaburi cu spatiu ingust, fara risc de supraincalzire sau de
              ardere. Exista cate un tip de cablu pentru fiecare material de acoperis.
            </div>
            <div>
              Sistemul automatizat economiseste energie, crescand automat cantitatea de caldura in apa cu gheata si
              scazand cantitatea de caldura in aer uscat. Unitatea inteligenta de control porneste cablul de incalzire
              doar cand este necesar: atunci cand detecteaza atat o temperatura scazuta, cat si umezeala.
            </div>
          </div>
          <br />
          <div className="degivrare-poza">{sistemeDanfoss.DEGIVRARE2}</div>
          <br />{" "}
          <div className="text-description">
            <h4>Instalații sub asfalt</h4>
            <hr />
            <div>
              Gheata si zapada de pe alei, zone de incarcare, drumuri de acces, rampe, scari si alte cai de acces pot
              crea probleme majore, provocand accidente si intarzieri. Pentru a preveni aceste riscuri,{" "}
              <strong>Danfoss</strong> ofera o gama completa de solutii pentru degivrarea suprafetelor.
            </div>
          </div>
          <div className="row">
            <div className="col min-width">{sistemeDanfoss.DEGIVRARE3}</div>
            <div className="col min-width">{sistemeDanfoss.DEGIVRARE4}</div>
          </div>
          <br />
          <div className="text-description">
            <div>
              Gama de produse <strong>Danfoss</strong> a fost proiectata special pentru a satisface cerintele
              aplicatiilor comerciale, industriale si rezidentiale. Indiferent daca este vorba de beton, nisip sau
              asfalt, exista un sistem <strong>Danfoss</strong> care ofera o solutie rapida, fiabila si usor de
              instalat.
            </div>
          </div>
          <div className="degivrare-poza">{sistemeDanfoss.DEGIVRARE5}</div>
          <br />
          <div className="text-description">
            Fiecare solutie de incalzire <strong>Danfoss</strong> se livreaza in set complet cu o unitate inteligenta de
            control si monitorizare, oferind utilizatorilor date si performante excelente, avand consum redus de
            energie. Dispozitivul de control si monitorizare multisenzor (ECtemp 850) este compatibil cu toate solutiile
            de topire a zapezii pentru rampe.
          </div>
          <br />
          <div className="row justify-content-md-center">
            <div className="col min-width-rulouri">
              <Card
                link={sistemeDanfoss.ECSAFE}
                expandedArray={[false, false, false, false, false]}
                cardTitle="ECsafe™ 20T - 230V"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="text-description">
                <div>
                  <strong>ECsafe™ 20T - 230V</strong> este un conductor bifilar complet ecranat cu un inveliş exterior
                  robust din PVC de culoare neagră, cu protecţie UV. Materialele de calitate superioară şi construcţia
                  robustă ne permit să vă oferim cu plăcere toate cablurile ECsafe™ cu garanţia unică de20 de ani{" "}
                  <strong>Danfoss</strong> warranty.
                </div>
                <div>
                  Datorită profilului circular, cablul este simplu de instalat intr-o gamă variată de aplicaţii pentru
                  exterior, precum jgheaburile sau aplicaţiile pentru sol.
                </div>
              </div>
            </div>
            <div className="col min-width-rulouri">
              <Card
                link={sistemeDanfoss.DEVISNOW}
                expandedArray={[false, false, false, false, false]}
                cardTitle="DEVIsnow™ 30T - 230V (DTCE-30)"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="text-description">
                <div>
                  <strong>DEVIsnow™ 30T - 230V (DTCE-30)</strong> este un conductor bifilar complet ecranat cu un
                  inveliş exterior robust din PVC de culoare neagră, cu protecţie UV. Materialele de calitate superioară
                  şi construcţia robustă ne permit să vă oferim cu plăcere toate cablurile ECsafe™ cu garanţia unică de
                  20 de ani <strong>Danfoss</strong> warranty.
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="text-description">
            <h4>O instalaţie standard este alcătuită din:</h4>
            <hr />
          </div>
          <div className="row">
            <div className="col min-width">
              {" "}
              <Card
                link={sistemeDanfoss.UNITATE_CONTROL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Unitate de control"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="text-description text-alcatuire">
                Acesta este dispozitivul care, pe baza măsurătorilor efectuate de senzori, stabileşte cand trebuie
                incălzită/te zona/zonele corespunzătoare
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <Card
                link={sistemeDanfoss.SURSA_ENERGIE}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Sursa de energie "
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="text-description text-alcatuire">
                Sursa de energie (una sau mai multe) alimentează unitatea de control şi senzorii conectaţi .
              </div>
            </div>
            <div className="col min-width" id="previousCard">
              {" "}
              <Card
                link={sistemeDanfoss.SENZOR_SOL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Senzor de sol"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="text-description text-alcatuire">
                Cel puţin un senzor este necesar pentru zona de sol, dar pentru o funcţionare optimă a sistemului sunt
                recomandaţi 2 sau mai mulţi senzori{" "}
              </div>
            </div>
            <div className="col min-width" id="lastCard">
              {" "}
              <Card
                link={sistemeDanfoss.SENZOR_ACOPERIS}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Senzor pentru acoperiş"
                list={[]}
                bodyClassName="rulouri-card"
              />
              <br />
              <div className="text-description text-alcatuire">
                Pentru zona acoperişului este necesar cel puţin un senzor, insă pentru acoperişurile complexe sunt
                recomandaţi 2 sau mai mulţi senzori .
              </div>
            </div>
          </div>
          <hr />
          <div className="text-description">
            <div>
              In perioada sezonului rece, persoanele au tendinta de a subestima pericolele reprezentate de formarea
              poleiului sau de depunerile de zapada, asta pana cand se produce un accident, oricat ar fi el de mic. Un
              strat subtire de gheata sau zapada compactata poate deveni un real pericol atat pentru pietonii de toate
              varstele cat si pentru automobile sau alte bunuri. Pentru a preveni eventualele accidente, de multe ori
              dureroase, noi va recomandam, ca o solutie la indemana, <strong>sistemele electrice de degivrare</strong>{" "}
              a zonelor periculoase.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SistemeDegivrare;
