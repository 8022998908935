import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
class FerestreTerasaSticlaCurbata extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Cupola acrilică" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Fereastra VELUX cu cupolă acrilică </h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  Fereastra VELUX cu cupolă acrilică este versiunea modernă a acestui model, potrivită pentru sedii de
                  birouri, clinici, workshop-uri și locuințe. Fereastra este formată dintr-un cadru de PVC și un panou
                  de geam termoizolant format din 2 foi de sticlă. În varianta cu acționare electrică, luminatorul este
                  echipat cu motor electric pentru mai mult confort în casa ta.
                </div>
                <hr />
                <li>Protecție fonică excelentă </li>
                <li>Varianta cu dom opac oferă intimitatea dorită</li>
                <li>Varianta cu dom transparent nu necesită întreținere specială</li>
              </div>
            </div>
            <div className="col min-width">
              <div className="row">
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.CXP}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră CXP pentru acces pe acoperiş terasă"
                    list={[]}
                  />
                </div>
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.CVP_CUPOLA}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră electrică CVP pentru acoperiş terasă"
                    list={[]}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col min-width"></div>
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.CFP_CUPOLA}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră fixă CFP pentru acoperiş terasă"
                    list={[]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreTerasaSticlaCurbata;
