import React from "react";
export const lindabAll = {
  NORDIC: (
    <img
      src="/assets/images/tigla_metalica_lindab/card_tigla_metalica_lindab_nordic400.png"
      className="card-img-top"
      alt="..."
    />
  ),
  SKANE: (
    <img
      src="/assets/images/tigla_metalica_lindab/card_tigla_metalica_lindab_skane.png"
      className="card-img-top"
      alt="..."
    />
  ),
  LTM: (
    <img
      src="/assets/images/tigla_metalica_lindab/card_tigla_metalica_lindab_ltm.png"
      className="card-img-top"
      alt="..."
    />
  ),
  LXD: <img src="/assets/images/tabla_plana_minirulou/lindab/lindab_lxd.png" className="card-img-top" alt="..." />,
  PLX: <img src="/assets/images/tabla_plana_minirulou/lindab/lindab_plx.png" className="card-img-top" alt="..." />,
  FAP: <img src="/assets/images/tabla_plana_minirulou/lindab/lindab_fap.png" className="card-img-top" alt="..." />,
  TOSCANA: (
    <img src="/assets/images/tigla_metalica_roca/lindab/lindab_toscana.jpg" className="card-img-top" alt="..." />
  ),
  MADERA: <img src="/assets/images/tigla_metalica_roca/lindab/lindab_madera.jpg" className="card-img-top" alt="..." />,
  RUSTICA: (
    <img src="/assets/images/tigla_metalica_roca/lindab/lindab_rustica.jpg" className="card-img-top" alt="..." />
  ),
  SERENA: <img src="/assets/images/tigla_metalica_roca/lindab/lindab_serena.jpg" className="card-img-top" alt="..." />
};
