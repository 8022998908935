import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { Link } from "react-router-dom";
import { figoAll } from "../../../misc/figo";
class Figo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Tablă plană minirulou" className="no-padding" />
        <br />
        <h2>Figo</h2>
        <hr />
        <div className="text-description">
          Suntem o companie din Austria cu o experianță de 140 de ani în învelitori și 130 de ani în domeniul
          prelucrării oțelului. Produsele <strong>FIGO</strong> reprezintă rezultatul experienței dobândite în istoria
          de succes a companiei. Împreună cu unul dintre cei mai mari producători de oțel din Europa, voestAlpine, cu
          care avem un parteneriat foarte strâns de decenii, am dezvoltat produse ce se caracterizează prin inovație,
          cunoștințe temeinice și calitate de durată.
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="figo/highline" className="card-link">
              <Card
                link={figoAll.HIGHLINE}
                expandedArray={[false, false, false, false, false]}
                cardTitle="HIGHLINE"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="figo/robust" className="card-link">
              {" "}
              <Card
                link={figoAll.ROBUST}
                expandedArray={[false, false, false, false, false]}
                cardTitle="ROBUST"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Figo;
