import React, { Component } from "react";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { panouriSolare } from "../../misc/panouriSolare";
import "./panouri.css";
class BSDPro extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="BSD PRO" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Bramac Solar BSD PRO</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                <div>
                  <strong>Panoul solar Bramac - BSD PRO</strong>, cu montaj încastrat în acoperiş se potriveşte pe orice
                  tip de învelitoare şi se montează foarte uşor datorită ramei încorporate. Acesta a fost verificat de
                  către Institutul Arsenal de Cercetare din Viena conform normei Europe EN 12975, dovedindu-se a fii
                  soluţia optimă pentru montajul încastrat în acoperiş curamă încorporată. Mai mult de atât panoul solar
                  Bramac BSD PRO, a fost certificat cu standardul European de calitate, "Solar Keymark”.
                </div>

                <table class="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Tipul panoului</th>
                      <th scope="col">LxÎ</th>
                      <th scope="col">Suprafaţă</th>
                      <th scope="col">Greutate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">BSD10E PRO</th>
                      <td>5403 x 2380 mm</td>
                      <td>
                        10,2 m<sup>2</sup>
                      </td>
                      <td>290 kg</td>
                    </tr>
                    <tr>
                      <th scope="row">BSD8E PRO</th>
                      <td>4352 x 2380 mm</td>
                      <td>
                        8,2 m<sup>2</sup>
                      </td>
                      <td>220 kg</td>
                    </tr>
                    <tr>
                      <th scope="row">BSD6E PRO</th>
                      <td>3303 x 2380 mm</td>
                      <td>
                        6,2 m<sup>2</sup>
                      </td>
                      <td>160 kg</td>
                    </tr>
                    <tr>
                      <th scope="row">BSD4E PRO</th>
                      <td>2402 x 2380 mm</td>
                      <td>
                        4,1 m<sup>2</sup>
                      </td>
                      <td>110 kg</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row">
                  <div className="col min-width">
                    {" "}
                    <div>
                      <strong>Date tehnice:</strong>
                    </div>
                    <li>Geam: Geam securizat solar 3,2 mm, conţinut scăzut de fier, structurat, călit </li>
                    <li>Gradul de transmisie a radiaţiei > 91,5%</li>
                    <li>Şipci geam: 2 părți Aluminiu, eloxat C 35</li>
                    <li>Etanșare: Garnitură cauciuc sintetic, rezistent la temperaturi ridicate</li>
                    <li>Ramă etanșare: Aluminiu vopsit 0.80mm integrate în panou</li>
                    <li>Culoare ramă de etanșare: gri antracit RAL 7016</li>
                    <li>Racorduri: Racorduri flexibile de inox, &empty; 16, 60cm lungime</li>
                    <li>
                      Racord hidraulic debit: High Flow I BSD PRO până la 50L/m<sup>2</sup>h
                    </li>
                    <li>
                      Racord hidraulic debit: Low Flow în serie BSD PRO - 15L/m<sup>2</sup>h
                    </li>
                    <li>
                      Protecţie senzor temperatură: furtun de ghidaj DN 10mm, aprox. 615mm lungime, sondă temperatură
                      PT1000 cu cablu de 1,5m premontat{" "}
                    </li>
                    <li>Presiune: maximă: 10 bar</li>
                    <li>
                      Amplasare: montajul se face pe orice tip de învelitoare tradiţională cu înclinaţii de la 22° la
                      65°
                    </li>
                  </div>
                  <div className="col min-width">
                    {" "}
                    <div>
                      <strong>Panoul solar Bramac corespunde celor mai moderne tehnologii:</strong>
                    </div>
                    <li> sticlă de siguranţă structurată şi cu un conţinut scăzut de fier</li>
                    <li>
                      suprafaţă de absorbţie intens selectivă - izolaţie din vată minerală, rezistentă la fluctuaţii de
                      temperatură
                    </li>
                    <li>şipci pentru fixarea sticlei, din aluminiu, cu garnitură de cauciuc sintetic</li>
                    <li>ramă încorporată în aluminiu - garanţie 10 </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="col min-width-rulouri">
              <Card
                link={panouriSolare.BSD_PRO}
                expandedArray={[false, false, false, false, false]}
                cardTitle="BSD PRO"
                list={[]}
              />
            </div>
          </div>
          <br />
          <h3 className="text-description">Date tehnice</h3>
          <hr />
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Tipul panoului</th>
                <th scope="col">Supraf. de absorbţie</th>
                <th scope="col">Supraf. vitrată </th>
                <th scope="col">Supraf. brută</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">BSD10E PRO</th>
                <td>
                  9,20 m<sup>2</sup>
                </td>
                <td>
                  9,20 m<sup>2</sup>
                </td>
                <td>
                  10,21 m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th scope="row">BSD8E PRO</th>
                <td>
                  7,50 m<sup>2</sup>
                </td>
                <td>
                  7,50 m<sup>2</sup>
                </td>
                <td>
                  8,18 m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th scope="row">BSD6E PRO</th>
                <td>
                  5,50 m<sup>2</sup>
                </td>
                <td>
                  5,50 m<sup>2</sup>
                </td>
                <td>
                  6,16 m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th scope="row">BSD4E PRO</th>
                <td>
                  3,70 m<sup>2</sup>
                </td>
                <td>
                  3,70 m<sup>2</sup>
                </td>
                <td>
                  4,13 m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th scope="col"></th>
                <th scope="col">Dimensiuni fără ramă</th>
                <th scope="col">Dimensiuni cu ramă</th>
              </tr>
              <tr>
                <th scope="row">BSD10E PRO</th>
                <td>5005 x 2039 x 110 mm</td>
                <td>5403 x 2380 x 110 mm</td>
              </tr>
              <tr>
                <th scope="row">BSD8E PRO</th>
                <td>4012 x 2039 x 110 mm</td>
                <td>4352 x 2380 x 110 mm</td>
              </tr>
              <tr>
                <th scope="row">BSD6E PRO</th>
                <td>3019 x 2039 x 110 mm</td>
                <td>3303 x 2380 x 110 mm</td>
              </tr>
              <tr>
                <th scope="row">BSD4E PRO</th>
                <td>22026 x 2039 x 110 mm </td>
                <td>2402 x 2380 x 110 mm</td>
              </tr>
              <tr>
                {" "}
                <th scope="col"></th>
                <th scope="col">Capacitate umplere panou solar (inclusiv ţeavă racord 60 cm)</th>
              </tr>
              <tr>
                <th scope="row">BSD10E PRO</th>
                <td>5,10 L</td>
              </tr>
              <tr>
                <th scope="row">BSD8E PRO</th>
                <td>4,10 L</td>
              </tr>
              <tr>
                <th scope="row">BSD6E PRO</th>
                <td>3,10 L</td>
              </tr>
              <tr>
                <th scope="row">BSD4E PRO</th>
                <td>2,10 L</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description">
                <div>
                  <strong>Material suprafaţă de absorbţie:</strong> aluminiu de 0,5mm, strat de înaltă fidelitate
                  Sunselect, aplicat în vacuum, meandre de cupru DN 8mm, sudură cu laser.
                </div>
                <li>Absorbţie > 95%</li>
                <li>{"Emisie termică < 5%"}</li>
                <li>Termoizolație spate: Vată minerală solară de 50mm</li>
                <li>Ramă panou: Lemn</li>
                <div>
                  <br />
                  <div>
                    <strong>Rezultate tehnice</strong>
                  </div>
                  <li>Factor de conversie &eta;0: 82,4%</li>
                  <li>
                    Coeficient de pierdere a căldurii K<sub>1</sub>: 3,8 W/m<sup>2</sup> K
                  </li>
                  <li>
                    Coeficient pătratic de pierdere a căldurii K<sub>2</sub>: 0,015 W/m<sup>2</sup> K
                  </li>
                  <li>Unghi de radiație factor colector K(50&deg;): 0,92%</li>
                  <li>Temperatura în panou în caz de nefuncționare: 198&deg; C</li>
                </div>
              </div>
            </div>
            <div className="col min-width-rulouri">
              <img src="/assets/images/panouri_solare/schema_bsd.png" class="bsd-imagine" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BSDPro;
