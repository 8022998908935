import React, { Component } from "react";
import "./Main.css";
import Card from "../misc/Card";
import Tag from "../misc/Tag";
import Slider from "../misc/Slider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

export const cardThumbs = {
  tiglaMetalica: (
    <img
      src="/assets/images/products_homepage/card_tigla_metalica.png"
      className="card-img-top"
      alt="..."
    />
  ),
  ferestreVelux: (
    <img
      src="/assets/images/products_homepage/card_ferestre_velux.png"
      className="card-img-top"
      alt="..."
    />
  ),
  panouriSolare: (
    <img
      src="/assets/images/products_homepage/card_panouri_solare.png"
      className="card-img-top"
      alt="..."
    />
  ),
  cosuriDeFum: (
    <img
      src="/assets/images/products_homepage/card_cosuri_de_fum.png"
      className="card-img-top"
      alt="..."
    />
  ),
  trapeDeFum: (
    <img
      src="/assets/images/products_homepage/card_trape_de_fum.png"
      className="card-img-top"
      alt="..."
    />
  ),
  degivrare: (
    <img
      src="/assets/images/products_homepage/card_degivrare.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaCeramicaBrass: (
    <img
      src="/assets/images/tigla_ceramica_beton/card_tigla_ceramica_brass.png"
      className="card-img-top "
      alt="..."
    />
  ),
  tiglaCeramicaCreaton: (
    <img
      src="/assets/images/tigla_ceramica_beton/card_tigla_ceramica_creaton.png"
      className="card-img-top "
      alt="..."
    />
  ),
  tiglaCeramicaNelskamp: (
    <img
      src="/assets/images/tigla_ceramica_beton/card_tigla_ceramica_nelskamp.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaBetonBramac: (
    <img
      src="/assets/images/tigla_ceramica_beton/card_tigla_beton_bramac.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaMetalicaLindab: (
    <img
      src="/assets/images/tigla_metalica_lindab/card_tigla_metalica_lindab.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaMetalicaWetterbest: (
    <img
      src="/assets/images/tigla_metalica_wetterbest/card_tigla_metalica_wetterbest.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaMetalicaNordic400: (
    <img
      src="/assets/images/tigla_metalica_lindab/card_tigla_metalica_lindab_nordic400.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaMetalicaSkane: (
    <img
      src="/assets/images/tigla_metalica_lindab/card_tigla_metalica_lindab_skane.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaMetalicaLtm: (
    <img
      src="/assets/images/tigla_metalica_lindab/card_tigla_metalica_lindab_ltm.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tablaPlanaLindab: (
    <img
      src="/assets/images/tabla_plana_minirulou/card_tabla_plana_minirulou_lindab.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tablaPlanaFigo: (
    <img
      src="/assets/images/tabla_plana_minirulou/card_tabla_plana_minirulou_figo.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaMetalicaRocaLindab: (
    <img
      src="/assets/images/tigla_metalica_roca/card_tigla_metalica_roca_lindab.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tiglaMetalicaRocaBmi: (
    <img
      src="/assets/images/tigla_metalica_roca/card_tigla_metalica_roca_bmi.png"
      className="card-img-top"
      alt="..."
    />
  ),
  jgheaburiLindab: (
    <img
      src="/assets/images/sisteme_jgheaburi/card_jgheaburi_lindab.png"
      className="card-img-top"
      alt="..."
    />
  ),
  jgheaburiStabicor: (
    <img
      src="/assets/images/sisteme_jgheaburi/card_jgheaburi_stabicor.png"
      className="card-img-top"
      alt="..."
    />
  ),
  jgheaburiFigo: (
    <img
      src="/assets/images/sisteme_jgheaburi/card_jgheaburi_figo.png"
      className="card-img-top"
      alt="..."
    />
  ),
  ferestreMansardaVelux: (
    <img
      src="/assets/images/ferestre_velux/card_ferestre_mansarda.png"
      className="card-img-top"
      alt="..."
    />
  ),
  ferestreAcoperisTerasa: (
    <img
      src="/assets/images/ferestre_velux/card_ferestre_acoperis_terasa.png"
      className="card-img-top"
      alt="..."
    />
  ),
  tuneluriSolare: (
    <img
      src="/assets/images/ferestre_velux/card_tuneluri_solare.png"
      className="card-img-top"
      alt="..."
    />
  ),
  rulouriDecorative: (
    <img
      src="/assets/images/ferestre_velux/card_rulouri_decorative.png"
      className="card-img-top"
      alt="..."
    />
  ),
  dispozitiveComanda: (
    <img
      src="/assets/images/ferestre_velux/card_dispozitive_comanda.png"
      className="card-img-top"
      alt="..."
    />
  ),
  panouSolarBsd: (
    <img
      src="/assets/images/panouri_solare/card_panou_solar1.png"
      className="card-img-top"
      alt="..."
    />
  ),
  panouSolarArk: (
    <img
      src="/assets/images/panouri_solare/card_panou_solar2.png"
      className="card-img-top"
      alt="..."
    />
  ),
  panouFotovoltaicInDax: (
    <img
      src="/assets/images/panouri_solare/card_panou_fotovoltaic1.png"
      className="card-img-top"
      alt="..."
    />
  ),
  panouFotovoltaicPeInv: (
    <img
      src="/assets/images/panouri_solare/card_panou_fotovoltaic2.png"
      className="card-img-top"
      alt="..."
    />
  ),
  trapaDeFum: (
    <img
      src="/assets/images/trape_de_fum/card_trapa_de_fum.png"
      className="card-img-top"
      alt="..."
    />
  ),
  luminatoare: (
    <img
      src="/assets/images/trape_de_fum/card_luminatoare.png"
      className="card-img-top"
      alt="..."
    />
  ),
  trapaDeAcces: (
    <img
      src="/assets/images/trape_de_fum/card_trapa_acces.png"
      className="card-img-top"
      alt="..."
    />
  ),
};

class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="homepage">
          <Tag name="Acasă" />
          {/*home */}
          <div className="main-text">
            Societatea <strong>ROOF CONSTRUCT INTERNATIONAL</strong> a luat
            ființă cu scopul de a oferi clienților produse și servicii de
            calitate la nivel de <strong>standard European</strong>. Drept
            urmare, am ales să colaborăm cu producători de renume, care oferă
            produse de calitate, cum ar fi:{" "}
            <strong>
              Creaton, BMI Bramac, BMI AeroDek, BMI Braas, BMI Awak, Schiedel,
              Nelskamp, Lindab, Wetterbest, Figo, VELUX, Danfoss
            </strong>
            . Compania noastră asigură <strong>consiliere profesională</strong>{" "}
            pentru alegerea celei mai bune soluții în funcție de configurația,
            complexitatea și utilitatea <strong>proiectului</strong>{" "}
            dumneavoastră.
          </div>
          <Slider />
          {/*end of home */}
          <Tag name="Produse" />
          {/*products*/}
          <div className="products-section">
            <div className="row">
              <div className="col">
                <Link
                  to={{
                    pathname: "/produse/sisteme-invelitori",
                    state: { scrollUp: true },
                  }}
                  className="card-link"
                >
                  <Card
                    link={cardThumbs.tiglaMetalica}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Sisteme învelitori"
                    list={[]}
                  />
                </Link>
              </div>

              <div className="col">
                <Link
                  to={{
                    pathname: "/produse/ferestre-velux",
                    state: { scrollUp: true },
                  }}
                  className="card-link"
                >
                  <Card
                    link={cardThumbs.ferestreVelux}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Ferestre VELUX"
                    list={[]}
                  />
                </Link>
              </div>
              <div className="col">
                <Link
                  to={{
                    pathname: "/produse/panouri-solare-fotovoltaice",
                    state: { scrollUp: true },
                  }}
                  className="card-link"
                >
                  {" "}
                  <Card
                    link={cardThumbs.panouriSolare}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Panouri solare și fotovoltaice"
                    list={[]}
                  />
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Link
                  to={{
                    pathname: "/produse/cosuri-de-fum",
                    state: { scrollUp: true },
                  }}
                  className="card-link"
                >
                  {" "}
                  <Card
                    link={cardThumbs.cosuriDeFum}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="COȘURI DE FUM SCHIEDEL"
                    list={[]}
                  />
                </Link>
              </div>
              <div className="col">
                <Link
                  to={{
                    pathname: "/produse/trape-de-fum",
                    state: { scrollUp: true },
                  }}
                  className="card-link"
                >
                  <Card
                    link={cardThumbs.trapeDeFum}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="TRAPE DE FUM ȘI LUMINATOARE"
                    list={[]}
                  />
                </Link>
              </div>
              <div className="col">
                <Link
                  to={{
                    pathname: "/produse/sisteme-danfoss",
                    state: { scrollUp: true },
                  }}
                  className="card-link"
                >
                  <Card
                    link={cardThumbs.degivrare}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Sisteme de degivrare Danfoss"
                    list={[]}
                  />
                </Link>
              </div>
            </div>
          </div>
          <br />
          {/*end of products*/}
          <Tag name="Servicii" />
          {/*services */}
          <br />
          Clima din Romania este destul de dura, cu ierni inghetate, veri
          calduroase si vanturi puternice. De aceea este foarte important ca sa
          alegeti materiale de calitate, care sa reziste in timp si de
          profesionisti <Link to="/servicii">... (citeste mai mult)</Link>
          <br />
          <br />
          {/*end of services */}
          <Tag name="Termeni și Condiții" className="no-padding" />
          {/*politicac */}
          <br />
          <div>
            Respectăm şi protejăm confidenţialitatea clienţilor noştri. În cele
            ce urmează, puteţi citi despre felul în care prelucrăm datele dvs.
            personale.
          </div>
          <div>
            Politica de Confidenţialitate Roof Construct International descrie
            gestionarea datelor furnizate sau colectate pe platformele noastre
            digitale, ceea ce oferă vizitatorului posibilitatea de a ne contacta
            şi de a beneficia de serviciile noastre. Politica de
            Confidenţialitate Roof Construct International respectă Regulamentul
            General UE privind Protecţia Datelor cu Caracter Personal 2016/679
            („GDPR”) și legislația locală.
          </div>
          <Link
            to={{ pathname: "/termeni-conditii", state: { scrollUp: true } }}
          >
            (citeste mai mult)
          </Link>
          <br />
          <br />
          {/*end of politica */}
          <Tag name="Contact" />
          {/*contact */}
          <br />
          <h5>Contacteaza-ne!</h5>
          <hr />
          <div> S.C. Roof Construct International S.R.L.</div>{" "}
          <div>CUI: RO36363899</div>
          <div> Reg. com.: J 40/10115/2016</div>
          <div> Str. Valea Oltului nr. 12, Sector 6, Bucuresti</div>
          <div> Telefon: 0723481857</div>
          <div> E-mail: office@roofconstruct.ro </div>
          <br />
          <Link to="/contact" className="btn btn-primary contact-button">
            Formular de contact
          </Link>
          {/*end of contact */}
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
