import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
import { Link } from "react-router-dom";
class FerestreTerasa extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Ferestre VELUX" />
        <br />
        <h2>Ferestre pentru acoperiș terasă</h2>
        <hr />
        <div className="row">
          <div className="col">
            {" "}
            <Link to="terasa/sticla-curbata" className="card-link">
              <Card
                link={ferestreVeluxAll.STICLA_CURBATA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="STICLĂ CURBATĂ"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="terasa/sticla-plata" className="card-link">
              {" "}
              <Card
                link={ferestreVeluxAll.STICLA_PLATA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="STICLA PLATĂ"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="terasa/cupola-acrilica" className="card-link">
              {" "}
              <Card
                link={ferestreVeluxAll.CUPOLA_ACRILICA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="CUPOLĂ ACRILICĂ"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="terasa/evacuarea-fumului" className="card-link">
              {" "}
              <Card
                link={ferestreVeluxAll.EVACUAREA_FUMULUI}
                expandedArray={[false, false, false, false, false]}
                cardTitle="EVACUAREA FUMULUI"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreTerasa;
