import React from "react";
export const dispozitiveComanda = {
  TIJE: (
    <img
      src="/assets/images/ferestre_velux/dispozitive_comanda/tije_adaptoare.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  ACCESORII_ELECTRICE: (
    <img
      src="/assets/images/ferestre_velux/dispozitive_comanda/accesorii_electrice.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  PROTECTIE: (
    <img src="/assets/images/ferestre_velux/dispozitive_comanda/protectie.jpg" className="card-img-top" alt="..." />
  ),
  VELUX_ACTIVE: (
    <img src="/assets/images/ferestre_velux/dispozitive_comanda/velux_active.jpg" className="card-img-top" alt="..." />
  )
};
