import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./BMI.css";
const images = {
  CARAMIZIU: {
    text: "CĂRĂMIZIU",
    className: "caramiziu-742"
  },
  MARO: {
    text: "MARO",
    className: "maro-434"
  },
  ANTRACIT_BRILIANT: {
    text: "ANTRACIT BRILIANT",
    className: "antracit-briliant"
  }
};

class BMIStabicor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleOnHover = e => {
    this.setState({
      text: e.text
    });
  };

  handleOnMouseOut = () => {
    this.setState({
      text: ""
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="BMI Stabicor" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Stabicor M</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              {" "}
              <div className="text-description">
                <div>
                  Sistemul Stabicor M reprezintã suma standardelor calitative şi estetice înalte, completând prin
                  eleganţa sa aspectul oricãrei clãdiri. Se potriveşte la orice model şi culoare de ţiglã, nu necesitã
                  lipire sau sudare, rezistent la coroziune, variaţii de temperaturã şi ultraviolete.
                </div>
                <h4>Avantaje</h4>
                <li>Instalare rapidă, uşoară şi curată.</li>
                <li>Sistemul de îmbinare etanş, cu garnitură de cauciuc.</li>
                <li>Nu este necesară lipirea cu silicon.</li>
                <li>
                  Protecţia împotriva abraziunii şi zgârierii în timpul asamblării, respectiv transport (jgheaburile şi
                  burlanele sunt acoperite pe întreaga lungime cu o folie protectoare).
                </li>
                <li>Stabilitate mare.</li>
                <li>Materiale de înaltă performanţă.</li>
                <li>Durată de viaţă lungă şi fără întreţinere.</li>
                <li>Rezistă la variaţii mari de temperatură.</li>
                <li>Material jgheaburi, burlane şi fitinguri tăiate din oţel zincat, cu acoperire de polyester.</li>
                <li>Capacitate mare de colectare a apei de până la 10 l/ml.</li>
                <li>Garanție 15 ani.</li>
                <hr />
                <div className="row">
                  <div className="col min-width">
                    <h4>Date tehnice</h4>
                    <li>Material: Tablă zincată vopsită în câmp electrostatic</li>
                    <li>Oțel: 0,55 mm strat zinc - 20 μm (275 g/mp)</li>
                    <li>Jgheaburi: Lungime: 4 m; Diametru: 125 mm, 150 mm</li>
                    <li>Burlane: Lungime: 1 m, 3 m; Diametru: 100 mm</li>
                  </div>
                  <div className="col max-width-348 min-width">
                    <div className="culori-text">
                      Culori: <div className="culori-text-culoare">{this.state.text}</div>
                    </div>
                    <br />
                    <img
                      src="/assets/images/sisteme_jgheaburi/bmi/rosu_caramiziu.png"
                      alt="lindab rainline"
                      className="culoare-stabicor"
                      onMouseOver={e => this.handleOnHover(images.CARAMIZIU)}
                      onMouseOut={e => this.handleOnMouseOut()}
                    />
                    <img
                      src="/assets/images/sisteme_jgheaburi/bmi/maro.png"
                      alt="lindab rainline"
                      className="culoare-stabicor"
                      onMouseOver={e => this.handleOnHover(images.MARO)}
                      onMouseOut={e => this.handleOnMouseOut()}
                    />
                    <img
                      src="/assets/images/sisteme_jgheaburi/bmi/antracit_briliant.png"
                      alt="lindab rainline"
                      className="culoare-stabicor"
                      onMouseOver={e => this.handleOnHover(images.ANTRACIT_BRILIANT)}
                      onMouseOut={e => this.handleOnMouseOut()}
                    />
                  </div>
                </div>
                <hr />
                <img
                  src="/assets/images/sisteme_jgheaburi/bmi/bmi_stabicor1.png"
                  alt="lindab rainline"
                  className="stabicor-image"
                />
              </div>
            </div>
            <div className="col max-width-stabicor min-width">
              <img
                src="/assets/images/sisteme_jgheaburi/bmi/bmi-stabicor2.png"
                alt="lindab rainline"
                className="stabicor-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BMIStabicor;
