import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Bramac.css";
const images = {
  ROSU_CARAMIZIU: {
    link: (
      <img src="/assets/images/tigla_bramac/tectura/tectura_rosu_caramiziu.jpg" className="card-img-top" alt="..." />
    ),
    text: "ROȘU CĂRĂMIZIU",
    className: "text-rosu-caramiziu"
  },
  ANTIC: {
    link: <img src="/assets/images/tigla_bramac/tectura/tectura_antic.jpg" className="card-img-top" alt="..." />,
    text: "ANTIC",
    className: "text-antic"
  },
  ANTRACIT_BRILIANT: {
    link: (
      <img src="/assets/images/tigla_bramac/tectura/tectura_antracit_briliant.jpg" className="card-img-top" alt="..." />
    ),
    text: "ANTRACIT BRILIANT",
    className: "text-antracit-briliant"
  }
};
class BramacTectura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_CARAMIZIU.link,
      text: images.ROSU_CARAMIZIU.text,
      className: images.ROSU_CARAMIZIU.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Tectura" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Răspunde provocărilor arhitecturale contemporane.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <div className="text-description">
                {" "}
                <div>
                  Prin liniile sale moderne ţigla Tectura se adaptează perfect unui stil arhitectural minimalist.{" "}
                </div>
                <div>
                  Tectura aduce noutatea unui stil formal reinterpretat constructiv şi tehnologic, fiind recomandată
                  atât pentru construcţii noi cât şi pentru renovarea acoperişurilor existente.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_CARAMIZIU)} className="dot rosu-caramiziu"></span>
                  <span name="rosu-angoba" onClick={e => this.handleOnClick(images.ANTIC)} className="dot antic"></span>
                  <span
                    name="cupru-angoba"
                    onClick={e => this.handleOnClick(images.ANTRACIT_BRILIANT)}
                    className="dot antracit-briliant"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BramacTectura;
