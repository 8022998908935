import React, { Component } from "react";
import "../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { cardThumbs } from "../../Homepage/Main";
import { Link } from "react-router-dom";
class PanouriSolare extends Component {
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Panouri solare și fotovoltaice" className="no-padding" />
        <br />
        <h2>Panouri solare</h2>
        <hr />
        <div className="row">
          <div className="col">
            {" "}
            <Link
              to="panouri-solare-fotovoltaice/panouri-solare/montaj-incastrat-in-invelitoare/bsd-pro"
              className="card-link"
            >
              <Card
                link={cardThumbs.panouSolarBsd}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Montaj încastrat în învelitoare"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="panouri-solare-fotovoltaice/panouri-solare/montaj-pe-invelitoare/ark-pro" className="card-link">
              <Card
                link={cardThumbs.panouSolarArk}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Montaj pe învelitoare"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
        <h2>Panouri fotovoltaice</h2>
        <hr />
        <div className="row">
          <div className="col">
            <Link
              to="panouri-solare-fotovoltaice/panouri-fotovoltaice/montaj-incastrat-in-invelitoare/pv-indax"
              className="card-link"
            >
              {" "}
              <Card
                link={cardThumbs.panouFotovoltaicInDax}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Montaj încastrat în învelitoare"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <Link
              to="panouri-solare-fotovoltaice/panouri-fotovoltaice/montaj-pe-invelitoare/pv-indax-pe-invelitoare"
              className="card-link"
            >
              {" "}
              <Card
                link={cardThumbs.panouFotovoltaicPeInv}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Montaj pe învelitoare"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PanouriSolare;
