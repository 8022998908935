import React, { Component } from "react";
import "./Tag.css";

class Tag extends Component {
  render() {
    return (
      <div className="row">
        <div className={this.props.className + " arrow_box"}>{this.props.name}</div>
      </div>
    );
  }
}

export default Tag;
