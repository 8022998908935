import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "./Nelskamp.css";
const images = {
  ROSU_NATURAL: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F12U/Rosu-Natural-Engobat-02-3.jpg" alt="..." />,
    text: "ROȘU ENGOBAT",
    className: "text-rosu-engobat"
  },

  NEGRU_INVECHIT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F12U/Negru-Invechit-Engobat-32-1.jpg" alt="..." />,
    text: "NEGRU ÎNVECHIT ENGOBAT",
    className: "text-negru-invechit-engobat"
  },

  NEGRU_MAT_ENGOBAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F12U/Negru-Mat-Engobat-39-2.jpg" alt="..." />,
    text: "NEGRU MAT ENGOBAT",
    className: "text-negru-mat-engobat"
  },

  NEGRU_GLAZURAT: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F12U/Negru-Glazurat-18-3.jpg" alt="..." />,
    text: "NEGRU GLAZURAT",
    className: "text-negru-glazurat"
  },

  AFUMAT_IN_MASA: {
    link: <img src="/assets/images/tigla_ceramica_nelskamp/F12U/Ars-Natural-144-1.jpg" alt="..." />,
    text: "AFUMAT ÎN MASĂ",
    className: "text-afumat-in-masa"
  }
};

class NelskampF12U extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATURAL.link,
      text: images.ROSU_NATURAL.text,
      className: images.ROSU_NATURAL.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
   
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };

  render() {
    return (
      <div className="products-container">
        <Tag name="F12Ü" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h2>Țigla ceramică profilată F12Ü Nord</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                {" "}
                <div>
                  Prin lungimea spațiului de suprapunere de 4 cm, modelul F12Ü Nord garantează un maxim de flexibilitate
                  în cazul renovărilor acoperișurilor vechi, oferind posibilitatea păstrării caroiajului existent.
                </div>
              </div>
            </div>
            <div className="col min-width">
              {" "}
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATURAL)} className="dot rosu-natural"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_INVECHIT_ENGOBAT)}
                    className="dot negru-invechit-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_MAT_ENGOBAT)}
                    className="dot negru-mat-engobat"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_GLAZURAT)}
                    className="dot negru-glazurat-engobat"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.AFUMAT_IN_MASA)} className="dot afumat-in-masa"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NelskampF12U;
