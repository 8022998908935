import React from "react";
export const wetterbestAll = {
  COLOSSEUM: (
    <img
      src="/assets/images/tigla_metalica_wetterbest/colosseum.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  PLUS: (
    <img
      src="/assets/images/tigla_metalica_wetterbest/plus.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  GLADIATOR: (
    <img
      src="/assets/images/tigla_metalica_wetterbest/gladiator.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  CLASIC: <img src="/assets/images/tigla_metalica_wetterbest/clasic.jpg" className="card-img-top" alt="..." />,
  CARDINAL: <img src="/assets/images/tigla_metalica_wetterbest/cardinal.png" className="card-img-top" alt="..." />,
  FAP: <img src="/assets/images/tabla_plana_minirulou/lindab/lindab_fap.png" className="card-img-top" alt="..." />,
  TOSCANA: (
    <img src="/assets/images/tigla_metalica_roca/lindab/lindab_toscana.jpg" className="card-img-top" alt="..." />
  ),
  MADERA: <img src="/assets/images/tigla_metalica_roca/lindab/lindab_madera.jpg" className="card-img-top" alt="..." />,
  RUSTICA: (
    <img src="/assets/images/tigla_metalica_roca/lindab/lindab_rustica.jpg" className="card-img-top" alt="..." />
  ),
  SERENA: <img src="/assets/images/tigla_metalica_roca/lindab/lindab_serena.jpg" className="card-img-top" alt="..." />
};
