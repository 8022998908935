import React, { Component } from "react";
import "../Products.css";
import Tag from "../../misc/Tag";
import Card from "../../misc/Card";
import { Link } from "react-router-dom";
const cards = {
  CERAMIC: <img src="/assets/images/cosuri_de_fum/ceramic.jpg" alt="..." className="rulouri-imagine" />,
  INOX: <img src="/assets/images/cosuri_de_fum/inox.jpg" alt="..." className="rulouri-imagine" />
};
class CosuriDeFum extends Component {
  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Coșuri de fum" />
        <br />
        <h2>Coșuri de fum</h2>
        <hr />
        <div className="row">
          <div className="col">
            {" "}
            <Link to="/produse/cosuri-de-fum/ceramic" className="card-link">
              {" "}
              <Card
                link={cards.CERAMIC}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Coş de fum ceramic "
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="/produse/cosuri-de-fum/inox" className="card-link">
              {" "}
              <Card
                link={cards.INOX}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Coş de fum din inox"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default CosuriDeFum;
