import React, { Component } from "react";
import Tag from "../../../misc/Tag";
import "../Ferestre.css";
import Card from "../../../misc/Card";
import { ferestreVeluxAll } from "../../../misc/ferestreVelux";
class FerestreMansardaStandardPlus extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Standard Plus" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h1>Ferestre de mansardă VELUX Standard Plus</h1>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              <h2>Operare de sus</h2>
              <hr />
              <div className="text-description">
                Noua fereastră de mansardă Standard Plus are acum geam
                termoizolant altcătuit din 3 foi de sticlă la un preț extrem de
                atractiv, ceea ce îți va da posibilitatea să păstrezi locuința
                călduroasă, fără a face compromisuri la calitate. Geamul
                termoizolant, împreună cu inovatorul sistem izolator
                ThermoTechnology™ și cu garnitura specială conferă acestei
                ferestre proproietăți izolatoare excelente. Bara de operare
                unică o recomandă pentru majoritatea înălțimilor de montaj, iar
                operarea de sus îți dă posibilitatea de a plasa o canapea
                dedesubt și totodată, să poți deschide fereastra.
              </div>

              <h2>Operare de jos</h2>
              <hr />
              <div className="text-description">
                Datorită geamului termoizolant cu 3 foi de sticlă, care este
                acum mult mai accesibil, noua fereastra Standard Plus cu operare
                de jos îți transformă mansarda într-o locuință mai eficientă
                energetic. Sistemul unic de termoizolare ThermoTechnology™,
                împreună cu garnitura suplimentară împotriva curentului
                îmbunătățesc performanța izolatoare a ferestrei, mențintând
                căldura în interior și acționând asemeni unui scut împotriva
                frigului de afară. Ferestrele cu operare de jos se montează de
                obicei în cazul unui parapet înalt, fiind poziționate la
                înălțime.
              </div>

              <h2>Operare de sus, fără întreținere</h2>
              <hr />
              <div className="text-description">
                GLU 0061 este fereastra noastră cu miez de lemn învelit în
                poliuretan, cu geam termoizolant alcătuit din 3 foi de sticlă.
                Este modelul care nu necesită întreținere. Modul unic de
                construcție al acestui model îi conferă un aspect impecabil,
                deoarece acoperirea omogenă a poliuretanului duce la un finisaj
                unitar, fără îmbinări. Modelul este recomandat în special în
                încăperi umede sau în spații cu exploatare intensă.
              </div>
            </div>
            <div className="col min-width">
              <div className="row">
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.GLL_SUS}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GLL 1061 cu operare de sus"
                    list={[]}
                  />
                </div>
                <div className="col ">
                  <Card
                    link={ferestreVeluxAll.GLL_JOS}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GLL 1061 B cu operare de jos"
                    list={[]}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col ">
                  <Card
                    link={ferestreVeluxAll.GLU_SUS_SP}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GLU 0061 cu operare de sus"
                    list={[]}
                  />
                </div>
                <div className="col">
                  <Card
                    link={ferestreVeluxAll.GLU_JOS_SP}
                    expandedArray={[false, false, false, false, false]}
                    cardTitle="Fereastră GLU 0061 B cu operare de jos"
                    list={[]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FerestreMansardaStandardPlus;
