import React, { Component } from "react";
import "../../../Products.css";
import "../RulouriDecorative.css";
import Tag from "../../../../misc/Tag";
import Card from "../../../../misc/Card";
import { Link } from "react-router-dom";
import { rulouriDecorativeControlLumina } from "../../../../misc/rulouriDecorative.js";
class ControlLumina extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Control al luminii" className="no-padding" />
        <br />
        <div className="row justify-content-md-center">
          <div className="col min-width-rulouri-decorative">
            <Link to="/produse/ferestre-velux/rulouri-decorative/control-lumina/rulou-opac" className="card-link">
              <Card
                link={rulouriDecorativeControlLumina.RULOU_OPAC_DKL_MANUAL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Rulou opac"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>

          <div className="col min-width-rulouri-decorative">
            <Link to="/produse/ferestre-velux/rulouri-decorative/control-lumina/rulou-duo" className="card-link">
              <Card
                link={rulouriDecorativeControlLumina.RULOU_DUO_DFD_MANUAL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Rulou DUO"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default ControlLumina;
