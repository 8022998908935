import React from "react";
export const nelskampAll = {
  F10U: <img src="/assets/images/tigla_ceramica_nelskamp/F10U/Natural-01.jpg" className="card-img-top" alt="..." />,
  F12U: (
    <img
      src="/assets/images/tigla_ceramica_nelskamp/F12U/Rosu-Natural-Engobat-02-3.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  F13U: (
    <img
      src="/assets/images/tigla_ceramica_nelskamp/F13/Rosu-Natural-Engobat-156-1.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  F14: (
    <img
      src="/assets/images/tigla_ceramica_nelskamp/F14/Rosu-Natural-Engobat-115.jpg"
      className="card-img-top"
      alt="..."
    />
  ),
  F15: <img src="/assets/images/tigla_ceramica_nelskamp/F15/Natural-115-1.jpg" className="card-img-top" alt="..." />,
  G10: <img src="/assets/images/tigla_ceramica_nelskamp/G10/Natural-01-1.jpg" className="card-img-top" alt="..." />,
  H15: <img src="/assets/images/tigla_ceramica_nelskamp/H15/Natural-115-2.jpg" className="card-img-top" alt="..." />
};
