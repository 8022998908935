import React, { Component } from "react";
import "./../../Products.css";
import "./Wetterbest.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { Link } from "react-router-dom";
import { wetterbestAll } from "../../../misc/wetterbest";
import $ from "jquery";
class Wetterbest extends Component {
  updateDimensions = () => {
    $("#previous2").css("maxWidth", $("#previous1").outerWidth() + "px");
    $("#last1").css("maxWidth", $("#previous1").outerWidth() + "px");
    $("#last2").css("maxWidth", $("#previous1").outerWidth() + "px");
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.scrollUp) {
        window.scrollTo(0, 0);
      }
    }
    $("#last1").css("maxWidth", $("#previous1").outerWidth() + "px");
    $("#previous2").css("maxWidth", $("#previous1").outerWidth() + "px");
    $("#last2").css("maxWidth", $("#previous1").outerWidth() + "px");
    window.addEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Țiglă metalică" />
        <br />
        <h2>Wetterbest</h2>
        <hr />
        <div className="text-description">
          Țigla metalică <strong>Wetterbest®</strong> este realizată în cadrul
          unui proces tehnologic automatizat care permite prelucrarea mecanică
          prin deformarea plastică la rece a oţelului prevopsit. Se remarcă prin
          rezistenţă mecanică la acţiunea factorilor de mediu, prin greutate
          specifică redusă, prin gama variată de culori şi dimensiuni și o
          durată medie de viaţă de 50 de ani.{" "}
        </div>
        <div className="text-description">
          Țigla metalică <strong>Wetterbest®</strong> este incombustibilă,
          folosirea acesteia drept material pentru realizarea învelitorilor,
          neinfluenţând clasa de rezistenţă la foc a acoperişurilor.
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="wetterbest/colosseum" className="card-link">
              <Card
                link={wetterbestAll.COLOSSEUM}
                expandedArray={[false, false, false, false, false]}
                cardTitle="COLOSSEUM"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col" id="previous1">
            {" "}
            <Link to="wetterbest/plus" className="card-link">
              {" "}
              <Card
                link={wetterbestAll.PLUS}
                expandedArray={[false, false, false, false, false]}
                cardTitle="PLUS"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col" id="previous2">
            {" "}
            <Link to="wetterbest/gladiator" className="card-link">
              {" "}
              <Card
                link={wetterbestAll.GLADIATOR}
                expandedArray={[false, false, false, false, false]}
                cardTitle="GLADIATOR"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col" id="last1">
            {" "}
            <Link to="wetterbest/clasic" className="card-link">
              {" "}
              <Card
                link={wetterbestAll.CLASIC}
                expandedArray={[false, false, false, false, false]}
                cardTitle="CLASIC"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col" id="last2">
            {" "}
            <Link to="wetterbest/cardinal" className="card-link">
              {" "}
              <Card
                link={wetterbestAll.CARDINAL}
                expandedArray={[false, false, false, false, false]}
                cardTitle="CARDINAL"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Wetterbest;
