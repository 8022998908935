import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "./Portfolio.css";
import Tag from "../misc/Tag";
import Card from "../misc/Card";
import { Link } from "react-router-dom";
const images = [
  {
    original: "/assets/images/portofoliu/lucrari_noi/1.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/1.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/3.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/3.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/9.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/9.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/10.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/10.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/29.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/29.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/4.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/4.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/5.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/5.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/6.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/6.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/7.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/7.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/8.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/8.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/11.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/11.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/12.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/12.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/13.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/13.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/14.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/14.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/15.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/15.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/16.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/16.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/17.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/17.jpg"
  },

  {
    original: "/assets/images/portofoliu/lucrari_noi/18.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/18.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/19.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/19.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/20.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/20.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/21.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/21.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/22.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/22.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/23.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/23.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/24.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/24.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/25.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/25.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/26.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/26.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/27.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/27.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/28.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/28.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/30.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/30.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/31.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/31.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/32.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/32.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/33.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/33.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/34.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/34.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/35.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/35.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/36.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/36.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/37.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/37.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/38.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/38.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/39.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/39.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/40.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/40.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/41.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/41.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/42.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/42.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/43.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/43.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/44.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/44.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/45.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/45.jpg"
  },
  {
    original: "/assets/images/portofoliu/lucrari_noi/46.jpg",
    thumbnail: "/assets/images/portofoliu/lucrari_noi/46.jpg"
  }
];

class Portfolio extends Component {
  render() {
    return (
      <div className="products-container">
        <Tag name="Portofoliu" />
        <br />
        <div className="row">
          <div className="col">
            <Link to="/portofoliu/proiecte-noi" className="card-link">
              {" "}
              <Card
                link={<img src="/assets/images/portofoliu/card_lucrari_noi.jpg" className="card-img-top" />}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Proiecte noi"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <Link to="/portofoliu/ferestre-luminatoare-trape-de-fum" className="card-link">
              {" "}
              <Card
                link={<img src="/assets/images/portofoliu/card_ferestre.jpg" className="card-img-top" />}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Ferestre, luminatoare, trape de fum"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Link to="/portofoliu/incalzire-in-pardoseala-degivrari" className="card-link">
              {" "}
              <Card
                link={<img src="/assets/images/portofoliu/card_incalzire.jpg" className="card-img-top" />}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Încălzire în pardoseală și degivrări"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            <Link to="/portofoliu/renovari" className="card-link">
              {" "}
              <Card
                link={<img src="/assets/images/portofoliu/card_renovari.jpg" className="card-img-top" />}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Renovări"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Portfolio;
