import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { Link } from "react-router-dom";
import { nelskampAll } from "../../../misc/nelskamp";
class Nelskamp extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Țiglă ceramică" />
        <br />
        <h2>Nelskamp</h2>
        <hr />
        <div className="text-description">
          Încă de la început, toate produsele <strong>Nelskamp</strong> au fost fabricate urmărind patru principii :
          calitate, fiabilitate, tradiție și inovație. Standardele înalte de calitate sunt demonstrate de certificarea
          DIN Plus a țiglelor <strong>Nelskamp</strong>, care vizează cele două aspecte de calitate ale unei țigle :
          rezistența la îngheț și impermeabilitatea. Produsele <strong>Nelskamp</strong> beneficiază de o garanție de 30
          de ani.
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="nelskamp/f10u" className="card-link">
              <Card
                link={nelskampAll.F10U}
                expandedArray={[false, false, false, false, false]}
                cardTitle="F10Ü"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="nelskamp/f12u" className="card-link">
              {" "}
              <Card
                link={nelskampAll.F12U}
                expandedArray={[false, false, false, false, false]}
                cardTitle="F12Ü"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="nelskamp/f13" className="card-link">
              {" "}
              <Card
                link={nelskampAll.F13U}
                expandedArray={[false, false, false, false, false]}
                cardTitle="F13"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="nelskamp/f14" className="card-link">
              {" "}
              <Card
                link={nelskampAll.F14}
                expandedArray={[false, false, false, false, false]}
                cardTitle="F14"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="nelskamp/f15" className="card-link">
              <Card
                link={nelskampAll.F15}
                expandedArray={[false, false, false, false, false]}
                cardTitle="F15"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="nelskamp/g10" className="card-link">
              <Card
                link={nelskampAll.G10}
                expandedArray={[false, false, false, false, false]}
                cardTitle="G10"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="nelskamp/h15" className="card-link">
              <Card
                link={nelskampAll.H15}
                expandedArray={[false, false, false, false, false]}
                cardTitle="H15"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <div className="empty-card"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Nelskamp;
