import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";
import Card from "../../../misc/Card";
import { Link } from "react-router-dom";
import { bramacAll } from "../../../misc/bramac";
class Bramac extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="products-container">
        <Tag name="Țiglă din beton" />
        <br />
        <h2>BMI Bramac</h2>
        <hr />
        <div className="text-description">
          Performanta, functionalitate, estetica. Tiglele <strong>BMI Bramac</strong> sunt alegerea perfecta pentru casa
          ta, deoarece iti ofera siguranta si durabilitate. Ai posibilitatea de a alege dintr-un sortiment variat de
          modele si culori, pentru a realiza acoperisul mult dorit. Produsele <strong>BMI Bramac</strong> beneficiază de
          o garanție de 30 de ani.
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="bramac/tectura" className="card-link">
              <Card
                link={bramacAll.TECTURA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Tectura"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="bramac/reviva" className="card-link">
              {" "}
              <Card
                link={bramacAll.REVIVA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Reviva"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="bramac/adria" className="card-link">
              {" "}
              <Card
                link={bramacAll.ADRIA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Adria"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="bramac/alpina-clasic" className="card-link">
              {" "}
              <Card
                link={bramacAll.ALPINA_CLASIC}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Alpina Clasic"
                list={[]}
                className=""
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {" "}
            <Link to="bramac/donau" className="card-link">
              <Card
                link={bramacAll.DONAU}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Donau"
                list={[]}
                className=""
                imgClass="height217"
              />
            </Link>
          </div>
          <div className="col">
            {" "}
            <Link to="bramac/romana" className="card-link">
              <Card
                link={bramacAll.ROMANA}
                expandedArray={[false, false, false, false, false]}
                cardTitle="Romana"
                list={[]}
                className=""
              />
            </Link>
          </div>
          <div className="col min-width"></div>
          <div className="col min-width"></div>
        </div>
      </div>
    );
  }
}

export default Bramac;
