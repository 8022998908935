import React, { Component } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dontShow: true,
    };
  }

  handleOnLinkClick = (e) => {
    this.setState({ dontShow: !this.state.dontShow });
  };
  render() {
    return (
      // <div className="logo-header">
      //   <img src="/assets/images/roofconstruct.jpg" className="logo" />
      // </div>
      <nav className="navbar navbar-expand-lg navbar-dark  static-top navi">
        <Link to="/" className="navbar-brand">
          <img
            src="/assets/images/logos/roofconstruct.jpg"
            alt=""
            className="logo"
          />
        </Link>
        <button
          onClick={(e) => this.handleOnLinkClick()}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded={!this.state.dontShow}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={
            this.state.dontShow
              ? "collapse navbar-collapse"
              : "collapse navbar-collapse show"
          }
          id="navbarResponsive"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item" onClick={(e) => this.handleOnLinkClick()}>
              <Link to="/" className="nav-link">
                Acasă
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                to="#"
              >
                Produse
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link
                  className="dropdown-item"
                  to="/produse/sisteme-invelitori"
                  onClick={(e) => this.handleOnLinkClick()}
                >
                  Sisteme învelitori
                </Link>
                <Link
                  className="dropdown-item"
                  to="/produse/ferestre-velux"
                  onClick={(e) => this.handleOnLinkClick()}
                >
                  Ferestre VELUX și accesorii
                </Link>
                <Link
                  className="dropdown-item"
                  to="/produse/panouri-solare-fotovoltaice"
                  onClick={(e) => this.handleOnLinkClick()}
                >
                  Panouri solare și fotovoltaice
                </Link>
                <Link
                  className="dropdown-item"
                  to="/produse/cosuri-de-fum"
                  onClick={(e) => this.handleOnLinkClick()}
                >
                  Coșuri de fum Schiedel
                </Link>
                <Link
                  className="dropdown-item"
                  to="/produse/trape-de-fum"
                  onClick={(e) => this.handleOnLinkClick()}
                >
                  Trape de fum și luminatoare
                </Link>
                <Link
                  className="dropdown-item"
                  to="/produse/sisteme-danfoss"
                  onClick={(e) => this.handleOnLinkClick()}
                >
                  Sisteme de degivrare Danfoss
                </Link>
              </div>
            </li>
            <li className="nav-item" onClick={(e) => this.handleOnLinkClick()}>
              <Link to="/servicii" className="nav-link">
                Servicii
              </Link>
            </li>
            <li className="nav-item" onClick={(e) => this.handleOnLinkClick()}>
              <Link to="/portofoliu" className="nav-link">
                Portofoliu
              </Link>
            </li>
            <li className="nav-item" onClick={(e) => this.handleOnLinkClick()}>
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header;
