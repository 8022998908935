import React, { Component } from "react";
import "./Creaton.css";
import Tag from "../../../misc/Tag";
const images = {
  NEGRU_MAT_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_negru_mat.jpg" alt="..." />,
    text: "NEGRU MAT ANGOBA",
    className: "text-negru-mat-angoba"
  },
  CUPRU_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_rosu_cupru.jpg" alt="..." />,
    text: "CUPRU ANGOBA",
    className: "text-cupru-angoba"
  },
  NEGRU_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_negru_smaltuit.jpg" alt="..." />,
    text: "NEGRU SMĂLȚUIT",
    className: "text-negru-smaltuit"
  },
  NEGRU_CRISTAL: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_negru_cristal.jpg" alt="..." />,
    text: "NEGRU CRISTAL",
    className: "text-negru-cristal"
  },
  MARO_SMALTUIT: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_maro_smaltuit.jpg" alt="..." />,
    text: "MARO SMĂLȚUIT",
    className: "text-maro-smaltuit"
  },
  MANGAN: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_mangan.jpg" alt="..." />,
    text: "MANGAN",
    className: "text-mangan"
  },
  ARDEZIE: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_ardezie.jpg" alt="..." />,
    text: "ARDEZIE",
    className: "text-ardezie"
  },
  MARO: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_maro.jpg" alt="..." />,
    text: "MARO",
    className: "text-maro"
  },
  ARDEZIE_CRISTAL: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_ardezie_cristal.jpg" alt="..." />,
    text: "ARDEZIE CRISTAL",
    className: "text-ardezie-cristal"
  },

  ROSU_NATUR: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_rosu_natur.jpg" alt="..." />,
    text: "ROȘU NATUR",
    className: "text-rosu-natur"
  },

  ROSU_VIN_ANGOBA: {
    link: <img src="/assets/images/tigla_ceramica_creaton/premion/premion_rosu_vin.jpg" alt="..." />,
    text: "ROȘU VIN ANGOBA",
    className: "text-rosu-vin-angoba"
  }
};
class CreatonPremion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: images.ROSU_NATUR.link,
      text: images.ROSU_NATUR.text,
      className: images.ROSU_NATUR.className
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnClick = e => {
    this.setState({
      image: e.link,
      text: e.text,
      className: e.className
    });
  };
  render() {
    return (
      <div className="products-container">
        <Tag name="Premion" />
        <br />
        <div className="creaton-container">
          <div className="text-title">
            <h2>Țiglă pentru acoperiș cu factor anti-ardere.</h2>
          </div>
          <hr />
          <div className="row">
            <div className="col min-width">
              {" "}
              <div className="text-description text-creaton">
                <div>Calitate înaltă și rezistență deosebite prin producția KERALIS</div>
                <div>Spațiu de deplasare deosebit de mare de 23 mm per țiglă</div>

                <div>Materiile prime de primă clasă permit 12 culori în patru variații de suprafață</div>

                <div>
                  Fălțuirea verticală cu „Tehnica de labirint” împiedică pătrunderea apei și în caz de presiune ridicată
                  a vântului
                </div>
              </div>
            </div>
            <div className="col min-width">
              <div className={this.state.className + " text-culoare"}>{this.state.text}</div>
              <div className="tigla-imagine">
                {this.state.image}{" "}
                <div className="dots">
                  <span onClick={e => this.handleOnClick(images.ROSU_NATUR)} className="dot rosu-natur"></span>
                  <span
                    name="rosu-angoba"
                    onClick={e => this.handleOnClick(images.CUPRU_ANGOBA)}
                    className="dot cupru-angoba"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO)} className="dot maro"></span>
                  <span onClick={e => this.handleOnClick(images.MANGAN)} className="dot mangan"></span>
                  <span onClick={e => this.handleOnClick(images.ARDEZIE)} className="dot adrezie-angoba"></span>
                  <span
                    onClick={e => this.handleOnClick(images.NEGRU_MAT_ANGOBA)}
                    className="dot negru-mat-angoba"
                  ></span>
                  <span
                    onClick={e => this.handleOnClick(images.ROSU_VIN_ANGOBA)}
                    className="dot rosu-vin-angoba"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.MARO_SMALTUIT)} className="dot maro-smaltuit"></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU_SMALTUIT)} className="dot negru-smaltuit"></span>
                  <span
                    onClick={e => this.handleOnClick(images.ARDEZIE_CRISTAL)}
                    className="dot ardezie-cristal"
                  ></span>
                  <span onClick={e => this.handleOnClick(images.NEGRU_CRISTAL)} className="dot negru-cristal"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatonPremion;
