import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "./Portfolio.css";
import Tag from "../misc/Tag";
import Card from "../misc/Card";
import { Link } from "react-router-dom";
const images = [
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/1.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/1.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/2.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/2.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/3.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/3.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/4.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/4.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/5.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/5.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/6.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/6.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/7.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/7.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/8.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/8.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/9.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/9.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/10.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/10.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/19.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/19.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/20.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/20.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/21.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/21.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/22.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/22.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/23.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/23.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/24.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/24.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/25.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/25.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/26.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/26.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/27.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/27.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/28.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/28.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/11.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/11.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/12.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/12.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/13.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/13.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/14.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/14.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/15.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/15.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/16.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/16.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/17.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/17.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/29.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/29.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/30.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/30.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/42.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/42.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/31.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/31.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/32.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/32.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/33.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/33.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/34.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/34.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/35.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/35.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/36.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/36.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/37.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/37.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/38.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/38.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/39.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/39.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/40.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/40.jpg"
  },
  {
    original: "/assets/images/portofoliu/incalzire_degivrari/41.jpg",
    thumbnail: "/assets/images/portofoliu/incalzire_degivrari/41.jpg"
  }
];

class IncalzireDegivrari extends Component {
  render() {
    return (
      <div className="products-container">
        <Tag name="Încălzire în pardoseală și degivrări" className="no-padding portfolio" />
        <br />
        <ImageGallery items={images} className="max-width-gallery" />
      </div>
    );
  }
}

export default IncalzireDegivrari;
