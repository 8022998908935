import React, { Component } from "react";
import "./../../Products.css";
import Tag from "../../../misc/Tag";

class LindabSkane extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="products-container">
        <Tag name="Skane" />
        <br />

        <div className="creaton-container">
          <div className="text-title">
            <h1>Skane</h1>
            <hr />
          </div>
          <div className="row">
            <div className="col braas-col">
              <div className="text-description">
                <div>
                  Tigla metalica Lindab Skane este un produs accesibil, cu profilatie joasa si aspect similar tiglei
                  clasice.{" "}
                </div>
                <div>
                  Profilul Skane este disponibil intr-o gama larga de culori cu acoperire PE (poliester 25 µm) si MATT
                  (poliester mat structurat 30 µm).{" "}
                </div>
              </div>
              <hr />
              <h3>Culori PE</h3>
              <img
                src="/assets/images/tigla_metalica_lindab/skane_culori_pe.png"
                alt="skane"
                className="lindab-culori"
              />{" "}
              <hr />
              <div>Culori MATT</div>
              <img
                src="/assets/images/tigla_metalica_lindab/skane_culori_matt.png"
                alt="skane"
                className="lindab-culori"
              />{" "}
            </div>
            <div className="col">
              {" "}
              <img
                src="/assets/images/tigla_metalica_lindab/SKANE_maro.jpg"
                alt="skane"
                className="lindab-metalica-image"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LindabSkane;
